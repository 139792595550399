import React from "react";
import { formatDate, formatDateTime, minutesDiff } from "../../services/helpers";
import Button from "../Button";

const ScheduleCalendar = ({ appointments = [] }) => {
  const days = [
    { displayName: "Sunday" },
    { displayName: "Monday" },
    { displayName: "Tuesday" },
    { displayName: "Wednesday" },
    { displayName: "Thursday" },
    { displayName: "Friday" },
    { displayName: "Saturday" },
  ];
console.log(appointments,"appointments?>>>>>>");

  return (
    <section className="schedule-calendar__container">
      {days.map((item, key) => (
        <div
          key={key}
          className="calendar-day"
          style={{
            backgroundColor: new Date().getDay() === key ? "#d5dffd" : "",
          }}
        >
          <h4>{item.displayName}</h4>
          {
            appointments?.filter(
              (appointment) => new Date(appointment.endTime?.seconds*1000).getDay() === key //&& new Date(appointment.endTime?.seconds*1000) > new Date()
            )
            ?.map((item, key) => {
              const startDate = formatDate(item.startTime);
              const startTime = formatDateTime(item.startTime);
              const endTime = formatDateTime(item.endTime);
              const isDesabled = minutesDiff(new Date(), new Date(item.startTime?.seconds*1000)) > 2 && (minutesDiff(new Date(), new Date(item.endTime?.seconds*1000)) > minutesDiff(new Date(), new Date(item.startTime?.seconds*1000)));

              return(
                <div key={key} className="appointment">
                  <p>
                    {item.patient.firstName} {item.patient.lastName}
                  </p>
                  <span className="appointment-type">{item.title}</span>
                  <a
                    href={isDesabled ? null : item.meetingLink }
                    target="_blank"
                    rel="noreferrer"
                    className="appointment-time"
                  >
                    {/* {item.time} - {item.endTime} */}
                    {startDate} ({startTime} - {endTime})
                  </a>
                  <Button
                    className="view-schedule-btn view-calendar"
                    type="minimal"
                    format="minimal"
                    onClick={() => window.open(item.meetingLink)}
                    disabled={isDesabled}
                  >
                    Join the call
                  </Button>
                </div>
              )
            })
            // .reverse()
          }
        </div>
      ))}
    </section>
  );
};
export default ScheduleCalendar;
