import React from "react";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";
import Routes from "./Routes";
import { StateProvider } from "./contexts/State";

createRoot(
  document.getElementById("root")
).render((
  // <React.StrictMode>
    <StateProvider>
      <Routes />
    </StateProvider>
  // </React.StrictMode>
))
