import Exercise from "./exercise";

class ExerciseSet {
  constructor(
    exercise = new Exercise(),
    work = [],
    rest = [],
    unit = "",
    notes = ""
  ) {
    this.exercise = exercise;
    this.work = work;
    this.rest = rest;
    this.unit = unit;
    this.notes = notes;
  }
  static build(exerciseSet, exerciseData) {
    return Object.assign(new ExerciseSet(), exerciseSet, {
      exercise: exerciseData,
    });
  }

  static async getFromRef(exerciseSet) {
    try {
      let exerciseRef = exerciseSet.exercise;
      return ExerciseSet.build(
        exerciseSet,
        await Exercise.getFromRef(exerciseRef)
      );
    } catch (err) {
      console.error(
        "There was a problem getting ExerciseSet from ref:",
        exerciseSet,
        err
      );
      throw err;
    }
  }

  static mapForStorage(exerciseSet) {
    try {
      let result = null;
      let exerciseRef = Exercise.getRef(exerciseSet.exercise.id);
      result = Object.assign({}, { ...exerciseSet }, { exercise: exerciseRef });
      return result;
    } catch (err) {
      console.error(
        "There was a problem mapping the exerciseSet for storage.",
        exerciseSet,
        err
      );
      throw err;
    }
  }
}

export default ExerciseSet;
