import Button from "./Button";
import TextInput from "./TextInput";
import { useState } from "react";
import firebase from "../services/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const PasswordReset = () => {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();

    const form = event.target;
    const data = new FormData(form);
    const auth = getAuth();

    try {
      const response = await sendPasswordResetEmail(
        auth,
        data.get("email_address")
      );
      setStatus("sent");
    } catch (error) {
      if (auth.code === "auth/user-not-found") {
        setError(new Error("Account not found"));
      } else {
        throw error;
      }
    }
  }

  return (
    <form onSubmit={handleSubmit} className="password-reset">
      <h2>Forgot your password?</h2>
      <p>
        We will send you instructions on how to reset your password by email.
      </p>
      {status !== "sent" ? (
        <TextInput name="email_address" placeholder="Email" />
      ) : (
        <p>Follow the link provided in the email.</p>
      )}
      <Button type="submit">Submit</Button>

      {error !== null ? <p class="error-message">{error.message}</p> : null}
    </form>
  );
};

export default PasswordReset;
