// TODO: This module should be on of the first to move to the new client interface.

import {
    serverTimestamp,
    doc,
    collection,
    query,
    where,
    getDoc,
    getDocs,
    setDoc,
      orderBy,
      limit
  } from "firebase/firestore";
  import { ref, getDownloadURL } from "firebase/storage";
  import Firebase from "../services/firebase";

  
  export { getSurveyResponse, getSurveyResponses };
  
  async function getSurveyResponses(user) {
    if (!user.id) {
      return [];
      }
    let requests = collection(Firebase.db, `users/${user.id}/responses`);
    let q = query(requests);
    const snap = await getDocs(q);
    return snap.docs.map((d) => [d.id, d.data()]);
  }
  
  async function getSurveyResponse(user, requestId) {
    let d = doc(Firebase.db, `users/${user.uid}/responses/${requestId}`);
    return (await getDoc(d)).data();
  }
  
