import {
  addDoc,
  getDocs,
  getDoc,
  collection,
  deleteDoc,
  setDoc,
  doc,
  updateDoc,
  query,
  where,
  orderBy,
  Timestamp
} from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";

class Appointment extends BasicFirebaseItem {
  constructor(
    id = "",
    trainerId = "",
    patientId = "",
    bookingId = "",
    title = "",
    description = "",
    startTime = "",
    endTime = "",
    meetingLink = "",
    appointments = []
  ) {
    super(id);
    this.trainerId = trainerId;
    this.patientId = patientId;
    this.bookingId = bookingId;
    this.title = title;
    this.description = description;
    this.startTime = startTime;
    this.endTime = endTime;
    this.meetingLink = meetingLink;
    this.appointments = appointments;
  }
/**
 * function for get all the apointments of tainer
 * @param {trainerId} trainerId 
 */
  static async getAppointments(trainerId) {
    try {
      const today = Timestamp.now();
      const listenerQuery = query(
        collection(Firebase.db, `trainers/${trainerId}/appointments/`),
        where('endTime', '>', today),
        orderBy("endTime", "asc"),
        orderBy("startTime", "asc")
      );
      const querySnapshot = await getDocs(listenerQuery);
      let data = await Promise.all(
        querySnapshot.docs.map(async (s) => {
          const data = s.data();
          const patientDetail = await getDoc(doc(Firebase.db, data.patientReference.path));
          data.patient = patientDetail.data();
          delete data.patientReference;
          return data;
        })
      );
      return data;
    } catch (err) {
      console.error(
        "There was a problem getting appointments",
        trainerId,
        err
      );
      throw err;
    }
  }

  async save(
    trainer,
    patient,
    bookingId,
    title,
    description,
    startTime,
    endTime,
    meetingLink
  ) {
    try {
      let patientRef = doc(Firebase.db, "users", patient.id);
      let preparedScheduleData = {
        patientReference: patientRef,
        bookingId,
        title,
        description,
        startTime: Timestamp.fromDate(new Date(startTime)),
        endTime: Timestamp.fromDate(new Date(endTime)),
        meetingLink
      }
      const scheduleRef = collection(Firebase.db, `trainers/${trainer.id}/appointments`);
      const appointmentRef = await addDoc(scheduleRef, { ...preparedScheduleData });
      await Appointment.savePatientAppointmentInTask(patient.id, appointmentRef, startTime)
      return appointmentRef;
    } catch (err) {
      console.error(
        "There was a problem saving the schedule:",
        this,
        trainer,
        patient,
        err
      );
      throw err;
    }
  }

  async checkDuplicateBooking(trainerId, bookingId) {
    console.log(trainerId, bookingId,"checkkkk");
    try {
      const listenerQuery = await query(
        collection(Firebase.db, `trainers/${trainerId}/appointments/`),
        where("bookingId", "==", bookingId),
        orderBy("startTime", "asc")
      );

      const querySnapshot = await getDocs(listenerQuery);
      let data = await Promise.all(
        querySnapshot.docs.map(async (s) => {
          return s.data()
        })
      );
      return data.length > 0;
    } catch (err) {
      console.error(
        "There was a problem checking the schedule:",
        this,
        trainerId,
        bookingId,
        err
      );
      throw err;
    }
  }

  static async savePatientAppointmentInTask(patientId, appointment, startTime) {
    try {
      const preparedData = {
        active: true,
        appointmentReference: appointment,
        id: appointment.id,
        // appointmentID: appointment.id,
        createdAt: Timestamp.now(),
        due: 
        {
          date:
          {
            _0: Timestamp.fromDate(new Date(startTime))
          }
        },
        records: [],
        taskType: "videoAppointment"
      }
  
      await setDoc(
        doc(Firebase.db, `users/${patientId}/tasks`, appointment.id),
        { ...preparedData }
      );
  
      const newDoc = await getDoc(
        doc(Firebase.db, `users/${patientId}/tasks`, appointment.id)
      );
      
      return newDoc.data();
    } catch (err) {
      console.error(
        "There was a problem saving the schedule:",
        this,
        patientId, 
        appointment,
        err
      );
      throw err;
    }
  }
}

export default Appointment;
