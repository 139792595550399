import SideBar from "../components/layouts/SideBar";
import AppLogout from "../components/AppLogout";
import SurveyResponseDocument from "../components/SurveyResponseDocument";

import { getPatient } from "../models/patient";
import { getSurveyResponse } from "../models/surveyResponses";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const SurveyResponse = () => {
  const { patientId, requestId } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState({});
  const [patient, setPatient] = useState({});

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      let patient = await getPatient(patientId);
      let request = await getSurveyResponse(patient, requestId);

      let rtf = new Intl.RelativeTimeFormat("en", {
        numeric: "auto",
        style: "short",
      });

      if (!ignore) {
        setPatient(patient);
        setRequest(request);
      }
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <AppLogout>
      <SideBar>
        <div className="feedback-container">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Patients
            </Link>
            /{" "}
            <Link to={`/patient/${patient.uid}`} className="current-route">
              {patient.firstName} {patient.lastName}
            </Link>
            / <span>Survey Response</span>
          </div>

          <div className="feedback-request-overview">
            <div className="feedback-details">
              <SurveyResponseDocument surveyResponse={request} />
            </div>
          </div>

        </div>
      </SideBar>
    </AppLogout>
  );
};

export default SurveyResponse;
