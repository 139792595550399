import React, { useCallback, useEffect, useRef, useState } from "react";
import Message from "../models/message";
import Patient from "../models/patient";
import Routine from "../models/routine";
import Trainer from "../models/trainer";
import Button from "./Button";
import Modal from "./Modal";
import RoutineSelector from "./RoutineSelector";
import UploadFileModal from "./UploadFileModal";

function MessageFooter({
  trainer = "",
  patient = new Patient(),
  routines = [],
}) {
  const [messageText, setMessageText] = useState("");
  const [hideButtons, setHideButtons] = useState(false);
  const [showRoutineModal, setShowRoutineModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const textInput = useRef();

  const changeInput = (e) => {
    e.target.style.height = "1.5rem";
    e.target.style.height = `${Math.min(e.target.scrollHeight, 180)}px`;
    setHideButtons(e.target.value !== "");
    setMessageText(e.target.value);
  };

  const sendRoutine = (routine) => {
    const message = new Message();
    message.type = "routine";
    message.data = Routine.getRef(trainer.id, routine.id);
    message.sender = Trainer.getRef(trainer.id);
    message.send(trainer.id, patient.id);
    setShowRoutineModal(false);
  };

  const sendNudge = () => {
    if (trainer.nudges.length > 0) {
      Message.sendNudge(trainer, patient.id);
    } else {
    }
  };

  const sendTextMessage = () => {
    if (patient.id) {
      (async () => {
        const message = new Message();
        message.type = "text";
        message.data = messageText;
        message.sender = Trainer.getRef(trainer.id);
        await message.send(trainer.id, patient.id);
        textInput.current.value = "";
        setMessageText("");
      })();
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendTextMessage();
    }
  };
  return (
    <div
      className="message-center-footer"
      onClick={() => {
        if (trainer.id && patient.id) {
          Message.markAsRead(trainer.id, patient.id);
        }
      }}
    >
      <div className={`message-center-buttons ${hideButtons ? "hidden" : ""}`}>
        {hideButtons ? (
          <button
            className="message-center-button"
            onClick={() => {
              setHideButtons(false);
            }}
          >
            <span className="material-icons">more_vert</span>
          </button>
        ) : ( 
          <>
            <button
              className="message-center-button"
              aria-label="Send a File"
              onClick={() => setShowFileModal(true)}
            >
              <span className="tool-tip">Send a File</span>
              <span className="material-icons sideways-left">attachment</span>
            </button>
            <button
              className="message-center-button"
              aria-label="Send a Routine"
              onClick={() => setShowRoutineModal(true)}
            >
              <span className="tool-tip">Send a Routine</span>
              <span className="material-icons">directions_run</span>
            </button>
            <button
              className="message-center-button"
              aria-label="Send a Nudge"
              onClick={sendNudge}
            >
              <span className="tool-tip">Send a Nudge</span>
              <span className="material-icons sideways-right">
                pan_tool_alt
              </span>
            </button>
          </>
        )}
      </div>
      <div className="message-center-input">
        <textarea
          id="message-input"
          placeholder={
            patient.firstName
              ? `Message ${patient.firstName}...`
              : "Send a message..."
          }
          onChange={changeInput}
          ref={textInput}
          onKeyDown={onEnterPress}
        />
      </div>
      <button
        className="message-center-button"
        aria-label="Send the Message"
        disabled={messageText === ""}
        onClick={sendTextMessage}
      >
        <span className="tool-tip">Send</span>
        <span className="material-icons">send</span>
      </button>
      <Modal
        show={showRoutineModal}
        onClose={() => {
          setShowRoutineModal(false);
        }}
        useCloseButton={true}
      >
        <h2>Which routine do you want to send?</h2>
        <RoutineSelector
          routineList={routines}
          trainer={trainer}
          owner={patient}
          onSelect={(routine) => sendRoutine(routine)}
          className="routine-selector-send"
        />
        <div className="button-container">
          <Button
            type="button"
            format="transparent"
            onClick={() => {
              setShowRoutineModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <UploadFileModal
        show={showFileModal}
        onClose={() => setShowFileModal(false)}
        useCloseButton={true}
        trainerId={trainer.id}
        patientId={patient.id}
      />
    </div>
  );
}

export default MessageFooter;
