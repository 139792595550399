import React, { useState, useEffect, useCallback } from "react";
import DaysWorkedOut from "./DaysWorkedOut";
import DoughnutChart from "./DoughnutChart";
import PatientGraph from "./PatientGraph";
import { capitalizeFirstLetter } from "../../services/helpers";
import RecentNotes from "./RecentNotes";
import Firebase from "../../services/firebase";
import Patient from "../../models/patient";

const PatientStatistics = ({ patient }) => {
  const [pickRange, setPickRage] = useState("month");
  const [stats, setStats] = useState({});
  const [daysWorkedOut, setDaysWorkedOut] = useState({});
  const [painData, setPainData] = useState([]);

  const setRange = (value) => {
    setPickRage(value);
  };
  const ranges = ["week", "month", "year"];

  useEffect(() => {
    (async () => {
      const s = await Patient.getStatistics(patient);

      if (!s) return;
      // Pain
      const painData = s.filter((r) => r.object === "painLevel");
      setPainData(painData);

      // DWO

      const dwo = s.filter((r) => r.object === "workoutProgress");
      setDaysWorkedOut(dwo);
    })();
  }, [pickRange, patient]);

  function dateInYear(now) {
    var start = new Date(now.getFullYear(), 0, 0);
    var diff =
      now -
      start +
      (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000;
    var oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  }

  function calculateChartData(pickRange) {
    let start;
    let end;
    let data = [];

    switch (pickRange) {
      case "week":
        var curr = new Date();
        var first = curr.getDate() - curr.getDay();
        var last = first + 6;

        start = new Date(curr.setDate(first));
        end = new Date(curr.setDate(last));

        break;
			case "year":
      case "month":
        var date = new Date();
        start = new Date(date.getFullYear(), date.getMonth(), 1);
        end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
    }

    for (let i = 0; i <= end.getDate() - start.getDate(); i++) {
			let offset = new Date(start);
			offset.setDate(start.getDate() + i);
			let record = painData.find((r) => r.createdAt.toDate().getDate() == offset.getDate())
      console.log(record);
      data[i - 1] = record
        ? record.painData.painPoints.reduce((a, x) => 10 * x.level + a, 0) /
          record.painData.painPoints.length
        : 0;
    }
    console.log(data);
    return data;
  }

  return (
    <section className="patient-detailed-stats">
      <div className="patient_stats__header">
        <h3>Statistics</h3>
        <div className="patient_stats__pick-time">
          {ranges.map((range, index) => (
            <span
              key={index}
              onClick={() => {
                setRange(range);
              }}
              className={`stat-time__button ${
                pickRange === range ? "active" : ""
              }`}
            >
              {capitalizeFirstLetter(range)}
            </span>
          ))}
        </div>
      </div>
      <div className="statistics-container__wrapper">
        <div className="statistics-container__left">
          <PatientGraph
            title="Pain Levels"
            range={pickRange}
            data={calculateChartData(pickRange)}
          />
          <div className="dougnut-container">
            {/* <DoughnutChart title="Adherence" progress={Math.ceil(adherence)} /> */}
            {/* <DoughnutChart title="Accuracy of Reps" progress={64} /> */}
            <RecentNotes patientId={patient.id} />
          </div>
        </div>
        <div className="statistics-container__right">
          <DaysWorkedOut timePeriod={pickRange} events={daysWorkedOut} />
          {/* <div className="promise-score__container">
            <h4>Promise Score</h4>
            <input type="checkbox" />
          </div> */}
        </div>
      </div>
    </section>
  );
};
export default PatientStatistics;
