import React, { useContext, useEffect, useState, useCallback } from "react";
import AppLogout from "../components/AppLogout";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/layouts/SideBar";
import TextSVGContainer from "../components/svg/TextSVGContainer";
import Toggle from "../components/Toggle";
import { StateContext } from "../contexts/State";
import Settings from "../models/settings";
import Button from "../components/Button";

/**
 *
 * @param children is the content that should be showed on the right side of the screen
 * @returns
 */
const SettingsPage = ({ children }) => {
  const { state, dispatch } = useContext(StateContext);
  const [settings, setSettings] = useState(new Settings());
  const [saved, setSaved] = useState(false)
  const navigate = useNavigate();

  const getSettingsData = useCallback(async () => {
    if (state.trainer.id) {
      const getSettings = await Settings.getSettings(state.trainer.id);
      if (getSettings) {
        setSettings(getSettings);
      }
    }
  }, [state.trainer.id]);

  useEffect(() => {
    getSettingsData();
  }, [getSettingsData]);

  const updateSettings = (field, property, e) => {
    setSettings((prevSettings) => {
      let temp = prevSettings;
      temp.notifications[field][property] = e.target.checked;
      return { ...temp };
    });
  };
  return (
    <AppLogout>
    <SideBar>
      <div className="dashboard-content settings-page">
        <div className="header">
          <h2>Settings</h2>
          <h3>Notifications</h3>
          <fieldset className="setting-container">
            <legend>Messages</legend>
            <label>
              <span className="material-icons-outlined">mail</span>
              Email
              <Toggle
                checked={settings.notifications.messages.email}
                onChange={(e) => updateSettings("messages", "email", e)}
              />
            </label>
            <label>
              <span className="material-icons-outlined">smartphone</span>
              Text
              <Toggle
                checked={settings.notifications.messages.text}
                onChange={(e) => updateSettings("messages", "text", e)}
              />
            </label>
          </fieldset>
          <fieldset className="setting-container">
            <legend>New Patients</legend>
            <label>
              <span className="material-icons-outlined">mail</span>
              Email
              <Toggle
                checked={settings.notifications.newPatients.email}
                onChange={(e) => updateSettings("newPatients", "email", e)}
              />
            </label>
            <label>
              <span className="material-icons-outlined">smartphone</span>
              Text
              <Toggle
                checked={settings.notifications.newPatients.text}
                onChange={(e) => updateSettings("newPatients", "text", e)}
              />
            </label>
          </fieldset>
        </div>
        <Button
          format="minimal"
          className="settings-save__btn"
          onClick={async () => {
            setSaved(!saved)
            await Settings.createSettings(state.trainer.id, settings);
            navigate("/dashboard");
          }}
        >
          {saved ? 'Saved!' : 'Save'}
        </Button>
      </div>
    </SideBar>
    </AppLogout>
  );
};
export default SettingsPage;
