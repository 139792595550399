import Button from "../components/Button";
import WarningSVG from "../components/svg/WarningSVG";
import Patient from "../models/patient";

class TypeformClass {
  constructor() {
    this.formBaseURL = "/.netlify/functions/";
    this.redValues = false;
  }

  async getFormQuestions(typeformId) {
    const body = {
      typeformId: typeformId,
    };
    const questions = await this.getTypeformURL(
      this.formBaseURL + "getFormQuestions",
      body
    );
    return questions;
  }

  async getFormResponseByEmail(email, typeformId) {
    const body = {
      typeformId: typeformId,
      email: email,
    };
    const url = this.formBaseURL + "getFormAnswers";
    const response = await this.getTypeformURL(url, body);
    return response;
  }

  async getTypeformURL(url, body, isFirstTry = true) {
    let newURL = null;
    if (window.location.hostname === "localhost")
      newURL = "http://localhost:9999" + url;
    return fetch(newURL ? newURL : url, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200 && isFirstTry) {
          console.log("trying again", response);
          return this.getTypeformURL(url, body, false);
        } else return response.json();
      })
      .catch((e) => {
        console.error("There was an error retrieving typeform data", e);
      });
  }

  mapQuestionsAndAnswers(
    questions,
    answers,
    email,
    setModalOpen = () => {},
    canEdit,
    patient = {},
    variables
  ) {
    // console.log('questions', questions);
    let title = (
      <div key={`${questions.id}-title`}>
        <div className="typeform-title">
          <h2>{questions.title}</h2>
          {canEdit && (
            <Button
              type="button"
              format="transparent"
              onClick={() => {
                window.open(
                  `https://305lp0pg9du.typeform.com/to/${questions.id}#email=${email}`,
                  "_blank"
                );
                setModalOpen(false);
              }}
            >
              Add / Replace Response
            </Button>
          )}
        </div>
        {this.compareROMtotals(variables)}
      </div>
    );
    if (questions?.fields?.length && answers?.length) {
      // console.log("questions: ", questions);
      questions.fields.forEach((question) => {
        // Make sure that we have the right answer for the question
        let matchedAnswer = {};
        answers.every((answer) => {
          if (answer.field.id === question.id) {
            matchedAnswer = answer;
            return false;
          }
          return true;
        });
        question.answer = matchedAnswer;

        //Generate display text depending on question type
        switch (matchedAnswer.type) {
          case "choice":
          case "multiple_choice":
            question.displayAnswer = matchedAnswer.choice.label;
            break;
          case "choices":
            question.displayAnswer = matchedAnswer.choices.labels.join(", ");
            break;
          default:
            question.displayAnswer = matchedAnswer[matchedAnswer.type];
            break;
        }
      });

      let showWarning;
      let displayQuestions = questions.fields.map((question) => {
        //Get the displayQuesiton and display
        let displayQuestion = this.formatQuestionTitle(
          question,
          questions.fields
        );

        showWarning = this.checkForRedValues(
          question,
          questions.title,
          patient.weight,
          variables,
          patient.id
        );

        return (
          <div
            className="typeform-question-and-answer"
            key={`${questions.id}-${question.id}`}
          >
            <div className="typeform-question">
              <h3 className={showWarning ? "errorColor" : ""}>
                {displayQuestion}{" "}
                {/* if the question has a description, display it next to the question */}
                {question.properties?.description &&
                  `(${question.properties?.description})`}
              </h3>
              {showWarning && <WarningSVG className="red-value" />}
            </div>

            <div className="typeform-response">
              {/* <pre>{question.displayAnswer}</pre> */}
              <span>{question.displayAnswer}</span>
            </div>
          </div>
        );
      });
      if (this.redValues && !patient?.assessmentWarning?.read) {
        console.log("sending");
        Patient.update(patient.id, {
          assessmentWarning: {
            showWarning: true,
            read: false,
          },
        });
      }
      // } else if (!this.redValues && patient?.assessmentWarning?.showWarning) {
      //   console.log('remove')
      //   Patient.update(patient.id, {
      //     assessmentWarning: {
      //       showWarning: false,
      //       read: false,
      //     },
      //   });
      // }

      return [title, displayQuestions];
    } else if (questions?.fields?.length) {
      this.redValues = false;
      return [
        title,
        <h3 key={`${questions.id}-response`}>
          There isn't a response stored for this assessment yet.
        </h3>,
      ];
    } else {
      return false;
    }
  }

  formatQuestionTitle(question, questions) {
    let questionParts = question.title.split("{{");
    let formattedQuestionParts = [];
    formattedQuestionParts.push(questionParts[0]);
    for (let i = 1; i < questionParts.length; i++) {
      let subParts = questionParts[i].split("}}");
      let reference = subParts[0].split(":")[1];
      let displayText = "";
      questions.every((compQuestion) => {
        if (compQuestion.ref === reference) {
          displayText = compQuestion.displayAnswer;
          return false;
        }
        return true;
      });
      formattedQuestionParts.push(displayText);
      formattedQuestionParts.push(subParts[1]);
    }
    return formattedQuestionParts.join("");
  }

  // if ROM totals are greater than 5 degrees apart, display warning
  compareROMtotals(variables) {
    if (variables && variables.length > 0) {
      const total_rom_right = variables.find(
        (variable) => variable?.key === "total_rom_right"
      );
      const total_rom_left = variables.find(
        (variable) => variable?.key === "total_rom_left"
      );
      return Math.abs(total_rom_left?.number - total_rom_right?.number) > 5 ? (
        <div className="rom-warning__container">
          <p>Patient's Total ROM right: {total_rom_right?.number}</p>
          <p>Patient's Total ROM left: {total_rom_left?.number}</p>
        </div>
      ) : (
        <></>
      );
    } else {
      return <></>;
    }
  }

  checkForRedValues(question, title, weight, variables, patientId) {
    if (title === "Evaluation Red Values Test" && question) {
      // Check for ratios Quadriceps and Hamstrings. Should be between 1.3 and 1.7
      if (
        question.title === "Quad ratio" ||
        question.title === "Hamstring ratio"
      ) {
        if (
          question.displayAnswer &&
          (Number(question.displayAnswer) > 1.7 ||
            Number(question.displayAnswer)) < 1.3
        ) {
          this.redValues = true;
          return true;
        } else return false;

        // if strength is below 15% of patient's body weight
      } else if (
        question.title.includes("ER Strength") ||
        question.title.includes("IR Strength")
      ) {
        if (question.displayAnswer < weight * 0.15) {
          this.redValues = true;
          return true;
        } else return false;

        // if strength to velocity is beneath threshold
      } else if (question.title.includes("Strength to Velocity")) {
        if (
          question.displayAnswer &&
          (Number(question.displayAnswer) > 1.6 ||
            Number(question.displayAnswer)) < 1.4
        ) {
          this.redValues = true;
          return true;
        } else return false;

        // if IR ROM on one side is more than 5 degrees apart
      } else if (question.title.includes("IR ROM")) {
        const ir_rom_total = variables.find(
          (variable) => variable?.key === "ir_rom_total"
        );
        const opposite_ir = ir_rom_total.number - question.displayAnswer;
        if (Math.abs(question.displayAnswer - opposite_ir) > 5) {
          this.redValues = true;
          return true;
        } else return false;
        // if ER ROM on one side is more than 5 degrees apart
      } else if (question.title.includes("ER ROM")) {
        const er_rom_total = variables.find(
          (variable) => variable?.key === "er_rom_total"
        );
        const opposite_er = er_rom_total.number - question.displayAnswer;
        if (Math.abs(question.displayAnswer - opposite_er) > 5) {
          this.redValues = true;
          return true;
        } else return false;
      }
    }
  }
}
const Typeform = new TypeformClass();

export default Typeform;
