import { useState, useEffect } from "react";
import { getOpenFeedbackRequests } from "../models/feedback";
import FeedbackRequestItem from "./FeedbackRequestItem";

const Feedback = (props) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requests = await getOpenFeedbackRequests(props.patient);
      setRequests(requests.slice(0, 5));
    };

    fetchData();
  }, [props.patient]);

  const items = requests.map(([id, req]) => (
    <FeedbackRequestItem key={id} id={id} patient={props.patient} request={req} />
  ));

  return (
    <div className={`patient-feedback`}>
      <h3>Feedback Requests</h3>
			{ items }
    </div>
  );
};

export default Feedback;
