import React, { Component, useCallback, useEffect, useState } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
} from "firebase/storage";
import Button from "./Button";
import Firebase from "../services/firebase";
import Modal from "./Modal";

/**
 * TextInput Component
 * Is a generally stylized and reusable input
 * @param id Id for the generated input field.  Applied to input element itself
 * @param className Additional classes to add to the overall wrapper of component
 * @param type Input type passed into input element
 * @param pattern Input validation pattern to be passed onto input field
 * @param onChange Function that gets sent the event every time input field is changed
 * @param label Text to be displayed above input
 * @param errorMessage Text to displayed below input field if there is an error
 * @param hasError Boolean value to determine whether input should be styled to have error and if error message should be displayed
 * @param setHasError Function that is called once input is validated
 * @param defaultValue Value to which the input should be initialized
 * @param icon Icon to be showed before input.  Should be an element rather than a url
 * @param minLength Minimum length of input text to be considered valid
 * @param required Whether or not the input should be marked valid in a form
 * @param onBlur Function that is passed the event whenever onBlur is called by the input
 * @param name Name to be applied to the input element
 * @param min Minimum number value passed onto input element
 * @param max Maximum number value passed onto input element
 */
const FileUpload = ({
  id = "",
  className = "",
  onUploadComplete = (downloadURL) => {},
  startUpload = () => {},
  finishUpload = () => {},
  label = "",
  loadingText = "Uploading",
  accept = "",
  type = "image",
  targetFolder = "",
  downloadURL = "",
  filePath = null,
  deleteFile = () => {},
}) => {
  const [targetFile, setTargetFile] = useState(null);
  const [targetFileName, setTargetFileName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileSize, setFileSize] = useState(1);
  const [amountUploaded, setAmountUploaded] = useState(0);
  const [finishedUploading, setFinishedUploading] = useState(false);
  const [metadata, setMetaData] = useState(null);
  const [upload, setUpload] = useState(null);
  const [showVerifyDeleteModal, setShowVerifyDeleteModal] = useState(false);

  const changeFile = (e) => {
    setTargetFile(e.target.files[0]);
    const fileNameParts = e.target.files[0].name.trim().split(".");
    const fileType = fileNameParts[fileNameParts.length - 1];
    const fileMetadata = {
      customMetadata: {
        originalFileName: e.target.files[0].name,
      },
    };
    const storageRef = ref(
      Firebase.storage,
      targetFolder + crypto.randomUUID() + "." + fileType
    );
    setIsLoading(true);
    startUpload();
    const uploadTask = uploadBytesResumable(
      storageRef,
      e.target.files[0],
      fileMetadata
    );
    setUpload(uploadTask);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log("upload status change", snapshot, amountUploaded, fileSize);
        setAmountUploaded(Number(snapshot.bytesTransferred));
        setFileSize(Number(snapshot.totalBytes));
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/canceled":
            break;
          case "storage/unauthorized":
          case "storage/unknown":
          default:
            alert("There was a problem uploading your file.");
            break;
        }

        setIsLoading(false);
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          onUploadComplete(downloadURL, uploadTask.snapshot.ref);
          setFinishedUploading(true);
          finishUpload();
          setIsLoading(false);
        });
      }
    );
  };

  useEffect(() => {
    (async () => {
      if (filePath) {
        const fileMetadata = await getMetadata(ref(Firebase.storage, filePath));
        setMetaData(fileMetadata);
      }
    })();
  }, [filePath]);

  useEffect(() => {
    return () => {
      if (isLoading && upload) {
        console.log("Trying to clean up upload", upload);
        upload?.cancel();
      }
    };
  }, []);

  const buildPreview = useCallback(() => {
    let preview = "";
    if (type === "image") {
      preview = <img src={downloadURL} alt="Uploaded Image" />;
    } else if (type === "video") {
      preview = (
        <video controls>
          <source src={downloadURL} type="video/mp4" />
        </video>
      );
    }
    return (
      <div className="file-preview">
        {preview}
        <div className="file-name">
          {metadata?.customMetadata?.originalFileName}
        </div>
        <div className="file-buttons">
          <span className="material-icons-outlined" onClick={verifyDeleteFile}>
            delete
          </span>
        </div>
      </div>
    );
  }, [downloadURL, metadata]);

  const formatSize = useCallback(
    (size) => {
      let suffix, adjustedNumber;
      // if(size > 1048576) {
      //   suffix = "KB"
      //   adjustedNumber = size / 1024.0
      // }
      if (size > 1048576) {
        suffix = "MB";
        adjustedNumber = size / 1048576.0;
      } else if (size > 1024) {
        suffix = "KB";
        adjustedNumber = size / 1024.0;
      } else {
        suffix = "B";
        adjustedNumber = size;
      }
      return `${adjustedNumber.toFixed(1)} ${suffix}`;
    },
    [amountUploaded, fileSize]
  );

  const verifyDeleteFile = () => {
    setShowVerifyDeleteModal(true);
  };

  return (
    <div className="file-upload-container">
      <h4>{label}</h4>
      <div className="upload-file-container">
        {finishedUploading ? (
          <div className="upload-summary">{buildPreview()}</div>
        ) : isLoading ? (
          <div className="loading">
            <div className="loading-text">
              {loadingText}: {formatSize(amountUploaded)}/{formatSize(fileSize)}
              <span className="material-icons-outlined">cancel</span>
            </div>
            <div className="loading-bar-outside">
              <div
                className="loading-bar-progress"
                style={{ right: `${100 * (1 - amountUploaded / fileSize)}%` }}
              ></div>
            </div>
          </div>
        ) : filePath ? (
          <div className="upload-summary">{buildPreview()}</div>
        ) : (
          <>
            <div className="material-icons-outlined">upload_file</div>
            <label htmlFor="file-upload" className="upload-label">
              <span className="upload-label-main">
                Click to select a file to upload
              </span>
              <br />
              <span>or drag and drop your file here</span>
              <input
                id="file-upload"
                className="upload-input"
                accept={accept}
                type={"file"}
                onChange={changeFile}
              />
            </label>
          </>
        )}
      </div>
      <Modal
        show={showVerifyDeleteModal}
        onClose={() => setShowVerifyDeleteModal(false)}
        useCloseButton={true}
      >
        {showVerifyDeleteModal ? (
          <>
            <h3>Are you sure you want to remove this photo?</h3>
            <p className="warning">This cannot be undone</p>
            <div className="delete-buttons">
              <Button
                value="cancel"
                type="button"
                onClick={() => setShowVerifyDeleteModal(false)}
                format="transparent"
              >
                Cancel
              </Button>
              <Button type="button" format="warning" onClick={deleteFile}>
                Delete
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default FileUpload;
