import React from "react";

const PatientGraph = ({
  className = "",
  title = "",
  unit = "",
  data = [],
  range = "",
}) => {
  const setColor = (percent) => {
    if (percent < 31 && percent >= 1) {
      return "#34A853";
    } else if (percent >= 30 && percent < 70) {
      return "#FCB514";
    } else if (percent >= 70) {
      return "#FF4848";
    } else if (percent === -1) {
      return "#443C3C";
    } else {
      return "#34A853";
    }
  };
  return (
    <div className="graph-container">
      <h4>{title}</h4>
      <div className="graph-range__container">
        <div className="graph-range__numbers">
          <span>10</span>
          <span>0</span>
        </div>
        <div className="graph-range">
          {data &&
            data.map((item, key) => (
              <div
                key={key}
                className="graph-item"
                style={{
                  backgroundColor: setColor(item),
                  height: `${item}%`,
                  width: `${range === "month" ? "1.35rem" : "2.5rem"}`,
                }}
              ></div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default PatientGraph;
