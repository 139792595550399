import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import { toDisplayName, humanize } from "../utils/text";
import { getReplay } from "../models/assessments";
import ReactPlayer from "react-player";

const AssessmentDocument = ({ patient, assessment }) => {
  const [url, setUrl] = useState(null);

  async function handler(label) {
    try {
      setUrl(
        await getReplay(
          patient.uid,
          assessment.exercise,
          assessment.reference,
          label
        )
      );
    } catch (error) {
      console.error(error);
      setUrl(undefined);
    }
  }

  function toggle(label) {
    assessment.results.boolean[label] = !assessment.results.boolean[label];
  }

  const tableData = (object) => {
    return Object.entries(object).map(([label, value]) => {
      return (
        <tr>
          <td>{label}</td>
          <td className="value">
            <span
              data-okay={typeof value === "boolean" && !value ? true : null}
              data-problem={typeof value === "boolean" && value ? true : null}
              onClick={() => toggle(label)}
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                borderRadius: "20px",
              }}
            ></span>
          </td>
          <td>
            {assessment.completed_replays && assessment.completed_replays[label] && (
              <button onClick={() => handler(label)} className="replay-button">
                <span className="material-icons-outlined">play_arrow</span>
              </button>
            )}
          </td>
        </tr>
      );
    });
  };

  const tableRows = () => {
    if (!assessment.results) {
      return;
    }
    return Object.entries(assessment.results).map(([r, v]) => {
      return tableData(v);
    });
  };

  return (
    <div className="assessment">
      <h1>{toDisplayName(assessment.exercise)}</h1>
      <div>
        <table className="assessment-table">
          <tbody>
            <tr>
              <th>Assessment</th>
              <th></th>
              <th>Replay</th>
            </tr>

            {tableRows()}
          </tbody>
        </table>
      </div>

      <div className="assessment-replay feedback-video-container">
        {url !== undefined ? (
          <ReactPlayer controls url={url} />
        ) : (
          <p>No replay available.</p>
        )}
      </div>
    </div>
  );
};

export default AssessmentDocument;
