import React, { Component, useEffect, useRef, useState } from "react";

/**
 * Button Component
 * Standardized button component to be used throughout the app
 * @param id Id to be applied to button element
 * @param className Additional class names to be applied to parent div
 * @param type Button type to be passed onto button element
 * @param disabled Boolean value determining whether button should be considered disabled or not
 * @param onClick Async function to be called when button is clicked.  Will call the success or error callback depending on success or failure
 * @param children Content to be put inside button element
 * @param successCallback Function to be called once the async onClick function is complete
 * @param errorCallback Funciton to be called if an error is throwin in the onclick function
 * @param format Visual format selection for button. Valid formats are: simple, transparent, fancy, and minimal
 * @param value Button value to be attached to button element
 * @param fixed applies the "fixed" class if true
 */
export default function FloatingActionButton({
  id = "",
  className = "",
  type = "button",
  disabled = false,
  onClick = async () => {},
  normalContent = null,
  successCallback = async () => {},
  errorCallback = async () => {},
  hoverContent = <></>,
  fixed = false,
}) {
  const handleClick = async () => {
    if (disabled) return;
    // Handle callbacks whether function that is passed in is async or not
    try {
      const data = await onClick();
      await successCallback(data);
    } catch (err) {
      console.log("There was a problem with the button", err);
      await errorCallback(err);
    }
  };
  return (
    <div
      id={id}
      className={`floating-action-button ${className} ${
        disabled ? "disabled" : ""
      } ${fixed && "fixed"}`}
      onClick={handleClick}
    >
      <span className="floating-action-button-content">{normalContent}</span>{" "}
      <span className="floating-action-button-hover-content">
        {hoverContent}
      </span>
    </div>
  );
}
