import React, { useContext, useEffect, useState } from "react";
import { StateContext } from "../contexts/State";
import { useNavigate } from "react-router-dom";
import Firebase from "../services/firebase";
import Button from "./Button";
import Modal from "./Modal";
import RoutineSelector from "./RoutineSelector";
import Routine from "../models/routine";
import Trainer from "../models/trainer";

/**
 * RoutineCard Component
 * @param routine Routine for which to display information
 * @param className Additional classes to be applied
 * @returns Routine card that shows an image, the routine name, and a list of exercises
 */
function CreateRoutine({ className = "", reservedNames = [], closeModal = () => {} }) {
  const navigate = useNavigate();
  const [showTemplates, setShowTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const { state, dispatch } = useContext(StateContext);

  // Navigates to a page to edit the routine
  const createFromTemplate = () => {
    setShowTemplates(true);
  };
  const selectTemplate = (templateId) => {
    navigate(`routine/${templateId}/create`);
  };
  const createFromScratch = () => {
    navigate(`routine/create`);
  };

  const refreshTrainerData = () => {
    Trainer.getById(state.trainer.id)
      .then((trainer) => {
        dispatch(Object.assign(state.trainer, trainer), {
          type: "STORE_TRAINER_DATA",
        });
      })
      .catch((err) => {
        Firebase.logoutUser();
        navigate("/");
      });
  };

  useEffect(() => {
    const getTemplates = async () => {
      if (showTemplates && state.trainer.routineTemplates) {
        setLoadingTemplates(true);
        let data = await Routine.getFromList(state.trainer.routineTemplates);
        setLoadingTemplates(false);
        setTemplates(data);
      }
    };

		getTemplates()
			.catch(console.error)
  }, [showTemplates, state.trainer.id, state.trainer.routineTemplates]);

  return (
    <div className={`create-routine-prep ${className}`}>
      <h2>
        Would you like to create the routine from scratch or from a template?
      </h2>

      <div className="modal-buttons">
        <Button type="button" format="transparent" onClick={closeModal}>
          Cancel
        </Button>
        <Button type="button" format="minimal" onClick={createFromScratch}>
          From Scratch
        </Button>
        <Button type="button" format="minimal" onClick={createFromTemplate}>
          From Template
        </Button>
      </div>

      <Modal
        show={showTemplates}
        onClose={() => {
          setShowTemplates(false);
        }}
        useCloseButton={true}
      >
        <h2>Which template do you want to use?</h2>
        {loadingTemplates && templates.length <= 0 ? (
          <div className="loading">Loading Templates...</div>
        ) : templates.length ? (
          <RoutineSelector
            routineList={templates}
            trainer={state.trainer}
            owner={state.trainer}
            onSelect={(template) => selectTemplate(template.id)}
            afterDelete={refreshTrainerData}
            className="template-selector"
          />
        ) : (
          <div className="no-templates">You don't have any templates.</div>
        )}
        <div className="button-container">
          <Button
            type="button"
            format="transparent"
            onClick={() => {
              setShowTemplates(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default CreateRoutine;
