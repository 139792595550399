import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Modal from "./Modal";
// import TextInput from "./TextInput";
// import Button from "./Button";
// import CheckboxList from "./CheckboxList";
// import TextBox from "./TextBox";
// import TextListInput from "./TextListInput";
// import UploadFileModal from "./UploadFileModal";
// import FileUpload from "./FileUpload";
import Exercise from "../models/exercise";
// import Trainer from "../models/trainer";

const VideoModal = ({
  trainerId,
  show = false,
  exercise,
  setClose = () => {},
}) => {
  const [url, setUrl] = useState("");
  const [exerciseName, setExerciseName] = useState("");

  const fetchURL = useCallback(async (exercise) => {
    try {
      const data = await Exercise.videoURL(exercise);
      setUrl(data);
    } catch (error) {
      console.error("Could not fetch video url: ", error);
    }
  }, []);

  useEffect(() => {
    if (exercise.name) {
      console.log(exercise.name);
      fetchURL(exercise).catch((err) => {
        setUrl("");
        console.error(err);
      });
      setExerciseName(exercise.displayName);
    }
  }, [exercise, exerciseName, fetchURL]);

  const deleteImg = async () => {
    await exercise.deleteImg();
    setClose();
  };

  const deleteVideo = async () => {
    await exercise.deleteVideo();
    setClose();
  };

  const close = () => {
    setClose();
  };

  return (
    <Modal
      show={show}
      onClose={close}
      useCloseButton={true}
      className="new-exercise-container"
    >
      <h3>{exerciseName}</h3>
      <ReactPlayer muted controls url={url} />
    </Modal>
  );
};
export default VideoModal;
