import React, { useCallback, useContext, useEffect, useState } from "react";
import AppLogout from "../components/AppLogout";
import Feedback from "../components/Feedback";
import Assessments from "../components/Assessments";
import SurveyResponses from "../components/SurveyResponses"
import SideBar from "../components/layouts/SideBar";
import { useFirebaseAuthentication } from "../services/firebase";
import Button from "../components/Button";
import { StateContext } from "../contexts/State";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfilePhoto from "../components/ProfilePhoto";
import PatientStats from "../components/PatientStats";
import RoutineSelector from "../components/RoutineSelector";
import Modal from "../components/Modal";
import CreateRoutine from "../components/CreateRoutine";
import Patient from "../models/patient";
import Routine from "../models/routine";
import MessageCenter from "../components/MessageCenter";
import FloatingActionButton from "../components/FloatingActionButton";
import TypeformModal from "../components/Typeform/TypeformModal";
import TypeformButtons from "../components/Typeform/TypeformButtons";
import PatientInfo from "../components/PatientInfo";
import WarningSVG from "../components/svg/WarningSVG";
import CloseSVG from "../components/svg/CloseSVG";
import PatientStatistics from "../components/Statistics/PatientStatistics";
import Firebase from "../services/firebase";
import GoogleSheets from "../services/googleSheets";
import TypeformShim from "../services/typeformShim";
import { getDoc } from "firebase/firestore";
import { reassess, getMotionAssessmentTask } from "../models/assessments";

/**
 * PatientDetails page
 * Shows a summary of patient details at the top and
 * shows a list of the assigned routines below
 * It also allows users to be redirected to questionnaire/assessment
 * forms if they haven't completed them, or it displays that information
 */

const PatientDetails = () => {
  const { patientId } = useParams();
  const [currentPatient, setCurrentPatient] = useState(new Patient());
  const { state } = useContext(StateContext);
  const navigate = useNavigate();
  const authUser = useFirebaseAuthentication();
  const [questionnaireModalOpen, setQuestionnaireModalOpen] = useState(false);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [assignedRoutines, setAssignedRoutines] = useState([]);
  const [showWarningText, setShowWarningText] = useState(false);
  const [reservedNames, setReservedNames] = useState([]);

  const [actionTaken, setActionTaken] = useState(false);
  const [assessmentDueDate, setAssessmentDueDate] = useState();

  useEffect(() => {
    if (
      currentPatient.assessmentWarning &&
      !currentPatient.assessmentWarning.read
    ) {
      setShowWarningText(currentPatient.assessmentWarning.showWarning);
    }
  }, [showWarningText, currentPatient.assessmentWarning]);

  const refreshPatientData = useCallback(() => {
    (async () => {
      const patient = await Patient.getById(patientId);
      setCurrentPatient(patient);

      let task = await getMotionAssessmentTask(patient);

      let rtf = new Intl.RelativeTimeFormat("en", {
        numeric: "auto",
        style: "short",
      });

      let due =
        task &&
        rtf.format(
          Math.trunc((task.due.intervals._1.toDate() - new Date()) / 8.64e7),
          "day"
        );

      setAssessmentDueDate(due);
    })();

    if (patientId) {
      (async () => {
        const res = await Routine.getFromSubcollection(patientId);

        setAssignedRoutines(
          res.map((routine) => {
            return routine;
          })
        );
      })();
    }
  }, [patientId]);

  // Loads current patient once the user is verified logged in
  useEffect(() => {
    (() => {
      if (authUser) {
        if (currentPatient.id === "") {
          refreshPatientData();
        }
      }
    })();
  }, [authUser, currentPatient.id, refreshPatientData]);

  // Formats full name for use
  const fullName = useCallback(() => {
    return `${currentPatient.firstName} ${currentPatient.lastName}`;
  }, [currentPatient]);

  const closeTypeformModals = () => {
    setQuestionnaireModalOpen(false);
    setAssessmentModalOpen(false);
  };

  const redirectToRoutine = (routine) => {
    navigate(`routine/${routine.id}/edit`);
  };

  const hideWarning = () => {
    const showRead = {
      showWarning: true,
      read: true,
    };
    Patient.update(currentPatient.id, {
      assessmentWarning: showRead,
    });

    setCurrentPatient((prev) => ({ ...prev, assessmentWarning: showRead }));
    setShowWarningText(false);
  };

  return (
    <AppLogout>
      <SideBar>
        <div className="patient-details-content">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Patients
            </Link>
            /{" "}
            <span className="current-route">
              {currentPatient.firstName && currentPatient.lastName
                ? fullName()
                : currentPatient.email}
            </span>
          </div>
          <div className="header">
            <div className="user-details">
              <ProfilePhoto imgURL={currentPatient.profilePhotoURL} />
              <h2>{fullName()}</h2>
            </div>

            <div className="patient-reassess">
              <div>
                {actionTaken ? (
                  <p data-okay="true">Assessment assigned</p>
                ) : (
                  <p>
                    {assessmentDueDate &&
                      `Next assessment ${assessmentDueDate}`}
                  </p>
                )}
              </div>
              <Button onClick={() => setActionTaken(reassess(currentPatient))}>
                <span>Reassess Now</span>
              </Button>
            </div>
          </div>
          <div className="patient-info__container-wrapper">
            <PatientInfo
              dateOfBirth={currentPatient.dob}
              gender={currentPatient.gender}
              height={currentPatient.height}
              weight={currentPatient.weight}
            />
          </div>
          <div className="patient-details-container">
            <div className="patient-details-container">
              <div className="stacked-container">
                <PatientStatistics patient={currentPatient} />
                <Feedback patient={currentPatient} />
                <Assessments patient={currentPatient} />
                <SurveyResponses patient={currentPatient} />
              </div>
              <MessageCenter
                trainer={state.trainer}
                patient={currentPatient}
                routines={assignedRoutines}
              />
              <div className="patients-header-container">
                <div className="routines-pre-header">
                  <h3>Assigned routines</h3>
                </div>
                <FloatingActionButton
                  id="create-routine-button"
                  fixed={false}
                  className="create-routine-button"
                  type="button"
                  onClick={() => setCreateModalOpen(true)}
                  normalContent={
                    <span className="material-icons-outlined">add</span>
                  }
                  hoverContent={<> Add Routine</>}
                />
              </div>

              <RoutineSelector
                routineList={assignedRoutines}
                trainer={state.trainer}
                owner={currentPatient}
                afterDelete={refreshPatientData}
              />
            </div>

            <Modal
              show={createModalOpen}
              onClose={() => {
                setCreateModalOpen(false);
              }}
              useCloseButton={true}
            >
              <CreateRoutine
                reservedNames={reservedNames}
                closeModal={() => {
                  setCreateModalOpen(false);
                }}
              />
            </Modal>
          </div>
        </div>
      </SideBar>
    </AppLogout>
  );
};
export default PatientDetails;
