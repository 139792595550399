import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../services/helpers";

const CheckboxList = ({
  options = [],
  name = "",
  label = "",
  className = "",
  type = "",
  onChange = () => {},
  onClick = () => {},
}) => {
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    const newSelections = options
      .filter((option) => option.checked)
      .map((option) => option.value);
    setSelections(newSelections);
  }, [options]);

  return (
    <div className={`checkbox-list-container ${className}`}>
      <span className="checkbox-list-label">{label}</span>
      {options.map((option, index) => {
        return (
          <div className={`checkbox-list-item`} key={index}>
            <input
              onChange={(e) => {
                // if (e.target.checked) {
                //   setSelections(selections.concat(e.target.value));
                // } else {
                //   setSelections((oldSelections) => {
                //     const index = oldSelections.indexOf(e.target.value);
                //     const newSelections = oldSelections.splice(index, 1);
                //     return newSelections;
                //   });
                // }
                onChange(e);
              }}
              value={option.value?.toLowerCase()}
              id={option.id}
              name={option.name}
              defaultChecked={option.checked}
              type="checkbox"
              className="radio-list__item"
            />
            <label htmlFor={name}>{capitalizeFirstLetter(option.value)}</label>
          </div>
        );
      })}
    </div>
  );
  // );
};
export default CheckboxList;
