import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Firebase from "../services/firebase";

class BasicFirebaseItem {
  constructor(id = "") {
    this.id = id;
  }
  static build(ref, data) {
    return Object.assign(new this(), data, { id: ref.id });
  }

  prepForSave() {
    let preppedObject = Object.assign({}, { ...this });
    delete preppedObject.id;
    return preppedObject;
  }

  static setupListener(
    customQuery,
    itemType,
    onModify,
    onAdd,
    onDelete,
    loadedAll = () => {},
    itemsPerPage = 0,
    startingIndex = 0
  ) {
    try {
      return onSnapshot(
        customQuery,
        (qSnapshot) => {
          const changes = qSnapshot.docChanges();
          for (let i = changes.length - 1; i >= 0; i--) {
            const change = changes[i];
            const builtItem = itemType.build(change.doc, change.doc.data());
            if (change.type === "added") {
              onAdd(builtItem);
            }
            if (change.type === "modified") {
              onModify(builtItem);
            }
            if (change.type === "removed") {
              onDelete(builtItem);
            }
            if (itemsPerPage > 0) {
              if (qSnapshot.docs.length < itemsPerPage) {
                loadedAll(startingIndex, true);
              } else {
                loadedAll(startingIndex, false);
              }
            }
          }
        },
        (err) => {
          console.error(
            "There was a problem with the listener",
            customQuery,
            itemType,
            onModify,
            onAdd,
            onDelete,
            err
          );
          throw err;
        }
      );
    } catch (err) {
      console.error(
        "There was a problem setting up the listener",
        customQuery,
        itemType,
        onModify,
        onAdd,
        onDelete
      );
      throw err;
    }
  }

  static async getDocFromRef(ref) {
    return await getDoc(ref);
  }

  static async getFromRef(ref) {
    try {
      if (typeof ref === "string") {
        let splitRef = ref.split("/");
        let subRef = [];
        if (splitRef[0] === "") subRef = splitRef.slice(1, -1);
        else subRef = splitRef.slice(0, -1);
        ref = doc(Firebase.db, subRef.join("/"), splitRef[splitRef.length - 1]);
      }
      let retrievedDoc = await getDoc(ref);
      return this.build(ref, retrievedDoc.data());
    } catch (err) {
      console.error(
        `There was a problem getting the ${this.constructor.name} from ref`,
        ref,
        err
      );
      throw err;
    }
  }
}
export default BasicFirebaseItem;
