import Button from "./Button";
import { Link } from "react-router-dom";

const FeedbackRequestItem = ({ id, request, patient }) => {

	const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'short' });

	return (
		<Link to={`/patient/${patient.id}/feedback/${id}`} className="feedback-request-item">
			<div className="material-icons-outlined">feedback</div> 
			<h4>{ request.exercise }</h4>

			<p className="date">{ rtf1.format(Math.floor((request.createdAt.toDate() - new Date()) / 8.64e+7), "days")}</p>
		</Link>
	)
}

export default FeedbackRequestItem
