import React, { useEffect, useState, useCallback } from "react";
import ProfilePhoto from "./ProfilePhoto";
import { useNavigate } from "react-router-dom";
import PatientStats from "./PatientStats";
import Patient from "../models/patient";
import CloseSVG from "./svg/CloseSVG";
import Firebase from "../services/firebase";

/**
 * PatientCard Component
 * @param patient Patient whose information should be displayed
 * @param className Additional class names to be applied
 * @returns A styled component to display patient information
 */
function PatientCard({
  patient = new Patient(),
  className = "",
  onSetShowDeleteModal = () => {},
  onSetPatientToDelete = () => {},
}) {
  const [adherenceColor, setAdherenceColor] = useState("#443C3C"); // Holds the color for status bar and progress wheel
  const [adherence, setAdherence] = useState(0);
  const navigate = useNavigate();

  const getAdherence = useCallback(async () => {
    if (patient.id) {
      const data = -1
      if (data?.adherence > 0) {
        // console.log(data.adherence)
        setAdherence(data.adherence * 100);
      } else {
        setAdherence(-1);
      }
    }
  }, [patient.id]);

  useEffect(() => {
    if (patient.id) {
      getAdherence(patient.id);
    }
  }, [getAdherence, patient.id]);

  // Generates the color based on adherence percent
  useEffect(() => {
    if (adherence > 66) {
      setAdherenceColor("#34A853");
    } else if (adherence > 33) {
      setAdherenceColor("#FCB514");
    } else if (adherence > 0) {
      setAdherenceColor("#FF4848");
    } else {
      setAdherenceColor("#443C3C");
    }
  }, [adherence]);

  // Redirects to patient details page
  const redirectToPatient = () => {
    navigate(`/patient/${patient.id}`);
  };

  return (
    <div className={"patient-card " + className}>
      <CloseSVG
        className={"delete-patient__btn"}
        onClick={() => {
          console.log("clicked");
          onSetShowDeleteModal(true);
          onSetPatientToDelete(patient.id);
        }}
      />
      <div onClick={redirectToPatient}>
        <div
          className="status-color"
          style={{
            background: adherenceColor,
          }}
        />
        <div className="patient-content">
          <h4>{`${patient.firstName} ${patient.lastName}`}</h4>
          <p className="patient-email subtext">{patient.email}</p>
        </div>
        <ProfilePhoto
          imgURL={patient.profilePhotoURL}
          className="patient-picture"
        />
      </div>
    </div>
  );
}

export default PatientCard;
