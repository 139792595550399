import React, { Component } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import ExerciseSet from "../models/exerciseSet";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
} from "../services/helpers";
import LogoSVG from "./svg/LogoSVG";
import TextSVGContainer from "./svg/TextSVGContainer";

/**
 * ExerciseBase Component
 * Creates a base exercise component to be reused in the AvailableExercise and SelectedExercise components
 * @param exerciseSet ExerciseSet from which to take data to display
 * @param onClick Function to be called when main exercise container is clicked
 */
export default class ExerciseBase extends Component {
  static defaultProps = {
    exerciseSet: new ExerciseSet(),
    onClick: () => {},
    onDragStart: () => {},
    onDragEnd: () => {},
    index: -1,
    onEdit: () => {},
    onDelete: () => {},
    editable: false,
  };

  constructor(props) {
    super(props);
  }

  /**
   *
   * @returns Html to be put inside the exercise details subtext depending on text that is available
   */
  chooseDetails() {
    if (this.props.exerciseSet.work.length > 0) {
      return (
        <>
          <span className="subdetails">
            Sets: {this.props.exerciseSet.work.length}
          </span>
          <span className="subdetails">
            {this.props.exerciseSet.unit
              ? capitalizeFirstLetter(this.props.exerciseSet.unit)
              : "Reps"}
            : {this.props.exerciseSet.work[0]}
          </span>
        </>
      );
    } else if (
      this.props.exerciseSet.exercise.primaryMusclesWorked.length > 0
    ) {
      let musclesWorked = this.props.exerciseSet.exercise.primaryMusclesWorked
        .map((muscle) => capitalizeAllFirstLetters(muscle))
        .join(", ");
      return `Primary Muscles Worked: ${musclesWorked}`;
    } else if (this.props.exerciseSet.exercise.shortInstructions) {
      return this.props.exerciseSet.exercise.shortInstructions;
    } else if (this.props.exerciseSet.exercise.instructions) {
      return this.props.exerciseSet.exercise.instructions;
    } else {
      return (
        <>
          <span className="subdetails"></span>
        </>
      );
    }
  }

  render() {
    return (
      <div
        className="exercise-base-container"
        onClick={this.props.onClick}
        onDragStart={(e) => {
          this.props.onDragStart(e, this.props.exerciseSet, this.props.index);
        }}
        onDragEnd={this.props.onDragEnd}
        draggable
      >
        <div className="exercise-photo">
          {/* {this.props.exerciseSet.exercise.imgURL ? (
            <LazyLoadImage
              alt={`Photo of ${this.props.exerciseSet.exercise.displayName}`}
              src={this.props.exerciseSet.exercise.imgURL}
              wrapperClassName="actual-photo"
              effect="opacity"
              draggable={false}
            />
          ) : (
            <></>
          )} */}
        </div>
        <div className="exercise-info">
          <div className="exercise-name">
            <h4>{((this.props.exerciseSet.exercise.displayName).split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1))).join(' ')}</h4>
          </div>
          <div className="exercise-details">{this.chooseDetails()}</div>
        </div>
        {this.props.editable ? (
          <div className="exercise-edit-buttons">
            <span
              className="material-icons-outlined"
              onClick={(e) => {
                e.stopPropagation();
                this.props.onEdit();
              }}
            >
              drive_file_rename_outlined
            </span>
            <span
              className="material-icons-outlined"
              onClick={this.props.onDelete}
            >
              delete
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
