import Button from "./Button";
import { Link } from "react-router-dom";
import { titleCase, toDisplayName } from "../utils/text"

const SurveyResponseItem = ({ id, response, patient }) => {
    const rtf1 = new Intl.RelativeTimeFormat("en", { numeric: 'auto', style: "short" });

  return (
    <Link
      to={`/patient/${patient.id || patient.uid}/responses/${id}`}
      className="assessment-item feedback-request-item"
    >
      <div className="material-icons-outlined">assessment</div>

      <h4>
		{ toDisplayName(response.name) }
      </h4>

      <p>
        {titleCase(rtf1.format(
          Math.trunc((response.completedAt.toDate() - new Date()) / 8.64e7),
          "day"
        ))}
      </p>
    </Link>
  );
};

export default SurveyResponseItem;
