import React from "react";

const SelectFilter = ({ className, onChange = () => {}, options = [] }) => {
  return (
    <div className={`select-component ${className}`}>
      <label htmlFor="">
        <div className="select-container">
          <span className="material-icons-outlined md-18">sort</span>
          <select
            // name=""
            // id=""
            onChange={onChange}
          >
            {options.map((value, key) => (
              <option value={value} key={key}>{value}</option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );
};
export default SelectFilter;
