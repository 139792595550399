import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import Button from "../components/Button";

import { StateContext } from "../contexts/State";

function latest(r, title, email) {
  if (r) {
    let res = r.find((l) => {
      return l.title === title;
    });

    if (res) {
      let ret = res.data
        .filter((l) => {
          return l["User email (Do not change)"] === email;
        })
        .map((l) => {
          return { ...l, timestamp: new Date(l["Timestamp"]) };
        })
        .reduce((acc, x) => {
          if (!acc) {
            // Most recent reduce.
            return x.timestamp;
          } else {
            if (x.timestamp > acc) {
              return x.timestamp;
            } else {
              return acc;
            }
          }
        }, undefined);
      if (!ret) {
        return undefined;
      }
      return res.data.find((q) => {
        return new Date(q["Timestamp"]).getTime() === ret.getTime();
      });
    }
  }
  return null;
}

const GoogleSheets = ({
  formType = "", // this will either be "question" or "assessment"
  canEdit = false, // determines whether the 'Add / Replace respsonse' button will appear
  formUrl = "", // This is the link to the form the trainer will use or send out to the patient
  currentPatient = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [responses, setResponses] = useState([]);

  const { state } = useContext(StateContext);

  const generateUrl = function (e) {
    return `https://docs.google.com/forms/d/e/${e.form}/viewform?usp=pp_url&entry.${e.prefill}=${currentPatient.email}`;
  };
  // https://v2.dashboard.ezpt.xyz/assessments/WkCyvG+RVW1KCzro2J5TqkVq?user=Ne20AwWe0dVRzHpzQmjGKeVR90R2&trainer=8AmOPJgczdSUsVo5fLRBNkot1Me2&evaluation=Supine+Evaluation
  const v2Url = function (e) {
    return `https://v2.dashboard.ezpt.xyz/assessments/WkCyvG+RVW1KCzro2J5TqkVq?user=${
      currentPatient.id
    }&trainer=${state.trainer.id}&evaluation=${encodeURIComponent(e.sheet)}`;
  };

  // Responses URL
  useEffect(() => {
    async function fetchData() {
      const responseUrl = function (e) {
        return `https://docs.google.com/spreadsheets/d/${
          state.trainer.spreadsheets.assessments.id
        }/gviz/tq?tqx=out:csv&sheet=${encodeURIComponent(e.sheet)}`;
      };

      const toJson = function (e) {
        return new Promise((resolve, reject) => {
          Papa.parse(responseUrl(e), {
            download: true,
            header: true,
            complete(results, file) {
              resolve(results.data);
            },
            error(err, file) {
              reject(err);
            },
          });
        });
      };

      let responses = await Promise.all(
        state.trainer.forms.assessments.map(async (e) => {
          return { title: e.title, data: await toJson(e) };
        })
      );

      setResponses(responses);

      setLoading(false);
    }
    fetchData();
  }, [
    state.trainer.forms.assessments,
    state.trainer.spreadsheets.assessments.id,
  ]);

  useEffect(() => {
    setAssessments(state.trainer.forms.assessments);
  }, [state.trainer, assessments]);

  return (
    <div className="sheets-container">
      <div className="sheets-modal-header">
        {formType === "questionnaire" ? (
          <h2>Questionnaire</h2>
        ) : (
          <h2>Assessment</h2>
        )}
        {assessments.map((e) => (
          <div key={e.title} className="modeal-item-container">
            <div className="modal-item-header">
              <h3>{e.title}</h3>
              {currentPatient.email ? (
                <Button
                  type="button"
                  format="transparent"
                  onClick={() => {
                    window.open(generateUrl(e), "_blank");
                  }}
                >
                  Add / Replace Response
                </Button>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modeal-item-responses">
              {loading ? (
                <h3>Loading Responses...</h3>
              ) : latest(responses, e.title, currentPatient.email) ? (
                <div>
                  <Button
                    type="button"
                    className="v2-report"
                    format="transparent"
                    onClick={() => {
                      window.open(v2Url(e), "_blank");
                    }}
                  >
                    View Report
                  </Button>
                  {Object.entries(
                    latest(responses, e.title, currentPatient.email)
                  )
                    .filter((x) => {
                      return (
                        x[0] !== "Timestamp" &&
                        x[0] !== "User email (Do not change)"
                      );
                    })
                    .map((item) =>
                      item[1] ? (
                        <div key={item[0]} className="answer">
                          <div className="question-header">
                            <h4>{item[0]}</h4>
                          </div>
                          <div className="typeform-response">
                            <p>{item[1]}</p>
                          </div>
                        </div>
                      ) : null
                    )}
                  <hr />
                </div>
              ) : (
                <p>There isn't a response stored for this assessment yet.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default GoogleSheets;
