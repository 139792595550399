import {
  addDoc,
  collection,
  getDoc,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Timestamp,
  arrayRemove,
  updateDoc,
  startAt,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import Trainer from "./trainer";
import { v1 as uuidv1 } from "uuid";

class Message extends BasicFirebaseItem {
  static MESSAGES_PER_PAGE = 1000;
  constructor(
    id = "",
    type = "", // Options are file, routine, text, nudge
    data = "",
    sentTimestamp = new Timestamp(),
    sender = ""
  ) {
    super(id);
    this.type = type;
    this.data = data;
    this.sentTimestamp = sentTimestamp;
    this.sender = sender;
  }

  static getCollection(trainerId, patientId) {
    return collection(Firebase.db, `messages/${trainerId}/${patientId}`);
  }

  static getRef(trainerId, patientId, messageId) {
    return doc(Firebase.db, `messages/${trainerId}/${patientId}/${messageId}`)
  }
  
  static getDocument(trainerId) {
    const messages = doc(Firebase.db, `messages/${trainerId}`)
    return messages
  }

  static async getMessageDoc(trainerId) {
    const unreadMessages = await getDoc(
      doc(Firebase.db, `messages/${trainerId}`)
    );
    return unreadMessages.data();
  }

  static async getMessages(trainerId, patientId, startingIndex = 0) {
    try {
      const messageQuery = Message.makeQuery(
        trainerId,
        patientId,
        startingIndex
      );
      const messageSnapshots = await getDocs(messageQuery);
      let result = [];
      messageSnapshots.docs.forEach((doc) => {
        result.push(Message.build(doc, doc.data()));
      });
      return result;
    } catch (err) {
      console.error(
        "There was a problem getting messages",
        trainerId,
        patientId,
        startingIndex,
        err
      );
      throw err;
    }
  }

  static makeQuery(trainerId, patientId, startingIndex = 0) {
    try {
      const messageCol = Message.getCollection(trainerId, patientId);
      if (startingIndex <= 0) {
        return query(
          messageCol,
          orderBy("sentTimestamp", "desc"),
          limit(Message.MESSAGES_PER_PAGE)
        );
      } else {
        return query(
          messageCol,
          orderBy("sentTimestamp", "desc"),
          startAfter(startingIndex),
          limit(Message.MESSAGES_PER_PAGE)
        );
      }
    } catch (err) {
      console.error(
        "There was a problem making the query",
        trainerId,
        patientId,
        startingIndex,
        err
      );
      throw err;
    }
  }

  static setupListener(
    trainerId,
    patientId,
    onModify,
    onAdd,
    onDelete,
    loadedAll,
    startingIndex = 0
  ) {
    try {
      // let initState = true;
      console.log("Setting up listener", trainerId, patientId);
      const messageQuery = Message.makeQuery(
        trainerId,
        patientId,
        startingIndex
      );
      // messageQuery = query(messageCol, orderBy('sentTimestamp', 'desc'), startAfter(startingIndex), limit(Message.MESSAGES_PER_PAGE));
      return super.setupListener(
        messageQuery,
        Message,
        onModify,
        onAdd,
        onDelete,
        loadedAll,
        Message.MESSAGES_PER_PAGE,
        startingIndex
      );
    } catch (err) {
      console.error(
        "There was a problem setting up the message listener",
        trainerId,
        patientId,
        onModify,
        onAdd,
        onDelete,
        err
      );
      throw err;
    }
  }

  static removeListener(listener) {
    console.log("removing listener", listener);
    try {
      listener();
    } catch (err) {
      console.error("There was a problem closing the listener");
    }
  }

  static async uploadAndSendFile(trainerId, patientId, file, filename) {
    try {
      const splitFileName = file.name.split(".");
      const filetype = splitFileName[splitFileName.length - 1];
      const newFileRef = ref(
        Firebase.storage,
        `messages/${trainerId}/${patientId}/${uuidv1()}.${filetype}`
      );
      await uploadBytes(newFileRef, file);
      const downloadUrl = await getDownloadURL(newFileRef);
      let message = new Message();
      message.data = { url: downloadUrl, filename: filename };
      message.sender = Trainer.getRef(trainerId);
      message.type = "file";
      message.send(trainerId, patientId);
    } catch (err) {
      console.error(
        "There was a problem uploading and or sending the file.",
        err
      );
    }
  }

  static async sendNudge(trainer, patientId) {
    try {
      const randomIndex = Math.floor(Math.random() * trainer.nudges.length);
      const selectedMessage = trainer.nudges[randomIndex];
      const nudge = new Message(
        "",
        "nudge",
        selectedMessage,
        "",
        Trainer.getRef(trainer.id)
      );
      nudge.send(trainer.id, patientId);
    } catch (err) {
      console.error(
        "There was a problem sending the nudge:",
        trainer.id,
        patientId,
        err
      );
    }
  }

  async send(trainerId, patientId) {
    try {
      const messageCol = Message.getCollection(trainerId, patientId);
      this.sentTimestamp = Timestamp.now();
      const preppedData = this.prepForSave();
      await addDoc(messageCol, preppedData);
    } catch (err) {
      console.error(
        "There was a problem saving the message: ",
        trainerId,
        patientId,
        err
      );
      throw err;
    }
  }

  static async markAsRead(trainerId, patientId) {
    try {
      const messageDoc = await Message.getMessageDoc(trainerId);
      if (messageDoc.unreadMessages) {
        try {
          const updatedMessages = await updateDoc(
            doc(Firebase.db, `messages/${trainerId}`),
            {
              unreadMessages: arrayRemove(patientId),
            }
          );
          return updatedMessages;
        } catch (error) {
          console.error("There was an error updating unread messages: ", error);
        }
      } else {
        return [];
      }
    } catch (error) {
      console.error("Could not retrieve message document: ", error);
    }
  }

  // gets most recent message
  // static async getRecentMessage(trainerId, patientId, callback = () => {}) {
  //   try {
  //     const messageCol = Message.getCollection(trainerId, patientId);
  //     try {
  //       const messages = query(
  //         messageCol,
  //         orderBy("sentTimestamp", "desc"),
  //         limit(1)
  //       );
  //       const data = [];
  //       onSnapshot(messages, (querySnapshot) => {
  //         querySnapshot.forEach((doc) => {
  //           data.push(doc.data());
  //         });
  //         console.log(data);
  //         // data.length > 0 ? callback(data) : callback([])
  //       });
  //       // return data.length > 0 ? data[0] : [];
  //       return data
  //     } catch (error) {
  //       console.error("Error getting most recent message: ", error);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "Could not retrive messsage collection for user ",
  //       patientId,
  //       error
  //     );
  //   }
  // }

  // get aiMessages
  static async getAiMessages(userID) {
    try {
      const collectionRef = collection(Firebase.db, `users/${userID}/aiMessages`);
      const aiQuery = query(
        collectionRef,
        orderBy("sentTimestamp", "desc"),
        limit(Message.MESSAGES_PER_PAGE)
      )
      const querySnapshot = await getDocs(aiQuery);

      return querySnapshot
    }
    catch (err) {
      console.error(
        "There was a problem getting AI health coach messages",
        userID,
        err
      );
      throw err;
    }
  }
}

export default Message;
