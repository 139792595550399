import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import TextInput from "./TextInput";
import Button from "./Button";
import CheckboxList from "./CheckboxList";
import TextBox from "./TextBox";
import TextListInput from "./TextListInput";
import UploadFileModal from "./UploadFileModal";
import FileUpload from "./FileUpload";
import Exercise from "../models/exercise";
import Trainer from "../models/trainer";

const NewExerciseModal = ({
  trainerId,
  show = false,
  exercise,
  setClose = () => { },
}) => {
  // const [image, setImage] = useState({})
  const [exerciseName, setExerciseName] = useState("");
  const [instructions, setInstructions] = useState("");
  const [defaultNotes, setDefaultNotes] = useState("");
  const [primaryJointsWorked, setPrimaryJointsWorked] = useState([]);
  const [primaryMusclesWorked, setPrimaryMusclesWorked] = useState([]);
  const [secondaryMusclesWorked, setSecondaryMusclesWorked] = useState([]);
  const [units, setUnits] = useState([]);
  const [imgUrl, setImgUrl] = useState("");
  const [imgPath, setImgPath] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPath, setVideoPath] = useState(null);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setExerciseName(exercise.displayName);
    setInstructions(exercise.instructions);
    setDefaultNotes(exercise.defaultNotes);
    setPrimaryJointsWorked([...exercise.primaryJointsWorked]);
    setPrimaryMusclesWorked([...exercise.primaryMusclesWorked]);
    setSecondaryMusclesWorked([...exercise.secondaryMusclesWorked]);
    // setUnits([...exercise.units]);
    setImgUrl(exercise.imgURL);
    setImgPath(exercise.imgPath);
    // setVideoUrl(exercise.videoURL);
    // setVideoPath(exercise.videoPath);
  }, [
    exercise,
    setExerciseName,
    setInstructions,
    setDefaultNotes,
    setPrimaryJointsWorked,
    setPrimaryMusclesWorked,
    setSecondaryMusclesWorked,
    // setUnits,
    setImgPath,
    setImgUrl,
    // setVideoPath,
    // setVideoUrl,
  ]);

  const save = () => {
    const exerciseToSave = Object.assign(
      new Exercise(),
      { ...exercise },
      {
        name: exerciseName.trim().replace(/\s+/g, '-').toLowerCase(),
        displayName: exerciseName,
        primaryJointsWorked: [...primaryJointsWorked],
        primaryMusclesWorked: [...primaryMusclesWorked],
        secondaryMusclesWorked: [...secondaryMusclesWorked],
        instructions: instructions,
        defaultNotes: defaultNotes,
        units: [...units],
        imgURL: imgUrl,
        imgPath: imgPath,
        videoURL: videoUrl,
        videoPath: videoPath,
        owner: Trainer.getRef(trainerId),
      }
    );
    setIsSaving(true);
    exerciseToSave
      .save(trainerId)
      .then(() => {
        setIsSaving(false);
        setClose();
      })
      .catch((err) => {
        console.error("There was a problem saving the new exercise", err);
        setIsSaving(false);
      });
  };

  const deleteImg = async () => {
    await exercise.deleteImg();
    setClose();
  };
  const deleteVideo = async () => {
    await exercise.deleteVideo();
    setClose();
  };

  const close = () => {
    if (!isSaving) {
      setClose();
    }
  };

  useEffect(() => {
    console.log(units);
  }, [units]);

  return (
    <Modal
      show={show}
      onClose={close}
      useCloseButton={true}
      className="new-exercise-container"
    >
      <div className="shim-button">
        <h3>Create A New Exercise</h3>
        <div className="modal-buttons new-exercise-buttons">
          <Button type="button" format="transparent" onClick={close}>
            Cancel
          </Button>
          <Button
            type="minimal"
            format="minimal"
            onClick={save}
            className="upload-button"
            disabled={
              !exerciseName ||
              !instructions
            }
          >
            Save
          </Button>
        </div>
      </div>
      <div className="new-exercise-content">
        <TextInput
          label="Name *"
          placeholder="Name of the exercise"
          defaultValue={exerciseName}
          type="text"
          min="1"
          onChange={(e) => {
            // TODO: send the name as formatted with dashes
            // setExerciseName(e.target.value.toLowerCase().split(" ").join("-"))
            setExerciseName(e.target.value);
          }}
        />
        <CheckboxList
          label="Units *"
          id="units-selection"
          name="units"
          options={[
            {
              value: "time",
              checked:
                exercise.units?.includes("time") || units.includes("time"),
            },
            {
              value: "reps",
              checked:
                exercise.units?.includes("reps") || units.includes("reps"),
            },
          ]}
          onChange={(e) => {
            // setUnits([...selectedUnits])
            if (e.target.checked) {
              setUnits(units.concat(e.target.value));
            } else {
              setUnits((oldSelections) => {
                const index = oldSelections.indexOf(e.target.value);
                const newSelections = oldSelections.splice(index, 1);
                return newSelections;
              });
            }
          }}
        />
        <TextBox
          id="instructions"
          placeholder="Describe your exercise..."
          value={instructions}
          label="Instructions *"
          onChange={(e) => {
            setInstructions(e.target.value);
          }}
        />
        <TextBox
          id="default-notes"
          placeholder="Add notes..."
          value={defaultNotes}
          label="Notes"
          onChange={(e) => {
            setDefaultNotes(e.target.value);
          }}
        />
        <TextListInput
          label="Primary Joints Worked"
          defaultValue={primaryJointsWorked}
          onChange={(list) => setPrimaryJointsWorked([...list])}
        />
        <div></div>
        <TextListInput
          label="Primary Muscles Worked"
          defaultValue={primaryMusclesWorked}
          onChange={(list) => {
            setPrimaryMusclesWorked([...list]);
          }}
        />
        <TextListInput
          label="Secondary Muscles Worked"
          defaultValue={secondaryMusclesWorked}
          onChange={(list) => setSecondaryMusclesWorked([...list])}
        />

        {/* /////////////////////////////////////////////////
        // **** Not using exercise images for now **** //
        ///////////////////////////////////////////////// */}
        {/* <FileUpload
          label="Exercise Photo"
          accept="image/*"
          startUpload={() => setIsUploadingImg(true)}
          finishUpload={() => setIsUploadingImg(false)}
          downloadURL={imgUrl}
          filePath={imgPath}
          onUploadComplete={(downloadURL, ref) => {
            setImgUrl(downloadURL);
            setImgPath(ref.fullPath);
          }}
          targetFolder={`exercisePhotos/${trainerId}/`}
          deleteFile={deleteImg}
        /> */}
        <FileUpload
          label="Exercise Video"
          accept="video/mp4"
          startUpload={() => setIsUploadingVideo(true)}
          finishUpload={() => setIsUploadingVideo(false)}
          downloadURL={videoUrl}
          filePath={videoPath}
          onUploadComplete={(downloadURL, ref) => {
            setVideoUrl(downloadURL);
            setVideoPath(ref.fullPath);
          }}
          targetFolder={`exerciseVideos/${trainerId}/`}
          deleteFile={deleteVideo}
          type="video"
        /> 
      </div>
      {isSaving ? (
        <div className="new-exercise-cover">Saving the new exercise</div>
      ) : (
        <></>
      )}
    </Modal>
  );
};
export default NewExerciseModal;
