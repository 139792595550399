import React, { Component } from "react";
import SearchSVG from "./svg/SearchSVG";
import TextSVGContainer from "./svg/TextSVGContainer";
import Fuse from 'fuse.js';

/**
 * SearchBar Component
 * Takes in an array and field names through which it should search for
 * search terms typed in
 * @param id Id to be given to search input
 * @param className Additional class names to be applied
 * @param updateArray Function that is passed the newly filtered array
 * @param inputArray Input array through which to search
 * @param searchFields Field names to consider in search
 * @param placeholder Placeholder text to be displayed
 */
class FuzzySearch extends Component {
    static defaultProps = {
        id: "",
        className: "",
        updateArray: (newArray) => {
            /* This should update our display array */
        },
        inputArray: [],
        searchFields: [],
        placeholder: "Search...",
    };
    constructor(props) {
        super(props);
    }
    count = 0;

  
    // Iterates through each item and verifies whether it meets search criteria
    filterArray = (value) => {  //value is the search 
        let input = this.props.inputArray;  //list of exercise to search through
        const key = this.props.searchFields; //key we're searching through
        const options = {
            includeScore: true,
            keys: key
        }
        const fuse = new Fuse(input, options);  //creating the fuzzy search
        if (value.trim() === '') {
            this.props.updateArray(input);
        }
        else{
            const results = fuse.search(value); //searching for what the user entered
            const mapOfResults = results.map(input => input.item);
            this.props.updateArray(mapOfResults); //updating list with our results
        }
    };

    // Simple debounce function for performance that ensures that
    // at least 300ms pass between the last keystroke and the filter running
    startFilter = (value) => {
        this.count++;
        let countCopy = this.count;
        setTimeout(() => {
            if (this.count == countCopy) {
                this.filterArray(value);
            }
        }, 300);
    };

    render() {
        return (
            <div className={"search-bar " + this.props.className}>
                <span className="search-icon">
                    <TextSVGContainer>
                        <SearchSVG />
                    </TextSVGContainer>
                </span>
                <input
                    id={this.props.id}
                    className={"input"}
                    type="text"
                    placeholder={this.props.placeholder}
                    name={this.props.name}
                    onChange={(e) => {
                        this.startFilter(e.target.value);
                    }}
                ></input>
            </div>
        );
    }
}

export default FuzzySearch;
