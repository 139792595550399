// TODO: This module should be on of the first to move to the new client interface.

import {
  serverTimestamp,
  doc,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  setDoc,
	orderBy,
	limit
} from "firebase/firestore";
import { ref, getStorage, getDownloadURL } from "firebase/storage";
import Firebase from "../services/firebase";

export { getReplay, getAssessment, getAssessments, reassess, getMotionAssessmentTask };

async function getReplay(user, exercise, reference, label) {
	const storage = getStorage(Firebase.app, "gs://apex-motion-assessment");

	let object = ref(storage, `replays/${user}/ohsa/${exercise}/${reference}/${label}.mp4`)

	return await getDownloadURL(object);
}

async function getAssessments(user) {
  if (!user.id) {
    return [];
	} // Avoid re-render problems: see #25
  let requests = collection(Firebase.db, `users/${user.id}/assessments`);
  let q = query(requests);
  const snap = await getDocs(q);
  return snap.docs.map((d) => [d.id, d.data()]);
}

async function getAssessment(user, requestId) {
  let d = doc(Firebase.db, `users/${user.uid}/assessments/${requestId}`);
  return (await getDoc(d)).data();
}

async function reassess(user) {

  let tasks = collection(Firebase.db, `users/${user.id}/tasks`);
  let q = query(tasks, where("type", "==", "motionAssessment"));

  const snap = await getDocs(q);

  return await Promise.all(
    snap.docs.map(
      async (doc) =>
        await setDoc(
          doc.ref,
          { due: { intervals: { _0: 1, _1: serverTimestamp() } } },
          { merge: true }
        )
    )
  );
}

async function getMotionAssessmentTask(user) {
  if (!user.id) {
    return undefined;
  } // See #25.

  let tasks = collection(Firebase.db, `users/${user.id}/tasks`);
  let q = query(tasks, where("taskType", "==", "motionAssessment"), orderBy("createdAt", "asc"), limit(1));

  const snap = await getDocs(q);

	return snap.docs[0].data()
}
