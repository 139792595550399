import React from "react";
import TextSVGContainer from "./svg/TextSVGContainer";
import Button from "./Button";

/**
 * @param setEditPatientModalOpen
 * @returns Two Buttons, one for opening the QuestionnaireModal, the other AssessmentModal
 */
const EditPatientButton = ({
  setEditPatientModalOpen = false,
}) => {
  return (
    <div className="test-buttons">
      <Button
        id="test-button"
        className="test-button"
        type="button"
        onClick={async () => {
          setEditPatientModalOpen(true);
        }}
        children={
          <>
            <TextSVGContainer>
              <span className="material-icons-outlined">fact_check</span>
            </TextSVGContainer>
            {`Create Patient`}
          </>
        }
        successCallback={() => {}}
        errorCallback={() => {}}
        format="minimal"
      />
    </div>
  );
};
export default EditPatientButton;
