import { React, useState } from "react";
import Message from "../models/message";
import ExerciseSet from "../models/exerciseSet";
import Button from "./Button";
import Modal from "./Modal";
import TextInput from "./TextInput";
import { useContext } from "react";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "../services/helpers";
import SelectComponent from "./SelectComponent";
import TextBox from "./TextBox";

/**
 * A reusable Modal component
 * @param className classes that get passed to the modal
 * @param show a boolean for whether or not the modal is open
 * @param onClose a function to call when the element closes
 * @param onSave a function to call when the 'Save' button is clicked
 * @param children Content to be shown inside modal
 * @param useCloseButton Whether or not there should be a close button and if modal can be closed by clicking outside of it
 * @returns
 */
function AddExerciseModal({
  className = "",
  show,
  onClose = () => { },
  useCloseButton = true,
  onSave = () => { },
  exerciseSaveData,
}) {
  const [newSets, setNewSets] = useState(0);
  const [newWork, setNewWork] = useState(0);
  const [newUnit, setNewUnit] = useState("");
  const [newNote, setNewNote] = useState("");

  useEffect(() => {
    try {
      if (exerciseSaveData.exerciseSet) {
        console.log(exerciseSaveData);
        if (exerciseSaveData.exerciseSet.exercise.units.length === 1) {
          setNewUnit(exerciseSaveData.exerciseSet.exercise.units[0]);
        } else {
          setNewUnit(exerciseSaveData.exerciseSet.unit || "time");
        }
        setNewSets(exerciseSaveData.exerciseSet.work.length);
        setNewWork(
          exerciseSaveData.exerciseSet.work.length
            ? exerciseSaveData.exerciseSet.work[0]
            : 0
        );
        setNewNote(exerciseSaveData.exerciseSet.notes);
      }
    } catch (err) {
      console.error(
        "There was a problem when exerciseSaveData Changed",
        exerciseSaveData,
        err
      );
    }
  }, [exerciseSaveData]);

  const changeUnit = (e) => {
    setNewUnit(e.target.value);
  };

  const changeSets = (e) => {
    setNewSets(Number(e.target.value));
  };

  const changeWork = (e) => {
    setNewWork(Number(e.target.value));
  };

  const changeNote = (e) => {
    setNewNote(e.target.value);
  };

  const saveData = () => {
    onSave(newSets, newWork, newUnit, newNote);
  };

  const closeModal = () => {
    onClose();
  };

  const unitSelect = () => {
    if (
      exerciseSaveData.exerciseSet &&
      exerciseSaveData.exerciseSet.exercise.units.length > 1
    ) {
      return (
        <SelectComponent value={newUnit} onChange={changeUnit} label="Units">
          <option value="select" disabled>Select a unit</option>
          {exerciseSaveData.exerciseSet.exercise.units.map((unit, index) => {
            return (
              <option value={unit} key={`option-${unit}-${index}`}>
                {capitalizeFirstLetter(unit)}
              </option>
            );
          })}
        </SelectComponent>
      );
    } else return <></>;
  };
  try {
    return (
      <Modal
        show={show}
        onClose={closeModal}
        useCloseButton={useCloseButton}
        className={`add-exercise-modal ${className}`}
      >
        {exerciseSaveData.exerciseSet ? (
          <>
            <h3>{((exerciseSaveData.exerciseSet.exercise.displayName).split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1))).join(' ')}</h3>
            {newUnit !== "" ? (
              <div className="sets-container">
                {unitSelect()}
                <TextInput
                  label="Sets"
                  placeholder="Number of Sets"
                  value={newSets ? newSets : ""}
                  type="number"
                  min="1"
                  onChange={changeSets}
                />
                <TextInput
                  label={capitalizeFirstLetter(newUnit)}
                  placeholder={`${capitalizeFirstLetter(newUnit)}`}
                  value={newWork ? newWork : ""}
                  type="number"
                  min="1"
                  onChange={changeWork}
                />
              </div>
            ) : (
              <></>
            )}
            <TextBox
              id="message-input"
              placeholder="Add any additional notes here..."
              onChange={changeNote}
              value={newNote}
              label="Notes"
            />
            <div className="modal-buttons">
              <Button
                type="minimal"
                format="minimal"
                onClick={saveData}
                className="save-button"
                disabled={newSets <= 0 || newWork <= 0 || !newUnit}
              >
                Save
              </Button>
              <Button type="button" format="transparent" className="cancel-button" onClick={closeModal}>
                Cancel
              </Button>
            </div>
            {/* <div className="exercise-details">
              <h4>Description</h4>
              <p className="exercise-description">
                {exerciseSaveData.exerciseSet.exercise.instructions}
              </p>
            </div> */}
          </>
        ) : (
          <></>
        )}
      </Modal>
    );
  } catch (err) {
    console.error("There was a problem rendering the AddExerciseModal", err);
  }
}

export default AddExerciseModal;
