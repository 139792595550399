import React, { Component } from "react";
import ExerciseBase from "./ExerciseBase";
import TextInput from "./TextInput";
import Button from "./Button";
import Modal from "./Modal";

/**
 * This component creates an available exercise that:
 * adds a handle to add the exercise,
 * gets information for the selected exercise,
 * then sends the constructed ExerciseSet to the passed in onSelect function
 * @param exerciseSet a generated exercise set with blank sets/work
 * @param onSelect function that accepts the filled exerciseSet
 */
export default class AvailableExercise extends Component {
  static defaultProps = {
    exerciseSet: {},
    onSelect: (ExerciseSet) => {},
		onClick: () => {},
    dragStart: () => {},
    onDragEnd: () => {},
    openModal: () => {},
    onEdit: () => {},
    onDelete: () => {},
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="available-exercise-wrapper">
        <div className="handle" onClick={this.props.openModal}>
          <span className="material-icons-outlined">add_circle_outline</span>
        </div>
        <ExerciseBase
          exerciseSet={this.props.exerciseSet}
          onDragStart={(e, exerciseSet, initialIndex) => {
            this.props.dragStart(e, exerciseSet, -1);
          }}
					onClick={this.props.exerciseSet.exercise.ownerLabel === "EZPT" ? this.props.onClick : () => {}}
          onDragEnd={this.props.onDragEnd}
          editable={true}
          onDelete={this.props.onDelete}
          onEdit={this.props.onEdit}
        />
      </div>
    );
  }
}
