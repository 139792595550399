import {
  arrayRemove,
  arrayUnion,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import Patient from "./patient";

class Trainer extends BasicFirebaseItem {
  constructor(
    id = "",
    clinicName = "",
    firstName = "",
    lastName = "",
    location = "",
    patients = [],
    profilePhotoURL = "",
    forms = [],
    questionnaireTypeformId = "",
    routineTemplates = [],
    nudges = []
  ) {
    super(id);
    this.clinicName = clinicName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.location = location;
    this.patients = patients;
    this.profilePhotoURL = profilePhotoURL;
    this.forms = forms;
    this.questionnaireTypeformId = questionnaireTypeformId;
    this.routineTemplates = routineTemplates;
    this.nudges = nudges;
  }

  static async getById(id) {
    try {
      let trainerRef = doc(Firebase.db, "trainers", id);
      let result = await Trainer.getFromRef(trainerRef);
      result.patients = await Promise.all(
        result.patients.map(
          async (patientRef) => await Patient.getFromRef(patientRef)
        )
      );
      return result;
    } catch (err) {
      throw err;
    }
  }

  static getRef(trainerId) {
    return doc(Firebase.db, "trainers", trainerId);
  }

  getRef() {
    return doc(Firebase.db, "trainers", this.id);
  }

  async addRoutineTemplate(routineRef) {
    try {
      return await updateDoc(this.getRef(), {
        routineTemplates: arrayUnion(routineRef),
      });
    } catch (err) {
      console.log(
        "There was a problem adding the routine template to the trainer",
        this,
        routineRef,
        err
      );
    }
  }

  async removeRoutineTemplate(routineRef) {
    try {
      return await updateDoc(this.getRef(), {
        routineTemplates: arrayRemove(routineRef),
      });
    } catch (err) {
      console.log(
        "There was a problem removing the routine template from the trainer",
        this,
        routineRef,
        err
      );
    }
  }

  // finds patient and trainer documents,
  // Then removes the patient reference from the 'patients' array
  // deletes the patient from the 'users' collection
  /**
   * @param  {string} trainerId
   * @param  {string} patientId
   * 
   * @returns The trainer object, with the updated patients array
   */
  static async deletePatient(trainerId, patientId) {
    try {
      const currPatient = doc(Firebase.db, "users", patientId);
      const currTrainer = doc(Firebase.db, "trainers", trainerId);
      await updateDoc(currTrainer, {
        patients: arrayRemove(currPatient),
      });
      await deleteDoc(currPatient);
      return await Trainer.getById(trainerId);
    } catch (err) {
      console.error(
        "There was a problem deleting the patient: ",
        trainerId,
        patientId,
        err
      );
      throw err;
    }
  }
	
	/**
	 */
  static async addPatient(trainerId, patientId) {
    try {
      const currPatient = doc(Firebase.db, "users", patientId);
      const currTrainer = doc(Firebase.db, "trainers", trainerId);
      await updateDoc(currTrainer, {
        patients: arrayUnion(currPatient),
      });
      await updateDoc(currPatient, {
        trainerID: trainerId
      });
      return await Trainer.getById(trainerId);
    } catch (err) {
      console.error(
        "There was a problem adding the patient: ",
        trainerId,
        patientId,
        err
      );
      throw err;
    }
  }
  /**
   * update username of trainer
   */
  static async updateUsername(trainerId, username) {
    try {
      // const currPatient = doc(Firebase.db, "users", patientId);
      const currTrainer = doc(Firebase.db, "trainers", trainerId);
      await updateDoc(currTrainer, {
        username: username,
      });
      return await Trainer.getById(trainerId);
    } catch (err) {
      console.error(
        "There was a problem adding the patient: ",
        trainerId,
        err
      );
      throw err;
    }
  }
}

export default Trainer;
