import SideBar from "../components/layouts/SideBar";
import AppLogout from "../components/AppLogout";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import ReactPlayer from 'react-player'

import { getPatient } from "../models/patient";
import { getFeedbackRequest } from "../models/feedback";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRequestMedia, submitFeedbackResponse } from "../models/feedback";

const FeedbackRequest = () => {

  const { patientId, requestId } = useParams()
	const navigate = useNavigate()
  const [request, setRequest] = useState({})
  const [patient, setPatient] = useState({})
  const [url, setUrl] = useState(null)
	const [note, setNote] = useState("")

  useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      let patient = await getPatient(patientId)
      let request = await getFeedbackRequest(patient, requestId)
      let url = await getRequestMedia(request.url)

      if (!ignore) {
        setPatient(patient)
        setRequest(request)
				setUrl(url)
      }
    }

    fetchData()
    return () => {
      ignore = true  
    }
  }, [])

	async function submit () {
		await submitFeedbackResponse(patient, requestId, { comment: note })
		navigate(`/patient/${patient.uid}`);
	}
  
  return (
    <AppLogout>
      <SideBar>
        <div className="feedback-container">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Patients
            </Link>
            / {" "}
            <Link to={`/patient/${patient.uid}`} className="current-route">
              {patient.firstName} {patient.lastName}
            </Link>
            / {" "}
            <span>
              Feedback
            </span>
          </div>

          <div className="feedback-request-overview">
            <div className="feedback-details">
              <h2>{patient.firstName} {patient.lastName}</h2>
              <h3>Provide Feedback for {request.exercise}</h3>
							<TextBox value={note} onChange={ (e) => setNote(e.target.value) } id="notes" placeholder={"Enter your feedback..."}/>
            </div>

            <div className="feedback-video-container">
							<ReactPlayer controls url={url} />
            </div>

            <Button className="feedback-submit" onClick={submit} >Submit Feedback</Button>
          </div>

        </div>

      </SideBar>
    </AppLogout>
  )
}

export default FeedbackRequest
