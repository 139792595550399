/**
 * Feedback Request Functions.
 *
 * Invariants:
 *
 * - User must have `uid` set.
 * - Firebase class must be initialized.
 *
 * @author Aaron Sutton
 */
import { doc, collection, query, where, getDoc, getDocs, setDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import Firebase from "../services/firebase";

export { getFeedbackRequest, getOpenFeedbackRequests, getRequestMedia, submitFeedbackResponse };

async function getOpenFeedbackRequests(user) {
	if (!user.id) { return [] } // Actual rerender hell.
  let requests = collection(Firebase.db, `users/${user.id}/statistics`);
  let q = query(
    requests,
    where("type", "==", "feedbackRequest"),
    where("open", "==", true),
  );
  const snap = await getDocs(q);
  return snap.docs.map((d) => [d.id, d.data()]);
}

async function getFeedbackRequest(user, requestId) {
  let d = doc(Firebase.db, `users/${user.uid}/statistics/${requestId}`)
  return (await getDoc(d)).data()
}

async function submitFeedbackResponse(user, requestId, response) {
  let d = doc(Firebase.db, `users/${user.uid}/responses/${requestId}`)
  await setDoc(d, response)

  d = doc(Firebase.db, `users/${user.uid}/statistics/${requestId}`)
	let snap = await getDoc(d);
	await setDoc(d, { ...snap.data(), open: false })
}

async function getRequestMedia(path) {
	return await getDownloadURL(ref(Firebase.storage, path))
}
