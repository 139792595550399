import React from "react";

/**
 * Wrapper for SVG components/other items.
 * @param className allows for special formatting
 * @param children child content to be formatted by this wrapper
 * @returns
 */
const TextSVGContainer = ({ children, className = "" }) => {
  return <span className={`text-svg-container ${className}`}>{children}</span>;
};
export default TextSVGContainer;
