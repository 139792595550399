import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  Firestore,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import Trainer from "./trainer";

class Exercise extends BasicFirebaseItem {
  constructor(
    id = "",
    name = "",
    displayName = "",
    primaryJointsWorked = [],
    primaryMusclesWorked = [], // Array of strings
    secondaryMusclesWorked = [], // Array of strings
    instructions = "",
    defaultNotes = "",
    units = [],
    imgURL = "",
    imgPath = "",
    videoURL = "",
    videoPath = "",
    owner = "",
    ownerLabel = ""
  ) {
    super(id);
    this.name = name;
    this.displayName = displayName;
    this.primaryJointsWorked = primaryJointsWorked;
    this.primaryMusclesWorked = primaryMusclesWorked;
    this.secondaryMusclesWorked = secondaryMusclesWorked;
    this.instructions = instructions;
    this.defaultNotes = defaultNotes;
    this.units = units;
    this.imgURL = imgURL;
    this.imgPath = imgPath;
    this.videoURL = videoURL;
    this.videoPath = videoPath;
    this.owner = owner;
    this.ownerLabel = ownerLabel;
  }

  static async getAllEZPTExercises() {
    try {
      const exercisesRef = collection(Firebase.db, "exercises");
      const q = query(
        exercisesRef,
        where("owner", "==", "EZPT"),
        orderBy("displayName", "asc")
      );
      const querySnapshot = await getDocs(q);

      let result = [];
      querySnapshot.docs.forEach((doc) => {
        result.push(Exercise.build(doc, doc.data()));
      });
      return result;
    } catch (err) {
      console.error("There was a problem getting all exercises", err);
      throw err;
    }
  }

  static build(ref, data) {
    try {
      let builtExercise = Object.assign(new Exercise(), data, { id: ref.id });
      builtExercise.ownerLabel =
        builtExercise.owner === "EZPT" ? "EZPT" : "Custom";
      return builtExercise;
    } catch (err) {
      console.error(
        "There was a problem building the exercise",
        ref,
        data,
        err
      );
    }
  }

  prepForSave() {
    let preppedObject = super.prepForSave();
    delete preppedObject.ownerLabel;
    Object.keys(preppedObject).forEach((key) => {
      if (
        !preppedObject[key] ||
        (Array.isArray(preppedObject[key]) && preppedObject[key].length === 0)
      ) {
        delete preppedObject[key];
      }
    });
    return preppedObject;
  }

  static setupListener(trainerId, onModify, onAdd, onDelete) {
    try {
      const trainerRef = Trainer.getRef(trainerId);
      const listenerQuery = query(
        collection(Firebase.db, "exercises"),
        where("owner", "==", trainerRef),
        orderBy("displayName", "asc")
      );
      return super.setupListener(
        listenerQuery,
        Exercise,
        onModify,
        onAdd,
        onDelete
      );
    } catch (err) {
      console.error(
        "There was a problem setting up the Exercise listener",
        trainerId,
        err
      );
    }
  }

  static getRef(exerciseId) {
    return doc(Firebase.db, "exercises", exerciseId);
  }

  static async videoURL(exercise) {
    try {
      const pathReference = ref(
        Firebase.storage,
        `videos/exercise/${exercise.id}.mp4`
      );
      const imgURL = await getDownloadURL(pathReference);
      return imgURL;
    } catch (err) {
			console.error('could not find video at path: ',err)
      return ''
		}
  }

  static async getImage(exerciseName) {
    try {
      const pathReference = ref(
        Firebase.storage,
        `exercisePhotos/${exerciseName}.png`
      );
      const imgURL = await getDownloadURL(pathReference);
      return imgURL;
    } catch (err) {}
  }

  async save() {
    try {
      console.log("trying to save exercise", this);
      const exercisesRef = collection(Firebase.db, "exercises");
      const preparedExerciseData = this.prepForSave();
      if (this.id !== "") {
        await this.update(preparedExerciseData);
      } else await this.saveNew(exercisesRef, preparedExerciseData);
    } catch (err) {
      console.error("There was a problem saving the exercise", err);
    }
  }

  async saveNew(collectionRef, preparedExerciseData) {
    try {
      console.log("Saving new", collectionRef, preparedExerciseData);
      return await addDoc(collectionRef, preparedExerciseData);
    } catch (err) {
      console.error(
        "There was a problem trying to save the following exercise as a new exercise:",
        preparedExerciseData,
        err
      );
      throw err;
    }
  }

  async update(preparedExerciseData) {
    try {
      return await updateDoc(
        doc(Firebase.db, "exercises", this.id),
        preparedExerciseData
      );
    } catch (err) {
      console.error(
        "There was a problem trying to update the following exercise:",
        this,
        preparedExerciseData,
        err
      );
      throw err;
    }
  }

  async deleteImg() {
    try {
      const exerciseRef = Exercise.getRef(this.id);
      const imgRef = ref(Firebase.storage, this.imgPath);
      await deleteObject(imgRef);
      return await updateDoc(exerciseRef, {
        imgURL: deleteField(),
        imgPath: deleteField(),
      });
    } catch (err) {
      console.error("There was a problem deleting the image", this, err);
    }
  }

  async deleteVideo() {
    try {
      const exerciseRef = Exercise.getRef(this.id);
      const videoRef = ref(Firebase.storage, this.videoPath);
      await deleteObject(videoRef);
      return await updateDoc(exerciseRef, {
        videoURL: deleteField(),
        videoPath: deleteField(),
      });
    } catch (err) {
      console.error("There was a problem deleting the video", this, err);
    }
  }

  async delete() {
    try {
      const exerciseRef = Exercise.getRef(this.id);
      return await deleteDoc(exerciseRef);
    } catch (err) {
      console.error("There was a problem deleting the exercise", this, err);
    }
  }
}

export default Exercise;
