import {
  collection,
  addDoc,
  getDocs,
  Timestamp,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import Firebase from "../services/firebase";

class TrainerNotes {
  constructor(note = "", createdAt = new Timestamp()) {
    this.note = "";
    this.createdAt = createdAt;
  }

  static async getTrainerNotes(patientId) {
    try {
      const notes = query(
        collection(Firebase.db, `users/${patientId}/trainerNotes`),
        orderBy("createdAt", "desc"),
        limit(5)
      );
      const data = [];
      const snapshot = await getDocs(notes);
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      return data;
    } catch (error) {
      console.error("failed to retrieve trainer notes: ", error);
    }
  }

  static async createTrainerNote(patientId, data) {
    try {
      const notes = collection(Firebase.db, `users/${patientId}/trainerNotes`);
      console.log("notes: ", notes);

      try {
        const note = new TrainerNotes();
        note.note = data;
        note.createdAt = Timestamp.now();
        const newNote = await addDoc(notes, { ...note });
        return newNote;
      } catch (error) {
        console.error(
          "there was an error creating the new trainer note: ",
          error
        );
      }
    } catch (error) {
      console.error("failed to get trainer notes: ", error);
    }
  }
}

export default TrainerNotes;
