// import React, { useEffect, useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import Exercise from "../models/exercise";
import { useNavigate } from "react-router-dom";
import Routine from "../models/routine";
// import Button from "./Button";

/**
 * RoutineCard Component
 * @param routine Routine for which to display information
 * @param className Additional classes to be applied
 * @returns Routine card that shows an image, the routine name, and a list of exercises
 */
function MiniRoutineCard({ routine = new Routine(), className = "", aiChat = false }) {

  const navigate = useNavigate()
  // const [routineImgURL, setRoutineImgURL] = useState("");

  // Iterates through the exercises and uses the first image it finds as it's display photo
  /////////////////////////////////////////////////
  // **** Not using exercise images for now **** //
  /////////////////////////////////////////////////

  // useEffect(() => {
  //   const loadImage = async () => {
  //     let imgURL = "";
  //     for (let i = 0; i < routine.exercises.length; i++) {
  //       let newImgUrl = await Exercise.getImage(
  //         routine.exercises[i].exercise.name
  //       );
  //       if (newImgUrl) {
  //         imgURL = newImgUrl;
  //         break;
  //       }
  //     }
  //     setRoutineImgURL(imgURL);
  //   };
  //   loadImage();
  // }, [routine]);

  const handleToRoutineEditor = (routineData) => {
    if (aiChat) {
      navigate(`routine/${routineData.id}/edit`, { state: { isAiRoutine: true } });
    }
  }

  return (
    <div className={`mini-routine-card ${className}`} onClick={() => handleToRoutineEditor(routine)} style={aiChat ? { cursor: 'pointer' } : {}}>
      {/* <div className="routine-photo">
        {routineImgURL ? (
          <LazyLoadImage
            wrapperClassName="actual-photo"
            alt={`Routine ${routine.name}`}
            src={routineImgURL}
            effect="opacity"
          />
        ) : (
          <></>
        )}
      </div> */}
      <div className="routine-data">
        <h5>{routine.name}</h5>
      </div>
    </div>
  );
}

export default MiniRoutineCard;
