import { addDoc, collection, where, query, getDocs } from "firebase/firestore";
import { toDisplayName } from "../utils/text";
import Firebase from "../services/firebase";
import AssignedRoutine from "../models/assignedRoutine";

const flagsToExercise = {
  "Arms Fall Forward": "squat-to-row",
  "Excessive Forward Lean": [
    "ball-wall-squats",
    "contralateral-bird-dog",
  ],
  "Foot Turns Out": "single-leg-reach-forward-and-side",
  "Knees Go Inwards": "tube-walking-side-to-side",
  "Knees Go Outwards": "ball-wall-squats",
};

export async function recommend(patient, assessment) {
  let exercises = new Set(
    Object.entries(assessment.results)
      .flatMap(([type, data]) => {
        if (type == "boolean") {
          return Object.entries(data).map(([label, value]) => {
            if (value) {
              return label;
            }
          });
        }
      })
      .flatMap((f) => flagsToExercise[f])
      .filter((s) => !!s)
  );

  exercises = await Promise.all(
    Array.from(exercises).map(async (name) => {
      let c = collection(Firebase.db, `/exercises`);
      let q = query(c, where("name", "==", name));

      const snap = await getDocs(q);

      return snap.docs.map((exercise) => {
        return {
          exercise: exercise.ref,
          work: [30],
          rest: [30],
          unit: "time",
          notes: "",
        };
      });
    })
  );

  const routine = {
    name: "Assessment: " + toDisplayName(assessment.exercise),
    isTemplate: false,
    exercises: exercises.flat(),
  };

  const doc = await addDoc(
    collection(Firebase.db, `/routines/trainerRoutines/${patient.trainerID}`),
    routine
  );

  return doc;
}
