import React, { useState, useEffect } from "react";
import ProfilePhoto from "./ProfilePhoto";
import Message from "../models/message";
import { formatDateTime, capitalizeFirstLetter } from "../services/helpers";
import { limit, onSnapshot, orderBy, query } from "firebase/firestore";

const ChatPatient = ({
  setPatientSort,
  trainerId,
  patient,
  className,
  unread,
  addMessage,
  ...props
}) => {
  const [recentMessage, setRecentMessage] = useState("");
  const [recentTimestamp, setRecentTimestamp] = useState("");
  const [info, setInfo] = useState({});

  // Sends a query for most recent message. Sets the text and timestamp to the response.
  // Creates the object "info" that holds an id and timestamp to be used for sorting
  useEffect(() => {
    const messageCol = Message.getCollection(trainerId, patient.id);
    const messages = query(
      messageCol,
      orderBy("sentTimestamp", "desc"),
      limit(1)
    );
    const data = [];
    const unsubscribe = onSnapshot(messages, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push(Message.build(doc, doc.data()));
      });
      addMessage(data[data.length - 1]);
      data.length > 0
        ? setRecentMessage(data[data.length - 1].data)
        : setRecentMessage("");
      data.length > 0
        ? setRecentTimestamp(data[data.length - 1].sentTimestamp)
        : setRecentTimestamp(null);
      data.length > 0
        ? setInfo({
            id: patient.id,
            timestamp: data[data.length - 1].sentTimestamp,
          })
        : setInfo({ id: patient.id });
    });

    return () => unsubscribe();
  }, [patient.id, trainerId, setInfo]);

  // adds "info" object to "patientSort" array, which will then be passed up to Chat.jsx for sorting the sidebar.
  useEffect(() => {
    if (Object.keys(info).length !== 0) {
      setPatientSort((prevPatients) => {
        return [...prevPatients, info];
      });
    }
    // console.log("done");
  }, [info, setPatientSort]);

  const trimText = (text) => {
    if (text.length > 20) {
      return `${text.slice(0, 20)}...`;
    } else return text;
  };

  return (
    <li {...props} className={`${className}`}>
      <div>
        <ProfilePhoto
          imgURL={patient.profilePhotoURL}
          className="chat-patient__picture"
        />
      </div>
      <div className="chat-patient__info">
        <div className="chat-patient__header">
          {patient.firstName && patient.lastName ? (
            <p className="chat-patient__name">
              {capitalizeFirstLetter(patient.firstName)}{" "}
              {capitalizeFirstLetter(patient.lastName)}
            </p>
          ) : (
            <p className="chat-patient__name">{trimText(patient.email)}</p>
          )}
          <span>{recentTimestamp && formatDateTime(recentTimestamp)}</span>
        </div>
        <div className="recent-msg__container">
          {recentMessage?.url && (
            <span className="most-recent__msg">
              <i>Sent a file</i>
            </span>
          )}
          {recentMessage?.type === "document" && (
            <span className="most-recent__msg">
              <i>Sent a routine</i>
            </span>
          )}
          <span className="most-recent__msg">
            {typeof recentMessage === "string" ? trimText(recentMessage) : ""}
          </span>
          {unread && <div className="ping"></div>}
        </div>
      </div>
    </li>
  );
};
export default ChatPatient;
