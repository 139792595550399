import React from "react";
import TextSVGContainer from "../svg/TextSVGContainer";
import Button from "../Button";

/**
 * @param setQuestionnaireModalOpen
 * @param setAssessmentModalOpen
 * @returns Two Buttons, one for opening the QuestionnaireModal, the other AssessmentModal
 */
const TypeformButtons = ({
  setQuestionnaireModalOpen = false,
  setAssessmentModalOpen = false,
}) => {
  return (
    <div className="questionnaire-buttons">
      <Button
        id="questionnaire-button"
        className="questionnaire-button"
        type="button"
        onClick={async () => {
          setQuestionnaireModalOpen(true);
        }}
        children={
          <>
            <TextSVGContainer>
              <span className="material-icons-outlined">fact_check</span>
            </TextSVGContainer>
            {` Questionnaire`}
          </>
        }
        successCallback={() => {}}
        errorCallback={() => {}}
        format="minimal"
      />
      <Button
        id="assessment-button"
        className="assessment-button"
        type="button"
        onClick={async () => {
          setAssessmentModalOpen(true);
        }}
        children={
          <>
            <TextSVGContainer>
              <span className="material-icons-outlined">assignment_ind</span>
            </TextSVGContainer>
            {` Assessment`}
          </>
        }
        successCallback={() => {}}
        errorCallback={() => {}}
        format="minimal"
      />
    </div>
  );
};
export default TypeformButtons;
