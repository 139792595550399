import SideBar from "../components/layouts/SideBar";
import AppLogout from "../components/AppLogout";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import ReactPlayer from "react-player";
import AssessmentDocument from "../components/AssessmentDocument";
import { recommend } from "../utils/naiveRoutineRecommender";
import { getPatient } from "../models/patient";
import {
  reassess,
  getAssessment,
  getMotionAssessmentTask,
} from "../models/assessments";

import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRequestMedia, submitFeedbackResponse } from "../models/feedback";

const Assessment = () => {
  const { patientId, requestId } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState({});
  const [patient, setPatient] = useState({});
  const [text, setText] = useState("Assign routine");
  const [actionTaken, setActionTaken] = useState(false);
  const [assessmentDueDate, setAssessmentDueDate] = useState();

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      let patient = await getPatient(patientId);
      let request = await getAssessment(patient, requestId);
      let task = await getMotionAssessmentTask(patient);

      let rtf = new Intl.RelativeTimeFormat("en", {
        numeric: "auto",
        style: "short",
      });

      let due = task && rtf.format(
        Math.trunc((task.due.intervals._1.toDate() - new Date()) / 8.64e7),
        "day"
      );

      if (!ignore) {
        setPatient(patient);
        setRequest(request);
				setAssessmentDueDate(due)
      }
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  async function handler(event) {
    setText("Generating routine...");
    let { id } = await recommend(patient, request);
    navigate(`/patient/${patient.uid}/routine/${id}/edit`);
  }

  return (
    <AppLogout>
      <SideBar>
        <div className="feedback-container">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Patients
            </Link>
            /{" "}
            <Link to={`/patient/${patient.uid}`} className="current-route">
              {patient.firstName} {patient.lastName}
            </Link>
            / <span>Assessment</span>
          </div>

          <div className="feedback-request-overview">
            <div className="feedback-details">
              <AssessmentDocument patient={patient} assessment={request} />
            </div>
          </div>

          <h1>Recommendations</h1>

          <div className="assessment-recommendations">
            <div className="item">
              <div>
                <h2>{text}</h2>
                <p>Based on assessment results</p>
              </div>

              <Button onClick={handler}>
                <span className="material-icons-outlined">play_arrow</span>
              </Button>
            </div>
          </div>
        </div>
      </SideBar>
    </AppLogout>
  );
};

export default Assessment;
