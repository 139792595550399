import { React } from "react";

/**
 * A reusable Modal component
 * @param className classes that get passed to the modal
 * @param show a boolean for whether or not the modal is open
 * @param onClose a function to call when the element closes
 * @param children Content to be shown inside modal
 * @param useCloseButton Whether or not there should be a close button and if modal can be closed by clicking outside of it
 * @returns
 */
function Modal({
  className = "",
  show,
  onClose = () => {},
  children,
  useCloseButton = false,
}) {
  return (
    <div
      className="modal"
      style={{ display: show ? "flex" : "none" }}
      onClick={useCloseButton ? onClose : () => {}}
    >
      {useCloseButton ? (
        <div className="modal-close-button">
          <span className="material-icons-outlined">close</span>
        </div>
      ) : (
        <></>
      )}
      {show ? (
        <div
          className={`modal-content ${className}`}
          onClick={(e) => {
            e.stopPropagation(); /* Stops modal from closing */
          }}
        >
          {children}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Modal;
