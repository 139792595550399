import {
  BrowserRouter,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import PatientDetails from "./pages/PatientDetails";
import Dashboard from "./pages/TrainerDashboard";
import EditRoutine from "./pages/EditRoutine";
import Chat from "./pages/Chat";
import SettingsPage from "./pages/Settings";
import Schedule from "./pages/Schedule";
import FeedbackRequest from "./pages/FeedbackRequest";
import Assessment from "./pages/Assessment";
import Assessments from "./pages/Assessments";
import SurveyResponse from "./pages/SurveyResponse"
import Exercises from "./pages/Exercises"

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<App />}>
          <Route path="settings" element={<SettingsPage />} />
          <Route
            path="dashboard"
            element={ <Dashboard /> }
          />
          <Route
            path="patient/:patientId/routine/:routineId/edit"
            element={<EditRoutine />}
          />
          <Route
            path="patient/:patientId/routine/create"
            element={<EditRoutine />}
          />
          <Route
            path="patient/:patientId/feedback/:requestId"
            element={<FeedbackRequest />}
          />
          <Route
            path="patient/:patientId/assessments"
            element={<Assessments />}
          />
          <Route
            path="patient/:patientId/assessments/:requestId"
            element={<Assessment />}
          />
          <Route
            path="patient/:patientId/responses/:requestId"
            element={<SurveyResponse />}
          />
          <Route
            path="patient/:patientId/routine/:templateId/create"
            element={<EditRoutine />}
          />
          <Route path="patient/:patientId" element={<PatientDetails />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route
            path="/exercises"
            element={<Exercises />}
          />
          <Route index element={<Login />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Switch>
    </BrowserRouter>
  );
}
