import React, { useState, useEffect, useCallback } from "react";
import Modal from "./Modal";
import Button from "./Button";
import TextInput from "./TextInput";
import AssignedRoutine from "../models/assignedRoutine";
import { Timestamp } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import Firebase from "../services/firebase";

const ScheduleRoutine = ({
  show,
  onClose = () => {},
  useCloseButton = true,
  routine = {},
  setCurrentRoutine,
  patient,
  trainerId,
  onSetRoutineSchedule,
  // onCreateRoutine = () => {},
  onSetNoScheduleError = () => {},
}) => {
  const options = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [routineInterval, setRoutineInterval] = useState("none");
  const [weeklyInterval, setWeeklyInterval] = useState([]);
  const [scheduleInfo, setScheduleInfo] = useState(new AssignedRoutine());
  const [due, setDue] = useState({});
  const [disabled, setDisabled] = useState(true);

  const load = useCallback(async () => {
    if (routine.id !== "" && patient.id !== "") {
      try {
        const assignedRoutine = await AssignedRoutine.getAssignedRoutine(
          patient.id,
          routine.id
        );
        setScheduleInfo(assignedRoutine);
      } catch (error) {
        console.error("cannot get doc: ", error);
      }
    }
  }, [patient.id, routine.id]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    onSetRoutineSchedule(scheduleInfo);
  }, [onSetRoutineSchedule, scheduleInfo]);

  // console.log(scheduleInfo);

  const saveSchedule = useCallback(async () => {
    try {
      const scheduledRoutine = new AssignedRoutine();
      scheduledRoutine.createdAt = Timestamp.now();
      weeklyInterval.length > 0
        ? (scheduledRoutine.due = {
            days: {
              _0: weeklyInterval,
              _1: Timestamp.now(),
            },
          })
        : (scheduledRoutine.due = due);
      setScheduleInfo(scheduledRoutine);
      onSetNoScheduleError(false);
      // console.log("starting");
      if (routine.id) {
        scheduledRoutine.routineReference = doc(
          Firebase.db,
          `routines/trainerRoutines/${trainerId}/${routine.id}`
        );
        const newSchedule = await AssignedRoutine.saveAssignedRoutine(
          routine,
          patient,
          Object.assign({}, scheduledRoutine),
          trainerId
        );
        // console.log("new schedule: ", newSchedule);
        // onCreateRoutine(newSchedule);
      } else {
        onClose();
      }
    } catch (error) {
      console.error("could not update scheduled routine: ", routine, error);
    }

    onClose();
    console.log("test");
  }, [
    due,
    onClose,
    // onCreateRoutine,
    onSetNoScheduleError,
    patient,
    routine,
    trainerId,
    weeklyInterval,
  ]);

  useEffect(() => {
    if (weeklyInterval.length === 0 && due === {}) {
      setDisabled(true);
      // console.log(disabled);
    }
  }, [disabled, weeklyInterval, due]);

  const closeModal = () => {
    setDue({});
    setWeeklyInterval([]);
    onClose();
  };
  return (
    <Modal
      className="schedule-routine__container"
      show={show}
      onClose={closeModal}
      useCloseButton={useCloseButton}
    >
      <h3>Set Frequency</h3>
      <div
        className={`frequency-container ${
          routineInterval === "none" && "blue"
        } ${routineInterval === "daily" && "yellow"} ${
          routineInterval === "weekly" && "green"
        }`}
      >
        <span
          className={`frequency-item ${routineInterval === "none" && "active"}`}
          onClick={() => {
            setRoutineInterval("none");
            setDisabled(true);
            setWeeklyInterval([]);
          }}
        >
          One-time
        </span>
        <span
          className={`frequency-item ${
            routineInterval === "daily" && "active"
          }`}
          onClick={() => {
            setRoutineInterval("daily");
            setDisabled(true);
            setWeeklyInterval([]);
          }}
        >
          Daily
        </span>
        <span
          className={`frequency-item ${
            routineInterval === "weekly" && "active"
          }`}
          onClick={() => {
            setRoutineInterval("weekly");
            setDisabled(true);
          }}
        >
          Weekly
        </span>
      </div>
      {/* <RadioList type="dropdown" options={["daily", "weekly", "none"]} /> */}

      {/* PICK DATE FROM CALENDAR */}
      {routineInterval === "none" && (
        <section>
          <TextInput
            label="Due Date"
            placeholder="Date"
            className="routine-input"
            type="date"
            min={new Date()
              .toISOString()
              .replace(/T.*/, "")
              .split("-")
              .join("-")}
            onChange={(e) => {
              setDisabled(false);
              // console.log(e.target.value);
              setDue({
                date: { _0: Timestamp.fromDate(new Date(e.target.value)) },
              });
            }}
          />
        </section>
      )}

      {/* EVERY X DAYS */}
      {routineInterval === "daily" && (
        <section className="daily-container">
          <span>Every</span>
          <TextInput
            type="number"
            className="daily-picker"
            min="1"
            max="7"
            value={due.intervals ? due.intervals._0 : ""}
            onChange={(e) => {
              setDisabled(false);
              if (Number(e.target.value) > 7) {
                e.target.value = e.target.value.replace(e.target.value, "");
              } else if (Number(e.target.value) < 1) {
                e.target.value = "1";
              }
              setDue({
                intervals: {
                  _0: Number(e.target.value),
                  _1: Timestamp.now(),
                },
              });
            }}
          />
          <span>days</span>
        </section>
      )}

      {/* PICK DAYS OF THE WEEK */}
      {routineInterval === "weekly" && (
        <section className="daily-container">
          <TextInput
            type="number"
            className="daily-picker"
            min="1"
            max="7"
            value={due.intervals ? due.intervals._0 : ""}
            onChange={(e) => {
              setDisabled(false);
              if (Number(e.target.value) > 7) {
                e.target.value = e.target.value.replace(e.target.value, "");
              } else if (Number(e.target.value) < 1) {
                e.target.value = "1";
              }
              setDue({
                intervals: {
                  _0: Number(e.target.value),
                  _1: Timestamp.now(),
                },
              });
            }}
          />
          <span>times per week</span>
        </section>
        // <section className="pick-day__container">
        //   {options.map((option, key) => (
        //     <span
        //       key={key}
        //       className={`pick-day__item ${
        //         weeklyInterval.includes(key + 1) && "picked"
        //       }`}
        //       onClick={() => {
        //         setDisabled(false);
        //         if (!weeklyInterval.includes(key + 1)) {
        //           setWeeklyInterval(weeklyInterval.concat(key + 1));
        //         } else {
        //           setWeeklyInterval((oldSelections) => {
        //             const index = oldSelections.indexOf(key + 1);
        //             const newSelections = oldSelections.splice(index, 1);
        //             return [...oldSelections, ...newSelections];
        //           });
        //         }
        //       }}
        //     >
        //       {option.substring(0, 1)}
        //     </span>
        //   ))}
        // </section>
      )}
      <div className="schedule-routine__btns">
        <Button type="button" onClick={closeModal} className="cancel-button">
          Cancel
        </Button>
        <Button
          type="button"
          onClick={saveSchedule}
          className={
            !disabled &&
            `${routineInterval === "none" && "blue-btn"} ${
              routineInterval === "daily" && "yellow-btn"
            } ${routineInterval === "weekly" && "green-btn"}`
          }
          disabled={disabled}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
export default ScheduleRoutine;
