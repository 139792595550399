import React, { useCallback, useEffect, useState } from "react";
import Message from "../models/message";
import AiMessageComponent from "./AiMessageComponent";

function AiMessageList({
    trainerId = "",
    patientId = "",
}) {
    const [aiMessages, setAiMessages] = useState([]);
    const [loadedAllMessages, setLoadedAllMessages] = useState(true);

    useEffect(() => {
        Message.getAiMessages(patientId)
            .then((data) => {
                const aiMessageData = [];
                data.forEach((doc) => {
                    aiMessageData.push(doc.data())
                });

                setAiMessages([...aiMessageData])
            })
        // .catch((err) => console.log("AI message err-->", err))
    }, [])

    console.log("aiMessages==>", aiMessages)

    const displayMessages = useCallback(
        (messageList) => {
            return messageList.map((message) =>
                displaySingleMessage(message, trainerId)
            );
        },
        [trainerId]
    );

    const displaySingleMessage = (message, trainerId) => {
        return message ? (
            <AiMessageComponent
                key={message?.id}
                message={message}
                trainerId={trainerId}
            />
        ) : (
            <></>
        );
    };

    return (
        <div className="actual-message-list-container">
            {displayMessages(aiMessages)}
            {loadedAllMessages ? (
                <div className="loaded-all-messages">
                    It looks like you've loaded
                    <br />
                    all of your messages to this person.
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default AiMessageList;
