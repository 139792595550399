import { useState, useEffect } from "react";
import { getSurveyResponses } from "../models/surveyResponses";
import SurveyResponseItem from "./SurveyResponseItem";

const SurveyResponses = (props) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requests = await getSurveyResponses(props.patient);
      setRequests(requests);
    };

    fetchData();
  }, [props.patient]);

  const items = requests.map(([id, req]) => (
    <SurveyResponseItem key={id} patient={props.patient} response={req} id={id} />
  ));

  return (
    <div className={`patient-feedback`}>
      <h3>Recent Survey Responses</h3>
		{ items }
    </div>
  );
};

export default SurveyResponses;
