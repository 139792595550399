import React, { useEffect, useState, useContext, useCallback } from "react";
import AppLogout from "../components/AppLogout";
import SideBar from "../components/layouts/SideBar";
import { StateContext } from "../contexts/State";
import Button from "../components/Button";
import SchedulePatients from "../components/scheduling/SchedulePatients";
import ScheduleAppointments from "../components/scheduling/ScheduleAppointments";
import ScheduleCalendar from "../components/scheduling/ScheduleCalendar";
import SelectFilter from "../components/SelectFilter";
import Appointment from "../models/appointment";
import ScheduleUsername from "../components/scheduling/ScheduleUsername"
const Schedule = () => {
  const { state } = useContext(StateContext);
  const [patients, setPatients] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSchedule, setShowSchedule] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const [showAppointments, setShowAppointments] = useState(false);

  const [filteredAppointments, setFilteredAppointments] = useState([]);

  async function getAppointmentsURL(url, body, isFirstTry = true) {
    let newURL = null;
    if (window.location.hostname === "localhost")
      newURL = "http://localhost:9999" + url;
    return fetch(newURL ? newURL : url, {
      method: "GET",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200 && isFirstTry) {
          console.log("trying again", response);
          return this.getAppointmentsURL(url, body, false);
        } else return response;
      })
      .catch((e) => {
        console.error("There was an error retrieving appointment data", e);
      });
  }

  // const callAppointments = useCallback(async () => {
  //   // const res = await getAppointmentsURL(`/.netlify/functions/getAppointments`);
  //   // const data = await res.json();

  //   const trainerId = state.trainer.id;
  //   const data = await Appointment.getAppointments(trainerId)
  //   setAppointments(data);
  //   setFilteredAppointments(data);

  // }, []);

  // useEffect(() => {
  //   callAppointments();
  // }, [callAppointments]);

  useEffect(async () => {
    if (state.trainer.id) {
      const trainerId = state.trainer.id;
      const data = await Appointment.getAppointments(trainerId)
      setAppointments(data);
      setFilteredAppointments(data);
    }
  }, [state.trainer.id, showSchedule]);

  const filterAppointments = (option) => {
    if (appointments.length > 0) {
      const test = appointments.filter((appointment) => {
        if (option === "All Meetings") {
          return true;
        } else {
          // return appointment.type === option ? true : false;
          return appointment.title === option ? true : false;
        }
      });
      setFilteredAppointments(test);
    }
  };

  useEffect(() => {
    setPatients(state.trainer.patients);
  }, [state.trainer.patients]);
  const createAccount = async () => {
    await setShowSchedule(!showSchedule)
    window.open('https://cal.com/signup', '_blank');
  }
  return (
    <AppLogout>
      <SideBar>
        <main className="schedule__main">
          <h2>
            {showSchedule ? "Upcoming Appointments" : "Schedule Appointments"}
          </h2>
          {showSchedule ? (
            <div>
              <section className="scheduling-options">
                {!state?.trainer?.username?.length ? <Button
                  className="view-schedule-btn view-calendar"
                  format="minimal fancy"
                  onClick={() => createAccount()}
                >
                  Create Account
              </Button> :
                  <Button
                    className="view-schedule-btn view-calendar"
                    format="minimal fancy"
                    onClick={() => setShowSchedule(!showSchedule)}
                  >
                    New Appointment
            </Button>
                }
                <SelectFilter
                  onChange={(e) => {
                    filterAppointments(e.target.value);
                  }}
                  className="schedule-filter"
                  options={[
                    "All Meetings",
                    "Intro Meeting",
                    "Membership Consultation",
                    "Assessment",
                  ]}
                />
              </section>
              <ScheduleCalendar appointments={filteredAppointments} />
            </div>
          ) : (!showCalendar && state?.trainer?.username?.length) ? (
            <SchedulePatients
              setShowSchedule={setShowSchedule}
              patients={patients}
              setCurrentPatient={setCurrentPatient}
              setPatients={setPatients}
              setShowCalendar={setShowCalendar}
            />
          ) : state?.trainer?.username?.length ? (
            <ScheduleAppointments
              currentPatient={currentPatient}
              setCurrentPatient={setCurrentPatient}
              setShowCalendar={setShowCalendar}
              showCalendar={showCalendar}
            />
          ) :
                <ScheduleUsername
                  currentPatient={currentPatient}
                  setCurrentPatient={setCurrentPatient}
                  setShowCalendar={setShowCalendar}
                  showCalendar={showCalendar}
                  setShowAppointments={setShowAppointments}
                  setShowSchedule={setShowSchedule}
                />
          }
        </main>
      </SideBar>
    </AppLogout>
  );
};
export default Schedule;
