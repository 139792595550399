import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Link, useLocation, useSearchParams } from "react-router-dom";
import TextInput from "../components/TextInput";
import AppLogout from "../components/AppLogout";
import PasswordReset from "../components/PasswordReset";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import Firebase from "../services/firebase";
import { StateContext } from "../contexts/State";
import TextSVGContainer from "../components/svg/TextSVGContainer";
import LogoSVG from "../components/svg/LogoSVG";
import EmailSVG from "../components/svg/EmailSVG";
import LockSVG from "../components/svg/LockSVG";
import Trainer from "../models/trainer";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = () => {
  const query = useQuery();
  const [searchParams, setSearchParams] = useSearchParams();

   const onChange=(event)=>{
     setSearchParams({[name]: value})
   }

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [validationError, setValidationError] = useState(false);
  let [emailError, setEmailError] = useState(false);
  let [emailErrorMsg, setEmailErrorMsg] = useState(
    "Please enter a valid email address."
  );
  let [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  let [loginError, setLoginError] = useState(false);
  let [loginErrorMsg, setLoginErrorMsg] = useState(
    "Email or password is incorrect."
  );
  const { state, dispatch } = useContext(StateContext);
  let userNameLabel = "Email";
  let passwordLabel = "Password";

	console.log(query.get("forgot"))

  const navigate = useNavigate();
  if (query.get("forgot") !== "") {
    return (
      <AppLogout>
        <main className="login-main">
          <form
            className="login-content"
            onSubmit={async (e) => {
              e.preventDefault();
              return login(email, password).catch((e) => {
                setLoginError(true);
                setLoginErrorMsg("Email or password is incorrect.");
                throw Error(e);
              });
            }}
          >
            <TextInput
              id={"email-input-field"}
              label={userNameLabel}
              placeholder={userNameLabel}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
                setValidationError(false);
                setEmailErrorMsg("Please enter a valid email address.");
              }}
              errorMessage={emailErrorMsg}
              type="email"
              name="email"
              hasError={emailError || validationError}
              setHasError={setEmailError}
              icon={
                <TextSVGContainer>
                  <EmailSVG />
                </TextSVGContainer>
              }
            />
            <TextInput
              id="password-input-field"
              label={passwordLabel}
              type="password"
              placeholder={passwordLabel}
              onChange={(e) => {
                setPassword(e.target.value);
                setValidationError(false);
              }}
              hasError={validationError}
              errorMessage={passwordErrorMsg}
              icon={
                <TextSVGContainer>
                  <LockSVG />
                </TextSVGContainer>
              }
            />
            {loginError ? (
              <div
                className="input-error-message"
                style={{
                  color: "red",
                  fontSize: "0.875rem",
                  margin: "0.5em",
                  testAlign: "left",
                  paddingTop: "20px",
                }}
              >
                {loginErrorMsg}
              </div>
            ) : (
              ""
            )}
            <Button
              id="login-button"
              className="login-button"
              type="submit"
              children={"Sign In"}
              errorCallback={loginFailed}
            />
            <Button
              id="forgot-password-button"
              className="forgot-password-button"
              type="button"
							onClick={() => setSearchParams({"forgot": ""})}
              children={"Forgot Password?"}
              format="transparent"
            />
          </form>
        </main>
      </AppLogout>
    );
  } else {
    return (
      <main className="login-main">
        <PasswordReset />
      </main>
    );
  }

  /**
   * login Function
   * Attempts to login and get trainer data
   * If login succeeds but the trainer doesn't exist
   * it automatically logs them out and gives them an error
   * @param email Email to login
   * @param password Password to login
   * @returns
   */
  async function login(email, password) {
    return Firebase.loginUser(email, password)
      .then((credential) => {
        dispatch(Object.assign(state.firebaseUser, credential.user), {
          type: "STORE_FIREBASE_DATA",
        });
        Trainer.getById(credential.user.uid)
          .then((trainer) => {
            dispatch(Object.assign(state.trainer, trainer), {
              type: "STORE_TRAINER_DATA",
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            Firebase.logoutUser();
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  }

  async function resetPassword(email) {
    return Firebase.resetPassword(email)
      .then((response) => {
        alert("Check your email to reset your password.");
      })
      .catch((err) => {
        alert(
          "There was a problem sending the password reset email.  Double check your email and try again"
        );
      });
  }

  // Redirects to dashboard if it successfully logged in.
  function successfulRedirect(data) {
    navigate("/dashboard");
  }

  function loginFailed(response) {
    setPasswordErrorMsg(
      "There was a problem with logging in.  Please ensure your email or password was correct and that you have an account."
    );
    setEmailErrorMsg("");
    setValidationError(true);
  }
};
export default Login;
