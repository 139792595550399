import React from "react";

const TextBox = ({
  value = "",
  onChange = () => {},
  className = "",
  id = "",
  placeholder = "",
  label = "",
  onKeyDown = () => {},
}) => {
  return (
    <div className={`${className} textbox-container`}>
      <label className="notes-label" htmlFor={id}>
        {label}
      </label>
      <div className="notes-input">
        <textarea
          onKeyDown={onKeyDown}
          id={id}
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
        />
      </div>
    </div>
  );
};
export default TextBox;
