import React, { useState, useEffect, useContext } from "react";
import Papa from "papaparse";
import Button from "../components/Button";

import { StateContext } from "../contexts/State";

function latest(r, title, email) {
  if (r) {
    let res = r.find((l) => {
      return l.title === title;
    });

    if (res) {
      let ret = res.data
        .filter((l) => {
          return (
            l[
              "What is your email? Please use this same email to sign up for the EZPT app."
						] === email || l["email"] === email
          );
        })
        .map((l) => {
          return { ...l, timestamp: new Date(l["Submitted At"]) };
        })
        .reduce((acc, x) => {
          if (!acc) {
            // Most recent reduce.
            return x.timestamp;
          } else {
            if (x.timestamp > acc) {
              return x.timestamp;
            } else {
              return acc;
            }
          }
        }, undefined);
      if (!ret) {
        return undefined;
      }
      return res.data.find((q) => {
        return new Date(q["Submitted At"]).getTime() === ret.getTime();
      });
    }
  }
  return null;
}

const TypeFormShim = ({
  canEdit = false,
  formUrl = "",
  currentPatient = {},
}) => {
  const [loading, setLoading] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [responses, setResponses] = useState([]);

  const { state } = useContext(StateContext);

  // Responses URL
  useEffect(() => {
    async function fetchData() {
      const responseUrl = function (e) {
        return `https://docs.google.com/spreadsheets/d/${
          state.trainer.spreadsheets.intake.id
        }/gviz/tq?tqx=out:csv&sheet=${encodeURIComponent(e.sheet)}`;
      };

      const toJson = function (e) {
        return new Promise((resolve, reject) => {
          Papa.parse(responseUrl(e), {
            download: true,
            header: true,
            complete(results, file) {
              resolve(results.data);
            },
            error(err, file) {
              reject(err);
            },
          });
        });
      };

      let responses = await Promise.all(
        state.trainer.forms.intake.map(async (e) => {
          return { data: await toJson(e) };
        })
      );

      setResponses(responses);

      setLoading(false);
    }
    fetchData();
  }, [state.trainer.forms.intake, state.trainer.spreadsheets.intake.id]);

  const generateUrl = function (e) {
    return `https://305lp0pg9du.typeform.com/to/cjn2VBN2#email=${currentPatient.email}`;
  };

  return (
    <div className="sheets-container">
      <div className="sheets-modal-header">
        {responses.map((e) => (
          <div key={e.title} className="modeal-item-container">
            <div className="modal-item-header">
              <h3>{e.title}</h3>
              {currentPatient.email ? (
                <Button
                  type="button"
                  format="transparent"
                  onClick={() => {
                    window.open(generateUrl(e), "_blank");
                  }}
                >
                  Add / Replace Response
                </Button>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modeal-item-responses">
              {loading ? (
                <h3>Loading Responses...</h3>
              ) : latest(responses, e.title, currentPatient.email) ? (
                <div>
                  {Object.entries(
                    latest(responses, e.title, currentPatient.email)
                  )
                    .filter((x) => {
                      return (
                        x[0] !== "Token" &&
                        x[0] !== "Submitted At"
											&& x[0] !== "Please enter your full name. I acknowledge that typing my name below constitutes my signature on the document linked here."
                      );
                    })
                    .map((item) =>
                      item[1] ? (
                        <div key={item[0]} className="answer">
                          <div className="question-header">
                            <h4>{item[0]}</h4>
                          </div>
                          <div className="typeform-response">
                            <p>{item[1]}</p>
                          </div>
                        </div>
                      ) : null
                    )}
                  <hr />
                </div>
              ) : (
                <p>There isn't a response stored for this assessment yet.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default TypeFormShim;
