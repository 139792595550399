import { logRoles } from "@testing-library/react";
import React, { useCallback, useEffect, useState } from "react";
import ListenerManager from "../models/listenerManager";
import Message from "../models/message";
import MessageComponent from "./MessageComponent";

function MessageList({
  trainerId = "",
  patientId = "",
  chatPage = false,
  recentMessages = [],
}) {
  const [messages, setMessages] = useState([]);
  const [loadedAllMessages, setLoadedAllMessages] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [listenerManager] = useState(new ListenerManager());

  const addedMessage = useCallback(
    (message) => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      setMessages((prevmessages) => {
        let returnval = [message, ...prevmessages];
        return returnval;
      });
    },
    [setMessages]
  );
  const modifiedMessage = useCallback(
    (message) => {
      setMessages((prevMessage) =>
        prevMessage.map((value) => {
          return message.id === value.id ? message : value;
        })
      );
    },
    [setMessages]
  );
  const deletedMessage = useCallback(
    (message) => {
      setMessages((prevMessages) =>
        prevMessages.filter((value) => {
          return message.id !== value.id;
        })
      );
    },
    [setMessages]
  );
  const loadedAll = useCallback(
    (startingIndex, newValue) => {
      if ((messages.length - startingIndex) / Message.MESSAGES_PER_PAGE < 1) {
        setLoadedAllMessages(newValue);
      }
    },
    [setLoadedAllMessages]
  );
  // const initList = useCallback((newMessages) => {
  //   setMessages(prevMessages => [...prevMessages, ...newMessages]);
  // })

  const generateListener = useCallback(
    async (index = 0) => {
      setIsLoading(true);
      const listener = Message.setupListener(
        trainerId,
        patientId,
        modifiedMessage,
        addedMessage,
        deletedMessage,
        loadedAll,
        await index
      );
      listenerManager.addListener(listener);
      return listener;
    },
    [
      addedMessage,
      deletedMessage,
      listenerManager,
      loadedAll,
      modifiedMessage,
      patientId,
      trainerId,
    ]
  );

  useEffect(() => {
    setMessages([]);
    generateListener(
      chatPage && recentMessages[0]?.id
        ? Message.getDocFromRef(
            Message.getRef(trainerId, patientId, recentMessages[0]?.id)
          )
        : 0
    );
    return () => {
      console.log(
        "iterating through each of these listeners",
        listenerManager.listeners
      );
      listenerManager.removeListeners();
    };
  }, [
    chatPage,
    generateListener,
    listenerManager,
    patientId,
    recentMessages,
    trainerId,
  ]);

  const loadMore = (e) => {
    if (loadedAllMessages === false && !isLoading) {
      if (
        -e.target.scrollTop ===
        e.target.scrollHeight - e.target.offsetHeight
      ) {
        generateListener(messages.length);
      }
    }
  };

  const displayMessages = useCallback(
    (messageList) => {
      return messageList.map((message) =>
        displaySingleMessage(message, trainerId)
      );
    },
    [trainerId]
  );

  const displayRecentMessages = useCallback(
    (messageList) => {
      let displayMessages = messageList.map((message) =>
        displaySingleMessage(message, trainerId)
      );
      return displayMessages.reverse();
    },
    [trainerId]
  );

  const displaySingleMessage = (message, trainerId) => {
    return message ? (
      <MessageComponent
        key={message?.id}
        message={message}
        trainerId={trainerId}
      />
    ) : (
      <></>
    );
  };

  return (
    <div className="actual-message-list-container" onScroll={loadMore}>
      {chatPage && recentMessages ? (
        displayRecentMessages(recentMessages)
      ) : (
        <></>
      )}
      {displayMessages(messages)}
      {loadedAllMessages ? (
        <div className="loaded-all-messages">
          It looks like you've loaded
          <br />
          all of your messages to this person.
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MessageList;
