import React, { useContext, useState, useEffect, useCallback } from "react";
import AppLogout from "../components/AppLogout";
import SideBar from "../components/layouts/SideBar";
import { StateContext } from "../contexts/State";
import ChatPatient from "../components/ChatPatient";
import MessageCenter from "../components/MessageCenter";
import SearchBar from "../components/SearchBar";
import Routine from "../models/routine";
import Message from "../models/message";
import { onSnapshot } from "firebase/firestore";

const Chat = () => {
  const [currentPatient, setCurrentPatient] = useState({});
  const [patientSort, setPatientSort] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [patients, setPatients] = useState([]);
	const { state } = useContext(StateContext)
  const [assignedRoutines, setAssignedRoutines] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);

  useEffect(() => {
    setPatients([...state.trainer.patients]);
  }, [state.trainer.patients]);

  useEffect(() => {
    if (currentPatient.id) {
      (async () => {
        const res = await Routine.getFromSubcollection(currentPatient.id);
        setAssignedRoutines(res.map((ar) => { return ar.routineReference }));
      })();
    }
  }, [currentPatient.id]);

  // Sets initial sorting for the chat sidebar. Matches the "timestamp" property on elements in the patientSort array to elements in the "patients" array.
  // adds the value "sortBy" to a patient, which is then used to sort the sidebar.
  useEffect(() => {
    if (patientSort.length > 0) {
      patients.forEach((patient, index) => {
        if (patientSort[index]?.timestamp) {
          patient.sortBy = patientSort[index].timestamp?.toDate();
        } else {
          patient.sortBy = -2;
        }
      });
      const sorted = [...patients].sort(
        (a, b) => Number(b.sortBy) - Number(a.sortBy)
      );
      setFilteredPatients(sorted);
    }
  }, [patientSort, patients]);

  useEffect(() => {
    if (filteredPatients.length === 0) {
      setFilteredPatients(patients);
    }
  }, [filteredPatients, patients]);

  // uses Firestore's onSnapshot() to set a listener on unreadMessages.
  // If a user's ID is present in the unreadMessages array, a red circle will show on that patient (until clicked)
  useEffect(() => {
    if (state.trainer.id) {
      const unsub = onSnapshot(Message.getDocument(state.trainer.id), (doc) => {
        if (doc.data()?.unreadMessages) {
          setUnreadMessages(doc.data()?.unreadMessages);
        }
      });
      return () => unsub();
    }
  }, [state.trainer.id]);

  // this cleanup function removes recentMessages (which are held in a listenr) when Chat is unmounted
  // this prevents the recentMessages array from being appended with duplicate elements.
  useEffect(() => {
    return () => {
      patients.forEach((patient) => {
        patient.recentMessages = [];
      });
    };
  }, [patients]);

  return (
    <AppLogout>
    <SideBar>
      <main className="chat-page__content">
        <h2 className="chat-header">Chat</h2>
        <section className="chat-container">
          <div className="chat-sidebar">
            <div className="chat-searchbar">
              <SearchBar
                className="chat-patient__search"
                inputArray={state.trainer.patients}
                searchFields={["firstName", "lastName"]}
                updateArray={(newArray) => setPatients([...newArray])}
              />
            </div>
            <ul className="chat-patients__list">
              {filteredPatients.map((patient) => (
                <ChatPatient
                  style={{
                    backgroundColor:
                      currentPatient.id === patient.id ? "#d5dffd" : "",
                  }}
                  patient={patient}
                  trainerId={state.trainer.id}
                  onClick={() => {
                    if (unreadMessages.includes(patient.id)) {
                      Message.markAsRead(state.trainer.id, patient.id);
                    }
                    setCurrentPatient(patient);
                  }}
                  key={patient.id}
                  setPatientSort={setPatientSort}
                  unread={unreadMessages.includes(patient.id)}
                  className="chat-patient"
                  addMessage={(message) => {
                    patient.recentMessages.push(message);
                  }}
                />
              ))}
            </ul>
          </div>
          <div className="chat-center__main">
            <MessageCenter
              trainer={state.trainer}
              patient={currentPatient}
              recentMessages={currentPatient.recentMessages}
              chatPage={true}
              routines={assignedRoutines}
            />
          </div>
        </section>
      </main>
    </SideBar>
    </AppLogout>
  );
};
export default Chat;
