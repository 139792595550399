import React, { useState } from "react";
import { capitalizeFirstLetter } from "../../services/helpers";
import ProfilePhoto from "../ProfilePhoto";
import SearchBar from "../SearchBar";
import { trimText } from "../../services/helpers";
import Button from "../Button";

const SchedulePatients = ({
  patients,
  setCurrentPatient,
  setShowCalendar,
  setShowSchedule,
}) => {
  const [filterPatients, setFilterPatients] = useState(patients);
  return (
    <div className="schedule-wrapper">
      <div className="search-container">
        <SearchBar
          placeholder="Search Patients..."
          inputArray={patients}
          searchFields={["firstName", "lastName", "email"]}
          updateArray={(newArray) => setFilterPatients([...newArray])}
        />
        <Button
          className="view-schedule-btn"
          type="button"
          format="minimal fancy"
          onClick={() => setShowSchedule(true)}
        >
          View Schedule
        </Button>
      </div>
      <section className="schedule-patients__container">
        {filterPatients
          .map((patient) => (
            <div
              key={patient.id}
              className="schedule-patient"
              onClick={() => {
                setShowCalendar(true);
                setCurrentPatient(patient);
              }}
            >
              <ProfilePhoto
                imgURL={patient.profilePhotoURL}
                className="schedule-patient__picture"
              />
              {patient.firstName && patient.lastName ? (
                <>
                  <p>{capitalizeFirstLetter(patient?.firstName)}</p>
                  <p>{capitalizeFirstLetter(patient?.lastName)}</p>
                </>
              ) : (
                <p>{patient?.email ? trimText(patient.email) : ""}</p>
              )}
            </div>
          ))
          .reverse()}
      </section>
    </div>
  );
};
export default SchedulePatients;
