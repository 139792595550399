import React from "react";

const PencilSVG = ({ className, onClick = () => {} }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13.0001H3.73333L11.8167 4.91674L11.45 4.55008L11.0833 4.18341L3 12.2667V13.0001ZM2 14.0001V11.8667L11.8 2.06674C11.9889 1.87785 12.225 1.78619 12.5083 1.79174C12.7917 1.7973 13.0278 1.89452 13.2167 2.08341L13.9333 2.80008C14.1222 2.98896 14.2167 3.2223 14.2167 3.50008C14.2167 3.77785 14.1222 4.01119 13.9333 4.20008L4.13333 14.0001H2ZM13.1667 3.48341L12.4833 2.80008L13.1667 3.48341ZM11.8167 4.91674L11.45 4.55008L11.0833 4.18341L11.8167 4.91674Z"
        fill="#FFF"
      />
    </svg>
  );
};
export default PencilSVG;
