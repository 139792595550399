import { useState, useEffect } from "react";
import { getAssessments } from "../models/assessments";
import AssessmentItem from "./AssessmentItem";
import { Link } from "react-router-dom"

const Assessments = (props) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requests = await getAssessments(props.patient);
      setRequests(requests.slice(0, 5));
    };

    fetchData();
  }, [props.patient]);

  const items = requests.map(([id, req]) => (
    <AssessmentItem key={id} patient={props.patient} assessment={req} id={id} />
  ));

  return (
    <div className={`patient-feedback`}>
			<div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
        <h3>Recent Assessments</h3>
        <Link to={`assessments`}>
          <p>
            <strong>See All</strong>
          </p>
        </Link>
      </div>
      {items}
    </div>
  );
};

export default Assessments;
