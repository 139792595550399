import {
  addDoc,
  getDocs,
  getDoc,
  collection,
  deleteDoc,
  setDoc,
  doc,
  updateDoc,
  where,
  get
} from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import ExerciseSet from "./exerciseSet";

class Routine extends BasicFirebaseItem {
  constructor(
    id = "",
    name = "",
    exercises = [], // Array of ExerciseSet
    isTemplate = false
  ) {
    super(id);
    this.name = name;
    this.exercises = exercises;
    this.isTemplate = isTemplate;
  }

  static getRef(trainerId, routineId) {
    return doc(Firebase.db, `routines/trainerRoutines/${trainerId}`, routineId);
  }

  static async getFromRef(routineRef) {
    try {
      const routineData = await super.getFromRef(routineRef);
      routineData.exercises = await Promise.all(
        routineData.exercises.map(
          async (exerciseSet) => await ExerciseSet.getFromRef(exerciseSet)
        )
      );
      routineData.exercises = routineData.exercises.filter((exerciseSet) => {
        return exerciseSet.exercise.displayName;
      });
      return routineData;
    } catch (err) {
      console.error(
        "There was a problem getting the routine from ref",
        routineRef,
        err
      );
      throw err;
    }
  }

  static async getFromSubcollection(id) {
    let ref;
    let subres;
    try {
      ref = collection(Firebase.db, `/users/${id}/tasks`);

      const snap = await getDocs(ref);

      subres = (
        await Promise.all(
          snap.docs
            .filter((o) => o.data().taskType === "assignedRoutine")
            .map(async (doc) => {
              let data = doc.data();
              let active = await data.active;

              let snap = await getDoc(data.routineReference);

              console.log("snap:", snap);
              console.log("snap.exists():", snap.exists());

              if (!snap.exists()) {
                return { routineReference: undefined };
              }
              let routine = snap.data();

              const exercises = await Promise.all(
                routine.exercises.map(async (set) => {
                  const snap = await getDoc(set.exercise);
                  return { ...set, exercise: snap.data() };
                })
              );

              if (active) {
                return {
                  ...data,
                  routineReference: {
                    ...snap.data(),
                    exercises: exercises,
                    id: snap.id,
                  },
                };
              }
              else {
                return {};
              }
            })
        )
      ).filter((obj) => {
        return obj.routineReference !== undefined;
      });
    } catch (err) {
      console.error(
        "There was an error loading the routines from list:",
        ref,
        err
      );
      throw err;
    }

    return subres;
  }

  static async getFromList(routineRefs) {
    try {
      let result = await Promise.all(
        routineRefs.map(
          async (routineRef) => await Routine.getFromRef(routineRef)
        )
      );
      console.log(result);
      return result;
    } catch (err) {
      console.error(
        "There was an error loading the routines from list:",
        routineRefs,
        err
      );
      throw err;
    }
  }

  static async getById(trainerId, id, patientId, isAiRoutine) {

    let collectionPath
    if (isAiRoutine) {
      collectionPath = `routines/aiGeneratedRoutines/${patientId}`
    }
    else {
      collectionPath = `routines/trainerRoutines/${trainerId}`
    }

    try {
      let routineRef = doc(
        Firebase.db,
        collectionPath,
        id
      );
			console.log(trainerId);
      let result = await Routine.getFromRef(routineRef);
			console.log(result);
      return result;
    } catch (err) {
      console.error(
        "There was a problem getting routine by id",
        trainerId,
        id,
        err
      );
      throw err;
    }
  }

  async save(trainer, patient, isAiRoutine) {
    try {
      const trainerRoutinesRef = collection(
        Firebase.db,
        `routines/trainerRoutines/${trainer.id}`
      );
      this.exercises = this.exercises.map((exerciseSet) =>
        ExerciseSet.mapForStorage(exerciseSet)
      );
      const { id, ...preparedRoutineData } = this;
      if (preparedRoutineData.isTemplate) {
        return await Routine.saveAsTemplate(trainer, id, preparedRoutineData);
      }

      let routineRef = null;
      if (id === undefined || id === "") {
        routineRef = await Routine.saveNew(
          trainerRoutinesRef,
          preparedRoutineData
        );
        await patient.addRecommendedRoutine(routineRef);
      } else {
        routineRef = await Routine.update(trainer.id, id, preparedRoutineData, patient.id, isAiRoutine);
      }
      return routineRef;
    } catch (err) {
      console.error(
        "There was a problem saving the routine:",
        this,
        trainer,
        patient,
        err
      );
      throw err;
    }
  }

  static async update(trainerId, routineId, preparedRoutineData, patientId, isAiRoutine) {

    let collectionPath
    if (isAiRoutine) {
      collectionPath = `routines/aiGeneratedRoutines/${patientId}`
    }
    else {
      collectionPath = `routines/trainerRoutines/${trainerId}`
    }

    try {
      return await updateDoc(
        doc(Firebase.db, collectionPath, routineId),
        preparedRoutineData
      );
    } catch (err) {
      console.error(
        "There was a problem updating the routine",
        trainerId,
        routineId,
        preparedRoutineData,
        err
      );
      throw err;
    }
  }

  static async saveNew(collectionRef, preparedRoutineData) {
    try {
      return await addDoc(collectionRef, preparedRoutineData);
    } catch (err) {
      console.error("There was an error adding the routine document:", err);
      throw err;
    }
  }

  static async saveAsTemplate(trainer, routineId, preparedRoutineData) {
    try {
      let routineRef = null;
      if (routineId === undefined || routineId === "") {
        routineRef = await Routine.saveNew(
          collection(Firebase.db, `routines/trainerRoutines/${trainer.id}`),
          preparedRoutineData
        );
        await trainer.addRoutineTemplate(routineRef);
      } else {
        routineRef = await Routine.update(
          trainer.id,
          routineId,
          preparedRoutineData
        );
      }
      return routineRef;
    } catch (err) {
      console.error(
        "There was a problem saving as template",
        trainer,
        routineId,
        preparedRoutineData,
        err
      );
    }
  }

  static async delete(trainer, owner, routine) {
    try {
      const routineRef = Routine.getRef(trainer.id, routine.id);

      if (routine.isTemplate) {
        await trainer.removeRoutineTemplate(routineRef);
      } else {
        await owner.removeRecommendedRoutine(routineRef);
      }

      // Copy the routine to a trash collection to preserve it for recovery later.

      const softDeletion = doc(
        Firebase.db,
        `routines/trash/${trainer.id}/${routineRef.id}`
      );
      await setDoc(softDeletion, routine);

      await deleteDoc(routineRef);

      window.location.reload();
    } catch (err) {
      console.error(
        "There was a problem deleting routine",
        trainer,
        owner,
        routine,
        err
      );
      throw err;
    }
  }

  static async deactivate(trainer, owner, routine) {
    try {
      const routineRef = Routine.getRef(trainer.id, routine.id);
      const taskRef = doc(Firebase.db, `users/${owner.id}/tasks/${routine.id}`);
      await updateDoc(taskRef, { active: false });
      window.location.reload();
    } catch (err) {
      console.error(
        "There was a problem deleting routine",
        trainer,
        owner,
        routine,
        err
      );
      throw err;
    }
  }
}

export default Routine;
