import React from "react";

/**
 *
 * @param {className} className allows for special formatting
 * @returns
 */
const LockSVG = ({ className = "" }) => {
  return (
    <svg
      width="25"
      height="25"
      className={`lock-svg ${className}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.7501 8.33333H17.7084V6.25C17.7084 3.375 15.3751 1.04167 12.5001 1.04167C9.62508 1.04167 7.29175 3.375 7.29175 6.25V8.33333H6.25008C5.10425 8.33333 4.16675 9.27083 4.16675 10.4167V20.8333C4.16675 21.9792 5.10425 22.9167 6.25008 22.9167H18.7501C19.8959 22.9167 20.8334 21.9792 20.8334 20.8333V10.4167C20.8334 9.27083 19.8959 8.33333 18.7501 8.33333ZM9.37508 6.25C9.37508 4.52083 10.7709 3.125 12.5001 3.125C14.2292 3.125 15.6251 4.52083 15.6251 6.25V8.33333H9.37508V6.25ZM17.7084 20.8333H7.29175C6.71883 20.8333 6.25008 20.3646 6.25008 19.7917V11.4583C6.25008 10.8854 6.71883 10.4167 7.29175 10.4167H17.7084C18.2813 10.4167 18.7501 10.8854 18.7501 11.4583V19.7917C18.7501 20.3646 18.2813 20.8333 17.7084 20.8333ZM12.5001 17.7083C13.6459 17.7083 14.5834 16.7708 14.5834 15.625C14.5834 14.4792 13.6459 13.5417 12.5001 13.5417C11.3542 13.5417 10.4167 14.4792 10.4167 15.625C10.4167 16.7708 11.3542 17.7083 12.5001 17.7083Z" />
    </svg>
  );
};
export default LockSVG;
