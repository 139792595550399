import {
  arrayRemove,
  arrayUnion,
  doc,
  updateDoc,
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  getDoc,
  setDoc,
	deleteDoc,
  where,
} from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import Message from "./message";

class NewPatient extends BasicFirebaseItem {
  constructor(
    email = "",
    firstName = "",
    lastName = ""
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  static secureRandomPassword(){
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'+'abcdefghijklmnopqrstuvwxyz0123456789@#$';

    for (let i = 1; i <= 8; i++){
      var char = Math.floor(Math.random() * str.length + 1);
      pass += str.charAt(char);
    }

    return pass;
  }

  static async createNewPatient(email, password, firstName, lastName, trainerId, ptCode) {
    try {
      var tempPassword = NewPatient.secureRandomPassword();//secureRandomPassword();
      console.log(tempPassword);
      const uid = await Firebase.createUser(email, tempPassword, firstName, lastName, trainerId, ptCode);
      return uid;
    } catch (err) {
      console.error(
        "There was a problem updating the routine",
        err
      );
      throw err;
    }
  }



  // static async createUserDoc(email, firstName, lastName, uid, trainerId, ptCode) {
  //   try {
  //     Firebase.newUserDoc(email, firstName, lastName, uid, trainerId, ptCode)
  //     return;
  //   } catch (err) {
  //     console.error(
  //       "There was a problem updating the routine",
  //       err
  //     );
  //     throw err;
  //   }
  // }

  static async saveNewUserDoc(email, firstName, lastName, uid, trainerId, ptCode) {
    try {
      console.log("inside saveNewUserDoc function");
      console.log("uid is: ", uid);
      const data = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        trainerId: trainerId,
        ptCode: ptCode,
        resetPassword: true
      }
      // await setDoc(
      //   doc(Firebase.db, `users/${patient.id}/assignedRoutines`, routine.id),
      //   data
      // );
      await setDoc(
        doc(Firebase.db, `users/${uid}`),
        data
      );
      console.log("successfully added new user doc");
    } catch (err) {
      console.error(
        "There was a problem saving the routine:",
        err
      );
      throw err;
    }
  }

}

export default NewPatient;
