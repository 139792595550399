import React, { useEffect } from "react";
import { toDisplayName, humanize } from "../utils/text.js";

const SurveyResponseDocument = ({ surveyResponse }) => {
    const tableData = (object) => {
      const { question, response } = object;
      const responses = Array.isArray(response) ? response : [response];
  
      return responses.map((resp, index) => (
        <>
          {index === 0 && (
            <tr>
              <td><b>{question}</b></td>
            </tr>
          )}
          <tr>
            <td style={{ paddingLeft: "20px" }}>{humanize(resp)}</td>
          </tr>
        </>
      ));
    };
  
    const tableRows = () => {
      if (!surveyResponse.responses) {
        return;
      }
      return surveyResponse.responses.map((response) => tableData(response));
    };
  
    return (
      <div>
        <h1>{toDisplayName(surveyResponse.name)}</h1>
        {/* <h2 */}
        <table className="assessment-table">
          <tbody>
            {tableRows()}
          </tbody>
        </table>
      </div>
    );
  };
      
export default SurveyResponseDocument;
