export const trainerReducer = (state, action) => {
  switch (action.type) {
    case "STORE_TRAINER_DATA":
      return action.payload;
    default:
      return state;
  }
};

export const firebaseReducer = (state, action) => {
  switch (action.type) {
    case "STORE_FIREBASE_DATA":
      return action.payload;
    default:
      return state;
  }
};
