import React, { render } from "react-dom";

export default function Toggle(props) {
  return (
    <label className="toggle">
      <input type="checkbox" {...props} />
      <span className="slider"></span>
    </label>
  );
}
