import React from "react";

/**
 *
 * @param {className} className allows for special formatting
 * @returns
 */
const EmailSVG = ({ className = "" }) => {
  return (
    <svg
      className={`email-svg ${className}`}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.9166 6.25C22.9166 5.10416 21.9791 4.16666 20.8333 4.16666H4.16659C3.02075 4.16666 2.08325 5.10416 2.08325 6.25V18.75C2.08325 19.8958 3.02075 20.8333 4.16659 20.8333H20.8333C21.9791 20.8333 22.9166 19.8958 22.9166 18.75V6.25ZM20.8333 6.25L12.4999 11.4479L4.16659 6.25H20.8333ZM20.8333 18.75H4.16659V8.33333L12.4999 13.5417L20.8333 8.33333V18.75Z" />
    </svg>
  );
};
export default EmailSVG;
