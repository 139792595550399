import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import RoutineCard from "./RoutineCard";
import Modal from "./Modal";
import Routine from "../models/routine";

/**
 * RoutineCard Component
 * @param routine Routine for which to display information
 * @param className Additional classes to be applied
 * @returns Routine card that shows an image, the routine name, and a list of exercises
 */
function RoutineSelector({
  routineList = [],
  trainer = "",
  owner = "",
  onSelect = null,
  afterDelete = () => {},
  className = "",
}) {
  const navigate = useNavigate();
  const [routineToDelete, setRoutineToDelete] = useState(null);

  // Navigates to a page to edit the routine
  const redirectToRoutine = (routine) => {
    navigate(`routine/${routine.id}/edit`);
  };
  const closeVerifyDeletionModal = () => {
    setRoutineToDelete(null);
  };
  const verifyDeletion = (routine) => {
    setRoutineToDelete(routine);
  };
  const deleteRoutine = async () => {
    await Routine.deactivate(trainer, owner, routineToDelete);
    setRoutineToDelete(null);
    afterDelete();
  };

  return (
    <div className={`routine-selector ${className}`}>
      {routineList.map((routine) => (
        <RoutineCard
          key={routine.id}
					routine={routine.isTemplate ? routine : routine.routineReference}
          selectable={onSelect ? true : false}
          onSelect={() => onSelect(routine)}
          onEdit={() => redirectToRoutine(routine)}
          onDelete={() => verifyDeletion(routine)}
        >
          {routine.name}
        </RoutineCard>
      ))}
      <Modal
        show={routineToDelete}
        onClose={closeVerifyDeletionModal}
        useCloseButton={true}
      >
        {routineToDelete ? (
          <>
            <h3>
              Are you sure you want to delete this{" "}
              {routineToDelete.isTemplate ? "template" : "routine"}?
            </h3>
            <p className="warning">This cannot be undone</p>
            <div className="delete-buttons">
              <Button
                value="cancel"
                type="button"
                onClick={closeVerifyDeletionModal}
                format="transparent"
              >
                Cancel
              </Button>
              <Button type="button" format="warning" onClick={deleteRoutine}>
                Delete
              </Button>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
}

export default RoutineSelector;
