import SideBar from "../components/layouts/SideBar";
import AppLogout from "../components/AppLogout";
import Button from "../components/Button";
import TextBox from "../components/TextBox";
import ReactPlayer from "react-player";
import AssessmentItem from "../components/AssessmentItem";
import AssessmentDocument from "../components/AssessmentDocument";

import { recommend } from "../utils/naiveRoutineRecommender";
import { toDisplayName } from "../utils/text";

import { getPatient } from "../models/patient";
import {
  reassess,
  getAssessments,
  getMotionAssessmentTask,
} from "../models/assessments";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getRequestMedia, submitFeedbackResponse } from "../models/feedback";

const Assessments = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const [assessments, setAssessments] = useState([]);
  const [patient, setPatient] = useState({});
  const [text, setText] = useState("Generate Routine");

  var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[1][key]] = rv[x[1][key]] || []).push(x);
      return rv;
    }, {});
  };

  function compare([id1, lhs], [id2, rhs]) {
    if (lhs.completedAt.toDate() > rhs.completedAt.toDate()) {
      return 1;
    } else if (lhs.completedAt.toDate() < rhs.completedAt.toDate()) {
      return -1;
    } else {
      return 0;
    }
  }

  groupBy(assessments, "exercise");

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      let patient = await getPatient(patientId);
      let assessments = await getAssessments({ id: patient.uid });

      if (!ignore) {
        setPatient(patient);
        setAssessments(assessments);
      }
    };

    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  async function handler(event) {
    setText("Generating routine...");
    let { id } = await recommend(patient, request);
    navigate(`/patient/${patient.uid}/routine/${id}/edit`);
  }

  async function aggregate() {
    setText("Generating routine...");

    let latest = Object.entries(groupBy(assessments, "exercise"))
      .map(([group, assessments]) => {
        return assessments.sort(compare).reverse()[0][1];
      })
      .flat();

    let assessment = latest.reduce((acc, x) => {

			let xr = x.results ? x.results.boolean : {}
			let ar = acc.results ? acc.results.boolean : {}

      return {
        ...acc,
        results: {
          boolean: { ...xr, ...ar }
        },
      };
		}, { exercise: "compound" });

    let { id } = await recommend(patient, assessment);

    navigate(`/patient/${patient.uid}/routine/${id}/edit`);
  }

  return (
    <AppLogout>
      <SideBar>
        <div className="feedback-container">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Patients
            </Link>
            /{" "}
            <Link to={`/patient/${patient.uid}`} className="current-route">
              {patient.firstName} {patient.lastName}
            </Link>
            / <span>Assessment</span>
          </div>

          <h1>Assessments</h1>
          <div className="assessments-container">
            <div className="patient-feedback">
              {Object.entries(groupBy(assessments, "exercise")).map(
                ([header, assessments]) => {
                  return (
                    <>
                      <h3>{toDisplayName(header)}</h3>

                      {assessments
                        .sort(compare)
                        .reverse()
                        .map(([id, a]) => (
                          <AssessmentItem
                            patient={patient}
                            assessment={a}
                            id={id}
                          />
                        ))}
                    </>
                  );
                }
              )}
            </div>

            <div>
              <Button onClick={() => aggregate()}>{text}</Button>
            </div>
          </div>
        </div>
      </SideBar>
    </AppLogout>
  );
};

export default Assessments;
