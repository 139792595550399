import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfilePhoto from "../ProfilePhoto";
import { StateContext } from "../../contexts/State";
import Button from "../Button";
import Firebase from "../../services/firebase";
import TextSVGContainer from "../svg/TextSVGContainer";
import LogoSVG from "../svg/LogoSVG";
import NavbarLink from "./NavbarLink";
import { onSnapshot } from "firebase/firestore";
import Message from "../../models/message";

/**
 *
 * @param children is the content that should be showed on the right side of the screen
 * @returns
 */
const SideBar = ({ children }) => {
  const { state, dispatch } = useContext(StateContext);
  const [unread, setUnread] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.trainer.id) {
      const unsub = onSnapshot(Message.getDocument(state.trainer.id), (doc) => {
        if (doc.data()?.unreadMessages) {
          setUnread(doc.data()?.unreadMessages);
        }
      });
      return () => unsub();
    }
  }, [state.trainer.id]);

  return (
    <div className="sidebar-layout">
      <div className="sidebar">
        <div className="sidebar-top">
          <ProfilePhoto imgURL={state.trainer.profilePhotoURL} />
          <nav className="nav-links_container">
						<NavbarLink to={"/dashboard"}>
							<p>Patients</p>
            </NavbarLink>
            <NavbarLink to={"/chat"}>
              <p>Chat</p>
              {unread?.length > 0 && <div className="ping"></div>}
            </NavbarLink>
            <NavbarLink to={"/schedule"}>
              <p>Schedule</p>
            </NavbarLink>
            <NavbarLink to={"/exercises"}>
              <p>Exercises</p>
            </NavbarLink>
          </nav>
        </div>
        <div className="sidebar-bottom">
          {/* Can add this button back in once scope expands to require it */}
          <Button
            id="settings-button"
            className="settings-button"
            type="button"
            onClick={() => {
              navigate("/settings");
            }}
            children={"Settings"}
          />
          <Button
            id="logout-button"
            className="logout-button"
            type="button"
            onClick={async () => {
              await Firebase.logoutUser();
              await dispatch({ type: "REMOVE_ALL_DATA" });
              navigate("/");
            }}
            children={
              <>
                <TextSVGContainer>
                  <span className="material-icons">exit_to_app</span>
                </TextSVGContainer>{" "}
                Logout
              </>
            }
          />
        </div>
      </div>
      <main className="sidebar-main-content">{children}</main>
    </div>
  );
};
export default SideBar;
