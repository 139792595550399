import {
  arrayRemove,
  arrayUnion,
  doc,
  updateDoc,
  collection,
  getDocs,
  orderBy,
  query,
  limit,
  getDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";
import Message from "./message";

/* New Patient Functions
 *
 * Invariants: 
 *
 * - User Doc Exists
 * @author Aaron Sutton
 */
export { getPatient }

async function getPatient(id) {
  let d = doc(Firebase.db, `users/${id}`)
  return (await getDoc(d)).data()
}

// ********** PATIENT CLASS ************

class Patient extends BasicFirebaseItem {
  constructor(
    id = "",
    gender = "",
    height = "",
    weight = "",
    dob,
    email = "",
    firstName = "",
    lastName = "",
    streak = -1,
    adherence = -1,
    profilePhotoURL = "",
    assessmentWarning = {
      showWarning: false,
      read: false,
    },
    recentMessages = [],
  ) {
    super(id);
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.streak = streak;
    this.adherence = adherence;
    this.profilePhotoURL = profilePhotoURL;
    this.assessmentWarning = assessmentWarning;
    this.recentMessages = recentMessages;
    this.dob = dob;
    this.gender = gender;
    this.height = height;
    this.weight = weight;
  }

  static async getFromStudyPool(email) {
    let q = collection(Firebase.db, "users")
		q = query(q, where("ptCode", "==", "study"));
		q  = query(q, where("email", "==", email));
    const snap = await getDocs(q);
    if (snap.docs.length === 0) return;
    return { id: snap.docs[0].id, ...snap.docs[0].data() };
  }

	static async getById(id) {
		let ref = Patient.getRef(id)
		let snap = await getDoc(ref)
		const data = snap.data()
    console.log("getting by ID! Gender: "+data.gender)
		return new Patient(
			id = snap.id,
      data.gender,
      data.height,
      data.weight,
      data.dob,
			data.email,
			data.firstName,
			data.lastName,
			data.profilePhotoURL,
		)
	}

  static getRef(id) {
    return doc(Firebase.db, "users", id);
  }

  getRef(id) {
    return doc(Firebase.db, "users", this.id);
  }

  async addRecommendedRoutine(routineRef) {
    try {
      return await updateDoc(this.getRef(), {
        recommendedRoutines: arrayUnion(routineRef),
      });
    } catch (err) {
      console.error(
        "There was a problem adding the recommended routine",
        this,
        routineRef,
        err
      );
      throw err;
    }
  }

  async removeRecommendedRoutine(routineRef) {
    try {
      const assignments = collection(this.getRef(), "tasks");
      const routine = doc(assignments, routineRef.id);
      await deleteDoc(routine);
    } catch (err) {
      console.error(
        "there was a problem deleting the recommended routine",
        this,
        routineRef,
        err
      );
      throw err;
    }
  }

  static async update(patientId, newData) {
    try {
      return await updateDoc(doc(Firebase.db, `users/${patientId}`), newData);
    } catch (err) {
      console.error(
        "There was a problem updating the routine",
        patientId,
        newData,
        err
      );
      throw err;
    }
  }

  // painData is stored as a collection on a user
  static async getPainData(patientId, limitTo = 31) {
    const today = new Date();
    let startDay = new Date();
    startDay.setDate(startDay.getDate() - limitTo);

    try {
      const painDataCollection = collection(
        Firebase.db,
        `users/${patientId}/painData`
      );
      const painData = query(
        painDataCollection,
        where("completedAt", "<=", today),
        where("completedAt", ">=", startDay),
        orderBy("completedAt", "desc"),
        limit(limitTo)
      );
      const data = [];
      const snapshot = await getDocs(painData);
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      return data.reverse();
    } catch (error) {
      console.error("there was a problem getting pain data: ", error);
      return [];
    }
  }

  // daysWorkedOut is stored inside of the metrics collection. The corresponding metric document is found by a user's id.
  static async getDaysWorkedOut(patientId) {
    try {
      const data = (
        await getDoc(doc(Firebase.db, `metrics/${patientId}`))
      ).data();
      if (data) {
        const sorted = data.events.sort(
          (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
        );
        return sorted;
      } else return [];
    } catch (error) {
      console.error("Could not find metrics for this user: ", error);
      return [];
    }
	}

  static async getStatistics(patient) {
		if (!patient) return;
		if (!patient.id) return;
    let q = collection(Firebase.db, `users/${patient.id}/statistics`)
    const snap = await getDocs(q);
    if (snap.docs.length === 0) return {};
		return snap.docs.map(s => s.data());
  }


  // a streak is stored inside of the metrics collection, as described in getDaysWorkedOut()
  static async getStreak(patientId) {
    try {
      const data = (
        await getDoc(doc(Firebase.db, `metrics/${patientId}`))
      ).data();
      return data;
    } catch (error) {
      console.error(`Could not find streak for this user: ${error}`);
      return {};
    }
  }

  async loadRecommendedRoutines() {}
}

export default Patient;
