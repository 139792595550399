import React, { useEffect, useState } from "react";
import Message from "../models/message";
import Routine from "../models/routine";
import MiniRoutineCard from "./MiniRoutineCard";

function MessageComponent({ message = new Message(), trainerId = "" }) {
  const [descriptor, setDescriptor] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    let newDescriptor = null;
    switch (message.type) {
      case "file":
        newDescriptor = "Sent a file";
        break;
      case "routine":
        newDescriptor = "Sent a routine";
        break;
      case "nudge":
        newDescriptor = "Sent a nudge";
        break;
      case "text":
      default:
        newDescriptor = "";
        break;
    }
    setDescriptor(newDescriptor);
  }, [message.type]);
  useEffect(() => {
    // setTimestamp(message.setTimestamp.toDate());
    const sentDateTime = message.sentTimestamp.toDate();
    const diffDate = new Date(new Date() - sentDateTime);
    let dateString = "";
    const isDiffYear = diffDate.getUTCFullYear() !== 1970;
    const isDiffMonth = diffDate.getUTCMonth() !== 0;
    const isDiffDay = diffDate.getUTCDate() !== 1;
    const isYesterday = diffDate.getUTCDate() === 2;
    const isWithinWeek = diffDate.getUTCDate() <= 7;
    if (!isDiffYear && !isDiffMonth && !isDiffDay) {
      dateString = sentDateTime.toLocaleString("default", {
        timeStyle: "short",
      });
    } else if (!isDiffYear && !isDiffMonth && isYesterday) {
      dateString = `Yesterday ${sentDateTime.toLocaleString("default", {
        timeStyle: "short",
      })}`;
    } else if (!isDiffYear && !isDiffMonth && isWithinWeek) {
      dateString = sentDateTime.toLocaleString("default", {
        weekday: "short",
        hour: "numeric",
        minute: "2-digit",
      });
    } else if (!isDiffYear) {
      dateString = sentDateTime.toLocaleString("default", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      });
    } else {
      dateString = sentDateTime.toLocaleString("default", {
        dateStyle: "medium",
        timeStyle: "short",
      });
    }
    setTimestamp(dateString);
  }, [message.sentTimestamp]);
  useEffect(() => {
    (async () => {
      let getNewContent = async () => {
        switch (message.type) {
          case "file":
            return (
              <div className="message-text">
                <a href={message.data.url} download>
                  {message.data.filename}
                </a>
              </div>
            );
          case "routine":
            let routine = await Routine.getFromRef(message.data);
            return <MiniRoutineCard routine={routine} />;
          case "nudge":
          case "text":
          default:
            return (
              <div className="message-text">
                <span>{message.data}</span>
              </div>
            );
        }
      };
      let newContent = await getNewContent();
      setContent(newContent);
    })();
  }, [message.type, message.data]);

  return (
    <div
      className={`message-component ${
        message.sender.id === trainerId ? "sent" : "recieved"
      }`}
    >
      <div className={`message-info`}>
        <div className="message-descriptor">{descriptor}</div>
        <div className="message-timestamp">{timestamp}</div>
      </div>
      {content}
    </div>
  );
}

export default MessageComponent;
