import React from "react";

const CalendarSVG = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1688 20.0415C15.4285 20.0415 14.8056 19.7887 14.3 19.2832C13.7944 18.7776 13.5417 18.1547 13.5417 17.4144C13.5417 16.6741 13.7944 16.0512 14.3 15.5457C14.8056 15.0401 15.4285 14.7873 16.1688 14.7873C16.909 14.7873 17.5319 15.0401 18.0375 15.5457C18.5431 16.0512 18.7958 16.6741 18.7958 17.4144C18.7958 18.1547 18.5431 18.7776 18.0375 19.2832C17.5319 19.7887 16.909 20.0415 16.1688 20.0415ZM4.875 23.8332C4.44167 23.8332 4.0625 23.6707 3.7375 23.3457C3.4125 23.0207 3.25 22.6415 3.25 22.2082V5.4165C3.25 4.98317 3.4125 4.604 3.7375 4.279C4.0625 3.954 4.44167 3.7915 4.875 3.7915H6.63542V2.1665H8.39583V3.7915H17.6042V2.1665H19.3646V3.7915H21.125C21.5583 3.7915 21.9375 3.954 22.2625 4.279C22.5875 4.604 22.75 4.98317 22.75 5.4165V22.2082C22.75 22.6415 22.5875 23.0207 22.2625 23.3457C21.9375 23.6707 21.5583 23.8332 21.125 23.8332H4.875ZM4.875 22.2082H21.125V10.5623H4.875V22.2082ZM4.875 8.93734H21.125V5.4165H4.875V8.93734ZM4.875 8.93734V5.4165V8.93734Z"
        fill="white"
      />
    </svg>
  );
};
export default CalendarSVG;
