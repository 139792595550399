import React, { Component, useEffect, useState } from "react";
import EyeSVG from "../components/svg/EyeSVG";

/**
 * TextInput Component
 * Is a generally stylized and reusable input
 * @param id Id for the generated input field.  Applied to input element itself
 * @param className Additional classes to add to the overall wrapper of component
 * @param type Input type passed into input element
 * @param pattern Input validation pattern to be passed onto input field
 * @param onChange Function that gets sent the event every time input field is changed
 * @param label Text to be displayed above input
 * @param errorMessage Text to displayed below input field if there is an error
 * @param hasError Boolean value to determine whether input should be styled to have error and if error message should be displayed
 * @param setHasError Function that is called once input is validated
 * @param defaultValue Value to which the input should be initialized
 * @param icon Icon to be showed before input.  Should be an element rather than a url
 * @param minLength Minimum length of input text to be considered valid
 * @param required Whether or not the input should be marked valid in a form
 * @param onBlur Function that is passed the event whenever onBlur is called by the input
 * @param name Name to be applied to the input element
 * @param min Minimum number value passed onto input element
 * @param max Maximum number value passed onto input element
 */
const TextListInput = ({
  id = "",
  className = "",
  name = "",
  onChange = (list) => {},
  label = "",
  placeholder = "Hit enter to add an item to the list",
  defaultValue,
  required = true,
  onBlur = () => {},
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(defaultValue);
  }, [defaultValue]);

  const handleInput = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (e.target.value !== "") {
        let newText = e.target.value;
        console.log("Adding text", newText);
        setItems((oldValue) => {
          const newValue = [...oldValue, newText];
          onChange(newValue);
          return newValue;
        });
        e.target.value = "";
      }
    }
  };

  return (
    <label className={`text-input-component ${className}`}>
      <span className="label">{label}</span>
      <div className={"wrapper "}>
        <input
          id={id}
          className={"input"}
          type={"text"}
          placeholder={placeholder}
          name={name}
          onKeyDown={handleInput}
          required={required}
        ></input>
      </div>
      <div className="text-input-items">
        {items.map((item, index) => (
          <div
            key={`text-input-${index}-${label.replace(/\s/g, "")}`}
            className="text-list-input-item"
          >
            <span className="item-content">{item}</span>
            <span className="item-remove">
              <span
                className="material-icons"
                onClick={() =>
                  setItems((old) => {
                    let newItems = [...old];
                    newItems.splice(index, 1);
                    onChange(newItems);
                    return newItems;
                  })
                }
              >
                close
              </span>
            </span>
          </div>
        ))}
      </div>
    </label>
  );
};

export default TextListInput;
