import React, { Component } from "react";
import TextSVGContainer from "./svg/TextSVGContainer";

/**
 * IncognitoInput Component
 * Input component that appears as normal text and doesn't have an outline or border
 * Once hovered over an edit button appears to the right
 * @param id Id for the input element
 * @param className Additional class names for main wrapper
 * @param type Input type to be passed to input
 * @param onChange Function called and passed the change event
 * @param placeholder Default text to be shown in background of empty input field
 * @param onBlur Function called and passed the blur event
 * @param icon Component/Element to be shown at beginning of input field
 * @param name Name to be applied to input field
 * @param value Default value to be given to input
 */
export default class IncognitoInput extends Component {
  static defaultProps = {
    id: "",
    className: "",
    type: "text",
    onChange: () => {},
    placeholder: "",
    onBlur: () => {},
    icon: "",
    name: "",
    value: "",
    error: false,
    errorText: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      value: props.value,
      error: props.error,
      errorText: props.errorText,
    };
  }

  render() {
    return (
      <label
        className={`incognito-input-wrapper ${this.props.className}`}
        onMouseEnter={(e) => {
          this.setState({ ...this.state, showEdit: true });
        }}
        onMouseLeave={(e) => {
          this.setState({ ...this.state, showEdit: false });
        }}
      >
        <span className="text-icon">{this.props.icon}</span>
        <div className="input-formatter">
          <input
            id={this.props.id}
            className={`input ${this.props.error ? "error" : ""}`}
            type={this.props.type}
            placeholder={this.props.placeholder}
            name={this.props.name}
            onChange={(e) => {
              this.state.value = e.target.value;
              this.props.onChange(e);
            }}
            onBlur={(e) => {
              this.state.value = e.target.value;
              this.props.onBlur(e);
            }}
            onFocus={(e) => {
              // Automatically highlights all text when selecting input for the first time
              e.target.setSelectionRange(0, e.target.value.length);
            }}
            defaultValue={this.props.value}
          />
          {this.props.error && (
            <span className="error-text">{this.props.errorText}</span>
          )}
          <span className="input-formatter-span">
            {this.state.value !== ""
              ? this.state.value
              : this.props.value !== ""
              ? this.props.value
              : this.props.placeholder}
          </span>
        </div>
        <TextSVGContainer
          className={`edit-button ${
            this.state.showEdit ? "visible" : "invisible"
          }`}
        >
          <span className="material-icons-outlined">
            drive_file_rename_outlined
          </span>
        </TextSVGContainer>
      </label>
    );
  }
}
