import React, { useCallback, useContext, useEffect, useState } from "react";
import AppLogout from "../components/AppLogout";
import SideBar from "../components/layouts/SideBar";
import SearchBar from "../components/SearchBar";
import FuzzySearch from "../components/FuzzySearch";
import Firebase, { useFirebaseAuthentication } from "../services/firebase";
import { StateContext } from "../contexts/State";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfilePhoto from "../components/ProfilePhoto";
import Button from "../components/Button";
import IncognitoInput from "../components/IncognitoInput";
import SelectedExercise from "../components/SelectedExercise";
import AvailableExercise from "../components/AvailableExercise";
import Modal from "../components/Modal";
import TextInput from "../components/TextInput";
import Routine from "../models/routine";
import Patient from "../models/patient";
import ExerciseSet from "../models/exerciseSet";
import Exercise from "../models/exercise";
import Trainer from "../models/trainer";
import AddExerciseModal from "../components/AddExerciseModal";
import TypeformModal from "../components/Typeform/TypeformModal";
import TypeformButtons from "../components/Typeform/TypeformButtons";
import FloatingActionButton from "../components/FloatingActionButton";
import NewExerciseModal from "../components/NewExerciseModal";
import ListenerManager from "../models/listenerManager";
import ScheduleRoutine from "../components/ScheduleRoutine";
import RadioList from "../components/RadioList";
import CalendarSVG from "../components/svg/CalendarSVG";
import PencilSVG from "../components/svg/PencilSVG";
import VideoModal from "../components/VideoModal";
import { formatDate } from "../services/helpers";
import { getDoc } from "firebase/firestore";
import AssignedRoutine from "../models/assignedRoutine";
import GoogleSheets from "../services/googleSheets";

/**
 * EditRoutinePage
 * Allows users to edit or create routines
 */
const Exercises = () => {
  const { patientId, routineId, templateId } = useParams(); // loads the patient id and the routineId from current url params if available
  const defaultExerciseSaveData = {
    sets: "",
    work: "",
    notes: "",
    exerciseSet: null,
    index: -1,
    isNewExercise: false,
  };
  const [currentRoutine, setCurrentRoutine] = useState(new Routine());
  const [currentPatient, setCurrentPatient] = useState(new Patient());
  const [filteredList, setFilteredList] = useState([]);
  const [EZPTExerciseList, setEZPTExerciseList] = useState([]);
  const [customExerciseList, setCustomExerciseList] = useState([]);
  const [reservedNames, setReservedNames] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [routineName, setRoutineName] = useState("");
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const [currentlyDragging, setCurrentlyDragging] = useState(false);
  const [exerciseSaveData, setExerciseSaveData] = useState(
    defaultExerciseSaveData
  );
  const [questionnaireModalOpen, setQuestionnaireModalOpen] = useState(false);
  const [assessmentModalOpen, setAssessmentModalOpen] = useState(false);
  const [exerciseModalOpen, setExerciseModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [listenerManager] = useState(new ListenerManager());
  const [exerciseToEdit, setExerciseToEdit] = useState(new Exercise());
  const [exerciseToDelete, setExerciseToDelete] = useState(null);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [routineSchedule, setRoutineSchedule] = useState({});
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [noScheduleError, setNoScheduleError] = useState(false);
  const { state, dispatch } = useContext(StateContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res = await Routine.getFromSubcollection(patientId);
      const names = res.map((r) => r.routineReference.name);
      setReservedNames(names);
    })();
  }, [patientId]);

  // Loads the previous routine data if routineId is defined
  // as soon as the patientId for the trainer in the state is loaded
  useEffect(() => {
    if (state.trainer.id !== "" && routineId !== undefined) {
      if (currentRoutine.name === "" || currentRoutine.name === undefined) {
        Routine.getById(state.trainer.id, routineId).then((routine) => {
          setCurrentRoutine(routine);
        });
      }
    } else if (state.trainer.id !== "" && templateId !== undefined) {
      if (currentRoutine.name === "") {
        Routine.getById(state.trainer.id, templateId).then((routine) => {
          setCurrentRoutine({ ...routine, id: "", isTemplate: false });
        });
      }
    }
  }, [state.trainer.id, routineId, currentRoutine.name, templateId]);

  const modifiedExercise = useCallback(
    (exercise) => {
      const newExerciseSet = Object.assign(new ExerciseSet(), {
        exercise: exercise,
      });
      setCustomExerciseList((previousExercises) =>
        previousExercises.map((value) => {
          return exercise.id === value.exercise.id ? newExerciseSet : value;
        })
      );
    },
    [setCustomExerciseList]
  );
  const addedExercise = useCallback(
    (exercise) => {
      const newExerciseSet = Object.assign(new ExerciseSet(), {
        exercise: exercise,
      });
      setCustomExerciseList((previousExercises) => [
        ...previousExercises,
        newExerciseSet,
      ]);
    },
    [setCustomExerciseList]
  );
  const deletedExercise = useCallback(
    (exercise) => {
      setCustomExerciseList((previousExercises) =>
        previousExercises.filter((value) => {
          return exercise.id !== value.exercise.id;
        })
      );
    },
    [setCustomExerciseList]
  );

  useEffect(() => {
    if (state.trainer.id) {
      const customExercisesListener = Exercise.setupListener(
        state.trainer.id,
        modifiedExercise,
        addedExercise,
        deletedExercise
      );
      listenerManager.addListener(customExercisesListener);
    }
  }, [state.trainer.id]);

  // This creates ExerciseSets for all of the exercises for displaying
  useEffect(() => {
    (async () => {
      let exercises = await Exercise.getAllEZPTExercises();
      setEZPTExerciseList(
        await Promise.all(
          exercises.map(async (exercise) => {
            /////////////////////////////////////////////////
            // **** Not using exercise images for now **** //
            /////////////////////////////////////////////////
            // exercise.imgURL = await Exercise.getImage(exercise.name);
            return Object.assign(new ExerciseSet(), { exercise: exercise });
          })
        )
      );
    })();
    return () => {
      listenerManager.removeListeners();
    };
  }, []);

  // Sets the selected list once the currentRoutine is loaded
  useEffect(() => {
    const getImages = async () => {
      setSelectedList(
        await Promise.all(
          currentRoutine.exercises.map(async (exerciseSet) => {
            /////////////////////////////////////////////////
            // **** Not using exercise images for now **** //
            /////////////////////////////////////////////////
            // if (!exerciseSet.exercise.imgURL)
            //   exerciseSet.exercise.imgURL = await Exercise.getImage(
            //     exerciseSet.exercise.name
            //   );
            return exerciseSet;
          })
        )
      );
    };
    getImages();
  }, [currentRoutine.exercises]);

  // Sets the routine name as soon as it is loaded
  useEffect(() => {
    setRoutineName(currentRoutine.name);
  }, [currentRoutine.name]);

  // Initializes the filtered list to the exercise list once it is available
  useEffect(() => {
    setFilteredList([...customExerciseList, ...EZPTExerciseList]);
  }, [EZPTExerciseList, customExerciseList]);

  // const openModal = (exerciseSet, index = -1, isNewExercise = false) => {
  //   setExerciseSaveData({
  //     exerciseSet: exerciseSet,
  //     sets: exerciseSet.work.length,
  //     work:exerciseSet.work[0],
  //     index: index,
  //     isNewExercise: isNewExercise
  //   });
  // }
  const closeModal = () => {
    setExerciseSaveData(defaultExerciseSaveData);
  };
  const closeScheduleModal = () => {
    setScheduleModalOpen(false);
  };
  const saveExerciseData = (sets, work, unit, notes) => {
    let modifiedExerciseSet = null;
    if (exerciseSaveData.isNewExercise) {
      modifiedExerciseSet = { ...exerciseSaveData.exerciseSet };
    } else {
      modifiedExerciseSet = exerciseSaveData.exerciseSet;
    }
    const workArray = Array(sets).fill(work);
    const restArray = Array(sets).fill(60);
    modifiedExerciseSet.work = workArray;
    modifiedExerciseSet.rest = restArray;
    modifiedExerciseSet.unit = unit;
    modifiedExerciseSet.notes = notes;
    if (exerciseSaveData.isNewExercise) {
      if (exerciseSaveData.index === -1) {
        setSelectedList([...selectedList, modifiedExerciseSet]);
      } else {
        const newList = [...selectedList];
        newList.splice(exerciseSaveData.index, 0, modifiedExerciseSet);
        setSelectedList(newList);
      }
    }
    closeModal();
  };

  const saveRoutineData = async () => {
    // builds routine from pieces of data and saves it
    console.log(currentRoutine.isTemplate);
    if (
      routineName !== "" &&
      (currentRoutine.isTemplate ||
        Object.keys(routineSchedule.due).length !== 0)
    ) {
      try {
        const mainRoutineToSave = Object.assign(new Routine(), {
          ...currentRoutine,
          exercises: [...selectedList],
          name: routineName,
        });
        const newRoutine = await mainRoutineToSave.save(
          state.trainer,
          currentPatient
        );

        if (!currentRoutine.isTemplate) {
          try {
            const getRoutineId = await getDoc(newRoutine);
            console.log(getRoutineId);
            const routineId = getRoutineId.id;

            // if there is a schedule, save it
            // if (Object.keys(routineSchedule.due).length !== 0) {
            await saveSchedule(newRoutine, routineId);
            // }
          } catch (error) {
            console.error("error saving schedule to routine: ", error);
          }
        }
        if (saveAsTemplate) {
          const templateRoutine = Object.assign(new Routine(), {
            ...mainRoutineToSave,
            id: "",
            isTemplate: true,
          });
          await templateRoutine.save(state.trainer, currentPatient);
          await refreshTrainerData();
        }
        navigate(`/patient/${patientId}`);
      } catch (err) {
        console.error("There was a problem saving.", err);
      }
    } else {
      // if routine doesn't have a title, show error text
      if (routineName === "") {
        setError(true);
				setErrorText("Please give you routine a title.")
      }

      if (reservedNames.includes(routineName)) {
        setError(true);
				setErrorText("Routine with this name already exists.")
      }

      if (Object.keys(routineSchedule.due).length === 0) {
        setNoScheduleError(true);
      }
    }
  };

  const saveSchedule = async (routineRef, routineId) => {
    try {
      routineSchedule.routineReference = routineRef;
      routineSchedule.taskType = 'assignedRoutine';
      let routine = currentRoutine;
      routine.id = routineId;

      await AssignedRoutine.saveAssignedRoutine(
        routine,
        currentPatient,
        Object.assign({}, routineSchedule)
      );
    } catch (error) {
      console.error("could not update scheduled routine: ", error);
    }
  };

  const refreshTrainerData = async () => {
    await Trainer.getById(state.trainer.id)
      .then(async (trainer) => {
        await dispatch(Object.assign(state.trainer, trainer), {
          type: "STORE_TRAINER_DATA",
        });
      })
      .catch((err) => {
        Firebase.logoutUser();
        navigate("/");
      });
  };

  const closeModals = () => {
    setQuestionnaireModalOpen(false);
    setAssessmentModalOpen(false);
    setExerciseModalOpen(false);
    setVideoModalOpen(false);
  };

  // Simple function get the current patient's full name
  const fullName = useCallback(() => {
    return currentPatient.firstName && currentPatient.lastName
      ? `${currentPatient.firstName} ${currentPatient.lastName}`
      : currentPatient.email;
  }, [currentPatient.firstName, currentPatient.lastName, currentPatient.email]);

  const editExercise = (exercise) => {
    setExerciseToEdit(exercise);
    setExerciseModalOpen(true);
  };

  // Show a modal to preview the exercise video from cloud storage.
  const previewExercise = (exercise) => {
    setExerciseToEdit(exercise);
    setVideoModalOpen(true);
  };

  const verifyDeletion = (exercise) => {
    setExerciseToDelete(exercise);
  };
  const closeVerifyDeletionModal = () => {
    setExerciseToDelete(null);
  };
  const deleteExercise = async () => {
    await exerciseToDelete.delete();
    setExerciseToDelete(null);
  };

  const handleRoutineSchedule = (newInterval) => {
    setCurrentRoutine((prev) => {
      return { ...prev, ...newInterval };
    });
  };

  // const onCreateRoutine = async (assignedRoutineRef) => {
  //   setCurrentRoutine((prev) => {
  //     return {
  //       ...prev,
  //       assignedRoutineRef: assignedRoutineRef,
  //     };
  //   });
  // };

  // const checkForTitle = (value) => {
  //   if (value === "") {
  //     setError(true)
  //   } else {
  //     setError(false)
  //   }
  //   console.log(error)
  // }

  return (
    <AppLogout>
      <SideBar>
        {/* <TypeformModal
        showQuestionnaire={questionnaireModalOpen}
        showAssessment={assessmentModalOpen}
        trainer={state.trainer}
        currentPatient={currentPatient}
        setClose={closeModals}
        canEdit={false}
      /> */}

        <Modal
          show={questionnaireModalOpen}
          onClose={closeModals}
          useCloseButton={true}
        >
          <div></div>
        </Modal>

        <Modal
          show={assessmentModalOpen}
          onClose={closeModals}
          useCloseButton={true}
        >
          <GoogleSheets
            canEdit={false}
            currentPatient={currentPatient}
            formType="assessment"
          />
        </Modal>
        <div className="routine-content">
          <div className="navigation-bar">
            <Link to="/dashboard" className="link">
              Exercises
            </Link>
            {" "}
          </div>

          <div className="header">
          </div>
          <div className="routines-header-container">
							<div className="routines-pre-header">
              <Button
                format="transparent"
                id="create-exercise-button"
                className="create-exercise-button"
                type="button"
                onClick={() => {
                  setExerciseToEdit(new Exercise());
                  setExerciseModalOpen(true);
                }}
              >
                <span className="material-icons">add_circle_outline</span>{" "}
                Create Exercise
              </Button>
            </div>
            <div className="exercise-options">
              <FuzzySearch
                className="edit-routine__searchbar"
                inputArray={[...customExerciseList, ...EZPTExerciseList]}
                searchFields={[
                  "exercise.displayName",
                  /*
                "exercise.units",
                "exercise.primaryJointsWorked",
                "exercise.primaryMusclesWorked",
                "exercise.secondaryMusclesWorked",
                "exercise.ownerLabel",*/
                ]}
                updateArray={(newArray) => setFilteredList([...newArray])}
              />
            </div>
          </div>
          <NewExerciseModal
            trainerId={state.trainer.id}
            show={exerciseModalOpen}
            setClose={closeModals}
            exercise={exerciseToEdit}
          />
          <VideoModal
            trainerId={state.trainer.id}
            show={videoModalOpen}
            setClose={closeModals}
            exercise={exerciseToEdit}
          />
          <div className="available-exercises-full">
            {filteredList.map((exerciseSet, index) => (
              <AvailableExercise
                key={`${exerciseSet.exercise.id}-available-${index}`}
                exerciseSet={exerciseSet}
                onSelect={selectRoutine}
                onClick={() => previewExercise(exerciseSet.exercise)}
                dragStart={dragStart}
                onDragEnd={() => {
                  setCurrentlyDragging(false);
                }}
                onDelete={() => verifyDeletion(exerciseSet.exercise)}
                onEdit={() => editExercise(exerciseSet.exercise)}
                openModal={() => {
                  setExerciseSaveData({
                    ...exerciseSaveData,
                    exerciseSet: exerciseSet,
                    isNewExercise: true,
                  });
                }}
              />
            ))}
          </div>
          <AddExerciseModal
            show={exerciseSaveData.exerciseSet}
            onClose={closeModal}
            onSave={saveExerciseData}
            exerciseSaveData={exerciseSaveData}
          />
          <Modal
            show={exerciseToDelete}
            onClose={closeVerifyDeletionModal}
            useCloseButton={true}
          >
            {exerciseToDelete ? (
              <>
                <h3>Are you sure you want to delete this exercise?</h3>
                <p className="warning">This cannot be undone</p>
                <div className="delete-buttons">
                  <Button
                    value="cancel"
                    type="button"
                    onClick={closeVerifyDeletionModal}
                    format="transparent"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    format="warning"
                    onClick={deleteExercise}
                  >
                    Delete
                  </Button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Modal>
          <ScheduleRoutine
            show={scheduleModalOpen}
            onClose={closeScheduleModal}
            setCurrentRoutine={handleRoutineSchedule}
            routine={currentRoutine}
            patient={currentPatient}
            trainerId={state.trainer.id}
            // onCreateRoutine={onCreateRoutine}
            onSetNoScheduleError={setNoScheduleError}
            onSetRoutineSchedule={setRoutineSchedule}
          />
        </div>
      </SideBar>
    </AppLogout>
  );

  function dragStart(e, exerciseSet, initialIndex) {
    setCurrentlyDragging(true);
    e.dataTransfer.setData(
      "exercise",
      JSON.stringify({ exerciseSet: exerciseSet, index: initialIndex })
    );
  }

  function onDrop(e) {
    setCurrentlyDragging(false);
    const transferData = JSON.parse(e.dataTransfer.getData("exercise"));
    const previndex = transferData.index;
    const exerciseSetToInsert = Object.assign(
      new ExerciseSet(),
      transferData.exerciseSet,
      {
        exercise: Object.assign(
          new Exercise(),
          transferData.exerciseSet.exercise
        ),
      }
    );
    const newIndex = Number(e.target.getAttribute("data-index"));
    if (
      (newIndex === previndex || newIndex === previndex + 1) &&
      previndex !== -1
    ) {
      return;
    }
    let [...newSelectedList] = selectedList;
    if (previndex === -1) {
      let newExercise = Object.assign(new ExerciseSet(), {
        ...exerciseSetToInsert,
      });
      setExerciseSaveData({
        ...exerciseSaveData,
        exerciseSet: newExercise,
        isNewExercise: true,
        index: newIndex,
      });
    } else {
      // Reorganize the list as it should be
      if (newIndex < previndex) {
        newSelectedList.splice(previndex, 1); // remove the exerciseSet from previous location
        newSelectedList.splice(newIndex, 0, exerciseSetToInsert); //insert it here
      } else {
        newSelectedList.splice(previndex, 1);
        newSelectedList.splice(newIndex - 1, 0, exerciseSetToInsert);
      }
      setSelectedList(newSelectedList);
    }
  }

  /**
   * selectRoutine Function
   * Adds passed in ExerciseSet to the list of selected exercise sets
   * @param exerciseSet ExerciseSet to be added to selected list
   */
  function selectRoutine(exerciseSet) {
    setSelectedList([...selectedList, exerciseSet]);
  }

  /**
   * removeRoutine
   * Removes passed in ExerciseSet from list of selected exercise sets
   * @param {*} exerciseSet
   */
  function removeRoutine(exerciseSet) {
    const [...newList] = selectedList;
    const setIndex = newList.indexOf(exerciseSet);
    newList.splice(setIndex, 1);
    setSelectedList(newList);
  }
};
export default Exercises;
