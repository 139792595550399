import React from "react";
import { formatDate } from "../services/helpers";

function PatientInfo({
  dateOfBirth,
  gender="",
  height="",
  weight="" })
  {
  const formatHeight = (text) => {
    console.log("gender:"+gender);
    console.log("height:"+height);
    console.log("weight:"+weight);
    return text ? text.split(" ").join("") : "N/A";
  };

  return (
    <ul className="patient-info__container">
      <li className="patient-info__item">
        <span className="patient-info__header">DOB:</span>
        {dateOfBirth && <span>{formatDate(dateOfBirth)}</span>}
      </li>
      <li className="patient-info__item">
        <span className="patient-info__header">Gender:</span>
        <span>{gender ? gender : "N/A"}</span>
      </li>
      <li className="patient-info__item">
        <span className="patient-info__header">Height:</span>
        <span>{formatHeight(height)}</span>
      </li>
      <li className="patient-info__item">
        <span className="patient-info__header">Weight:</span>
        <span>{weight ? weight : "N/A"}</span>
      </li>
    </ul>
  );
};
export default PatientInfo;
