import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

/**
 * ProfilePhoto Component
 * @param imgURL URL of image to be displayed if available
 * @param className Additional classNames to be applied
 * @returns circular photo with either a placeholder svg or the lazy-loaded image passed in
 */
const ProfilePhoto = ({ imgURL = "", className = "" }) => {
  return (
    <>
      {
        // If there is an image url try to load that instead
        imgURL != "" ? (
          <LazyLoadImage
            alt="Profile photo"
            src={imgURL}
            wrapperClassName={`profile-photo ${className}`}
            effect="opacity"
          />
        ) : (
          <svg
            className={`profile-photo profile-photo-default ${className}`}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-2-inside-1_8_839" className="white-fill">
              <path
                className="even-odd"
                d="M6 39.875L6 39C6 33.015 17.9925 30 24 30C30.0075 30 42 33.015 42 39V39.8749C37.6023 44.8575 31.1682 48 24 48C16.8318 48 10.3977 44.8575 6 39.875ZM24 10C19.0275 10 15 14.0275 15 19C15 23.9725 19.0275 28 24 28C28.9725 28 33 23.9725 33 19C33 14.0275 28.9725 10 24 10Z"
              />
            </mask>
            <path
              className="light-fill even-odd"
              d="M6 39.875L6 39C6 33.015 17.9925 30 24 30C30.0075 30 42 33.015 42 39V39.8749C37.6023 44.8575 31.1682 48 24 48C16.8318 48 10.3977 44.8575 6 39.875ZM24 10C19.0275 10 15 14.0275 15 19C15 23.9725 19.0275 28 24 28C28.9725 28 33 23.9725 33 19C33 14.0275 28.9725 10 24 10Z"
            />
            <path
              className="med-fill"
              d="M6 39.875H4V40.6313L4.50052 41.1984L6 39.875ZM6 39H8H6ZM42 39.8749L43.4995 41.1984L44 40.6313V39.8749H42ZM8 39.875V39H4V39.875H8ZM8 39C8 38.2383 8.36568 37.4231 9.32415 36.5385C10.2962 35.6414 11.7385 34.8142 13.4909 34.1122C16.9991 32.7068 21.2463 32 24 32V28C20.7462 28 15.9934 28.8007 12.0034 30.3991C10.0069 31.1989 8.07551 32.2477 6.61124 33.5991C5.13338 34.9631 4 36.7692 4 39H8ZM24 32C26.7537 32 31.0009 32.7068 34.5091 34.1122C36.2615 34.8142 37.7038 35.6414 38.6758 36.5385C39.6343 37.4231 40 38.2383 40 39H44C44 36.7692 42.8666 34.9631 41.3888 33.5991C39.9245 32.2477 37.9931 31.1989 35.9966 30.3991C32.0066 28.8007 27.2538 28 24 28V32ZM40 39V39.8749H44V39H40ZM40.5005 38.5515C36.4664 43.1221 30.5705 46 24 46V50C31.7658 50 38.7383 46.5928 43.4995 41.1984L40.5005 38.5515ZM24 46C17.4295 46 11.5336 43.1221 7.49948 38.5515L4.50052 41.1984C9.26173 46.5928 16.2342 50 24 50V46ZM17 19C17 15.1321 20.1321 12 24 12V8C17.9229 8 13 12.9229 13 19H17ZM24 26C20.1321 26 17 22.8679 17 19H13C13 25.0771 17.9229 30 24 30V26ZM31 19C31 22.8679 27.8679 26 24 26V30C30.0771 30 35 25.0771 35 19H31ZM24 12C27.8679 12 31 15.1321 31 19H35C35 12.9229 30.0771 8 24 8V12Z"
              mask="url(#path-2-inside-1_8_839)"
            />
          </svg>
        )
      }
    </>
  );
};

export default ProfilePhoto;
