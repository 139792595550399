import { doc, Timestamp, updateDoc, setDoc, getDoc } from "firebase/firestore";
import Firebase from "../services/firebase";
import BasicFirebaseItem from "./basicFirebaseItem";

class AssignedRoutine extends BasicFirebaseItem {
  constructor(
    id = "",
    due = {},
    records = [],
    active = true,
    createdAt = new Timestamp(),
    routineReference = ""
  ) {
    super(id);
    this.due = due;
    this.records = records;
    this.active = active;
    this.createdAt = createdAt;
    this.routineReference = routineReference;
  }

  static async getAssignedRoutine(patientId, routineId) {
    try {
      const assignedRoutine = await getDoc(
        doc(Firebase.db, `users/${patientId}/tasks/${routineId}`)
      );
      return assignedRoutine.data();
    } catch (error) {
      console.error(
        "Could not find the assigned routine document reference: ",
        error,
      );
      console.error(patientId)
      console.error(routineId)
    }
  }

  static async saveAssignedRoutine(
    routine,
    patient,
    assignedRoutine,
    trainerId
  ) {
    try {
      if (routine?.id !== "") {
        await setDoc(
          doc(Firebase.db, `users/${patient.id}/tasks`, routine.id),
					{ ...assignedRoutine, id: routine.id, taskType: "assignedRoutine" }
        );
        const newDoc = await getDoc(
          doc(Firebase.db, `users/${patient.id}/tasks/${routine.id}`)
        );
        return newDoc.data();
      } else {
        // const { id, ...toBeSaved } = assignedRoutine;
        try {
          const updated = await updateDoc(
            doc(Firebase.db, `users/${patient.id}/tasks`),
            { assignedRoutine }
          );
          return updated;
        } catch (error) {
          console.error("cannot update routine: ", error);
        }
      }
    } catch (err) {
      console.error(
        "There was a problem saving the routine:",
        this,
        routine,
        patient,
        err
      );
      throw err;
    }
  }
}

export default AssignedRoutine;
