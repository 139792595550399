export function capitalizeFirstLetter(inputString) {
  try {
    return `${inputString[0].toUpperCase()}${inputString.slice(1)}`;
  } catch (err) {
    console.error(
      "There was a problem formatting the string:",
      inputString,
      err
    );
  }
}

export function capitalizeAllFirstLetters(inputString) {
  try {
    let words = inputString.trim().split(" ");
    return words
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(" ");
  } catch (err) {
    console.error(
      "There was a problem formatting the string:",
      inputString,
      err
    );
  }
}

export const formatDate = (date) => {
  const birthday = date.toDate();
  const year = birthday.getFullYear().toString().slice(-2);
  // why is getMonth zero-indexed...? lol
  const month = birthday.getMonth() + 1;
  const day = birthday.getUTCDate();
  return date ? `${month}/${day}/${year}` : "N/A";
};

export const formatDateTime = (date) => {
  date = date.toDate();
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const trimText = (text) => {
  if (text.length > 20) {
    return `${text.slice(0, 20)}...`;
  } else return text;
};

export const getFirstAndLastDayOfWeek = () => {
  let wDate = new Date();
  let dDay = wDate.getDay() > 0 ? wDate.getDay() : 7;
  let first = wDate.getDate() - dDay;
  let firstDayWeek = new Date(wDate.setDate(first));
  let lastDayWeek = new Date(wDate.setDate(firstDayWeek.getDate() + 6));
  const start = firstDayWeek.toLocaleDateString().split("/");
  const end = lastDayWeek.toLocaleDateString().split("/");
  return [
    {
      month: start[0],
      day: start[1],
      year: start[2],
      dayOfTheWeek: firstDayWeek.getDay()
    },
    {
      month: end[0],
      day: end[1],
      year: end[2],
      dayOfTheWeek: lastDayWeek.getDay()
    },
  ];
};

export const minutesDiff = (date1, date2) => {
  return (date2 - date1) / 60000;
}