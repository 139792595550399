import { collection, setDoc, doc, getDoc } from "firebase/firestore";
import Firebase from "../services/firebase";

class Settings {
  constructor(
    notifications = {
      messages: {
        email: false,
        text: false,
      },
      newPatients: {
        email: false,
        text: false,
      },
    }
  ) {
    this.notifications = notifications;
  }

  static async getSettings(trainerId) {
    try {
      const settings = await getDoc(
        doc(Firebase.db, `trainers/${trainerId}/settings/${trainerId}`)
      );
      return settings.data();
    } catch (error) {
      console.error("could not retrieve settings: ", error);
    }
  }

  static async createSettings(trainerId, data = {}) {
    try {
      const settings = collection(
        Firebase.db,
        `trainers/${trainerId}/settings`
      );
      console.log("settings: ", settings);

      try {
        let newSettings = new Settings();
        newSettings = { ...newSettings, ...data };
        console.log(newSettings);

        await setDoc(
          doc(Firebase.db, `trainers/${trainerId}/settings`, trainerId),
          data
        );
        const newData = await getDoc(
          doc(Firebase.db, `trainers/${trainerId}/settings/${trainerId}`)
        );
        console.log(newData.data());
        return newData.data();
      } catch (error) {
        console.error("There was an error saving the settings: ", error);
      }
    } catch (error) {
      console.error("There was an error creating the collection.", error);
    }
  }
}

export default Settings;
