import React, { Component } from "react";
import ExerciseBase from "./ExerciseBase";

/**
 * This component creates an available exercise that:
 * adds a handle to delete the exercise,
 * allows you to update sets/reps selected exercise
 * @param exerciseSet a generated exercise set with filled sets/reps
 */
export default class SelectedExercise extends Component {
  static defaultProps = {
    exerciseSet: {},
    dragStart: () => {},
    onDragEnd: () => {},
    openModal: () => {},
    index: -1,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="selected-exercise-wrapper">
        <div
          className="handle"
          onClick={() => this.props.onRemove(this.props.exerciseSet)}
        >
          <span className="material-icons-outlined">delete</span>
        </div>
        <ExerciseBase
          exerciseSet={this.props.exerciseSet}
          onClick={this.props.openModal}
          onDragStart={(e, exerciseSet, initialIndex) => {
            this.props.dragStart(e, exerciseSet, initialIndex);
          }}
          onDragEnd={this.props.onDragEnd}
          index={this.props.index}
        />
      </div>
    );
  }
}
