import React, { createContext, useReducer } from "react";
import Trainer from "../../models/trainer";
import { firebaseReducer, trainerReducer } from "./reducer";

class INITIAL_STATE {
  trainer = new Trainer();
  firebaseUser = {};
}

// Sets up inital state for trainer and firebase user data
const StateContext = createContext({
  state: INITIAL_STATE,
  dispatch: () => null,
});

// Sets up a way to remove all of the data on logout
const stateReducer = ({ trainer, firebaseUser }, action) => {
  switch (action.type) {
    case "REMOVE_ALL_DATA":
      console.log("Clearing state");
      return new INITIAL_STATE();
    default:
      return {
        trainer: trainerReducer(trainer, action),
        firebaseUser: firebaseReducer(firebaseUser, action),
      };
  }
};

// Actual provider that adds the state to be accessible anywhere
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, new INITIAL_STATE());

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };
