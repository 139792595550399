import React, { useEffect, useContext } from "react";
import LeftArrowSVG from "../svg/LeftArrowSVG";
import Cal, { getCalApi } from "@calcom/embed-react";
import Appointment from "../../models/appointment";
import { StateContext } from "../../contexts/State";

const ScheduleAppointments = ({
  setShowCalendar,
  setCurrentPatient,
  showCalendar,
  currentPatient,
}) => {        
  const { state, dispatch } = useContext(StateContext);
  useEffect(() => {
    const initializeCal = async () => {
      const Cal = await getCalApi();
      Cal("on", {
        action: "bookingSuccessful",
        callback: handleBookingSuccessful
      });
    };
    
    initializeCal();
  
    return () => {
      const removeCal = async () => {
        const Cal = await getCalApi();
        console.log(Cal,"Cal>>>>>>");
        
        Cal("off", {
          action: "bookingSuccessful",
          callback: handleBookingSuccessful
        });
      };
      
      removeCal();
    };
  }, []);
  // useEffect(() => {
  //   (async function () {
  //     const Cal = await getCalApi();
  //     Cal("on", {
  //       action: "bookingSuccessful",
  //       callback: async (e) => {
  //         console.log("I am booking successfull");
          
  //         if (e.detail.data.booking.status === "CANCELLED") {
  //           return false
  //         }
  //         const bookingId = e.detail.data.booking.id;
  //         const status = e.detail.data.booking.status;
  //         const title = e.detail.data.booking.title;
  //         const description = e.detail.data.booking.description;
  //         const startTime = e.detail.data.booking.startTime;
  //         const endTime = e.detail.data.booking.endTime;
  //         const meetingLink = e.detail.data.booking.metadata?.videoCallUrl;
  //         const schedule = new Appointment();
  //         const checkDuplicate = await schedule.checkDuplicateBooking(state.trainer.id, bookingId);
  //         if(!checkDuplicate){
  //           await schedule.save(
  //             state.trainer,
  //             currentPatient,
  //             bookingId,
  //             title,
  //             description,
  //             startTime,
  //             endTime,
  //             meetingLink
  //           );
  //         }
  //       }
  //     });
  //   })();
  // }, []);
  const handleBookingSuccessful = async (e) => {
    if (e.detail.data.booking.status === "CANCELLED") {
      return false;
    }
  
    const bookingId = e.detail.data.booking.id;
    const schedule = new Appointment();

    const checkDuplicate = await schedule.checkDuplicateBooking(state.trainer.id, bookingId);
    console.log(typeof checkDuplicate,checkDuplicate,"checkDuplicate>>>>");
    
    if (!checkDuplicate) {
      const status = e.detail.data.booking.status;
      const title = e.detail.data.booking.title;
      const description = e.detail.data.booking.description;
      const startTime = e.detail.data.booking.startTime;
      const endTime = e.detail.data.booking.endTime;
      const meetingLink = e.detail.data.booking.metadata?.videoCallUrl;
      await schedule.save(
        state.trainer,
        currentPatient,
        bookingId,
        title,
        description,
        startTime,
        endTime,
        meetingLink
      );
    }
  };
  return (
    <div>
      <button
        className="back-btn"
        onClick={() => {
          setShowCalendar(!showCalendar);
          setCurrentPatient({});
        }}
      >
        <LeftArrowSVG />
        Go Back
      </button>
      {/* Start acuite.com */}
      {/* <iframe
        title="schedule"
        src={`https://app.squarespacescheduling.com/schedule.php?owner=26607391${currentPatient.firstName
            ? "&firstName=" + currentPatient.firstName
            : ""
          }${currentPatient.lastName ? "&lastName=" + currentPatient.lastName : ""
          }${currentPatient.email ? "&email=" + currentPatient.email : ""}`}
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      ></script> */}
      {/* End acuite.com */} 

      {/* Start cal.com */}
      <Cal
        calLink={state?.trainer?.username}
        // calLink="shipra"
        config={{
          name: `${currentPatient.firstName} ${currentPatient.lastName}`,
          email: currentPatient.email,
          // notes: "Test Meeting",
          // guests: [currentPatient.email],
          // theme: "dark", // "dark" or "light" theme
        }}
      ></Cal>
      {/* End cal.com */}
    </div>
  );
};
export default ScheduleAppointments;
