import React, { useEffect, useContext, useState } from "react";
import LeftArrowSVG from "../svg/LeftArrowSVG";
import Cal, { getCalApi } from "@calcom/embed-react";
import Appointment from "../../models/appointment";
import { StateContext } from "../../contexts/State";
import Trainer from "../../models/trainer";
import TextInput from "../TextInput"
import Button from "../Button";
const ScheduleUsername = ({
  setShowCalendar,
  setCurrentPatient,
  showCalendar,
  currentPatient,
  setShowSchedule,
  setShowAppointments
}) => {
  const { state, dispatch } = useContext(StateContext);
  const [username, setUsername] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const handleInfoClick = () => {
    setShowInfo(!showInfo);
  };
  const handleSave = async (trainerId) => {
    if (username.trim() !== "") {
      setErrorMessage('');
      const trainer = await Trainer.updateUsername(trainerId, username.trim());
      dispatch({
        type: "STORE_TRAINER_DATA",
        payload: {
          ...trainer,
        },
      });
      if (trainer) {
        setShowAppointments(true)
      }
      //   setUsername("");
      // fetch(`https://app.cal.com/${username.trim()}`)
      //   .then(async (response) => {
      //     console.log(response, "res>>>>>>");
      //     if (response.status === 200) {
      //         const trainer = await Trainer.updateUsername(trainerId, username.trim());
      //         dispatch({
      //           type: "STORE_TRAINER_DATA",
      //           payload: {
      //             ...trainer,
      //           },
      //         });
      //         if (trainer) {
      //           setShowAppointments(true)
      //         }
      //         //   setUsername("");

      //     } else {
      //       setErrorMessage('Username not found.');
      //       console.error(`Request failed with status code ${response.status}`);
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    } else {
      setErrorMessage('Please enter a value.');
    }

  };
  const onchangeUsername = async (e) => {
    await setUsername(e.target.value)
    if (e.target.value.trim() !== "") {
      setErrorMessage("")
    } else {
      setErrorMessage('Please enter a value.');
    }
  }
  return (
    <div>
      <button
        className="back-btn"
        onClick={() => {
          setShowSchedule(true)
        }}
      >
        <LeftArrowSVG />
        Go Back
      </button>
      <div style={{ display: 'flex' }}>
        <div className="search-container">
          {/* <button
              style={{
                position: "relative",
                top: "43%",
                left: "27%",
                transform: "translateY(-50%)",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={handleInfoClick}
            >
             <span className="material-icons-outlined">info_outline</span>
            </button>
            <div style={{ position: "relative" }}>
            {showInfo && (
              <div
                style={{
                  width:"300px",
                  position: "absolute",
                  top: "20px",
                  left: "81%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "5px",
                  padding: "10px",
                  zIndex: "1",
                }}
              > 
                <b>How to get the username from <a href="https://cal.com/signup">cal.com</a>?</b>
                <p>1. Create a new account on cal.com.</p>
                <p>2. After creating an account, log in with the same credential.</p>
                <p>3. Once you are logged in, you will be redirected to your account dashboard.</p>
                <p>4. Click on the "Settings" link in your dashboard menu to access your account information.</p>
                <p>5. Your username should be listed in your account details under "Profile”.</p>
                <p>6. Enter that username in the above input box</p>
              </div>
            )}
          </div> */}
          <TextInput
            label={"Add Username"}
            placeholder={"Username"}
            value={username}
            type="text"
            onChange={(e) => onchangeUsername(e)}
            hasError={errorMessage}
            errorMessage={errorMessage}
          />
        </div>
        <div style={{ marginTop: 55 }}>
          <Button
            className="view-schedule-btn"
            type="button"
            format="minimal fancy"
            onClick={() => handleSave(state.trainer.id)}
          >
            Save
        </Button>
        </div>
      </div>
      <div
        style={{
          width: "680px",
          backgroundColor: "#ffffff",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          borderRadius: "5px",
          padding: "10px",
          zIndex: "1",
          marginTop: "40px"
        }}
      >
        <b style={{fontSize:20}}>How to get the username from <a href="https://cal.com/signup">cal.com</a>?</b>
        <p>1. Create a new account on cal.com.</p>
        <p>2. After creating an account, log in with the same credential.</p>
        <p>3. Once you are logged in, you will be redirected to your account dashboard.</p>
        <p>4. Click on the "Settings" link in your dashboard menu to access your account information.</p>
        <p>5. Your username should be listed in your account details under "Profile”.</p>
        <p>6. Enter that username in the above input box.</p>
      </div>
    </div>
  );
};
export default ScheduleUsername;
