import Button from "./Button";
import { Link } from "react-router-dom";
import { titleCase, toDisplayName } from "../utils/text"

const AssessmentItem = ({ id, assessment, patient }) => {
	const rtf1 = new Intl.RelativeTimeFormat("en", { numeric: 'auto', style: "short" });

  return (
    <Link
      to={`/patient/${patient.id || patient.uid}/assessments/${id}`}
      className="assessment-item feedback-request-item"
    >
      <div className="material-icons-outlined">assessment</div>

      <h4>
				{ toDisplayName(assessment.exercise) }
      </h4>

      <p>
        {titleCase(rtf1.format(
          Math.trunc((assessment.completedAt.toDate() - new Date()) / 8.64e7),
          "day"
        ))}
      </p>
    </Link>
  );
};

export default AssessmentItem;
