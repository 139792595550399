import React, { useEffect, useState, useCallback } from "react";
import TrainerNotes from "../../models/trainerNotes";
import Modal from "../Modal";
import Button from "../Button";
import TextBox from "../TextBox";

const RecentNotes = ({ patientId = "" }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);

  const onGetNotes = useCallback(async () => {
    if (patientId) {
      const res = await TrainerNotes.getTrainerNotes(patientId);
      setNotes(res);
    }
  }, [patientId]);

  useEffect(() => {
    onGetNotes();
  }, [onGetNotes]);

  const closeModal = () => {
    setShowAddNoteModal(false);
    setNewNote("");
  };

  const submitNewNote = async (e) => {
    try {
      const data = await TrainerNotes.createTrainerNote(patientId, newNote);
      setNotes((prev) => [...prev, { note: newNote }]);
      setShowAddNoteModal(false);
      setNewNote("");
      return data;
    } catch (error) {
      console.error("There was an error creating this note: ", error);
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      submitNewNote();
    }
  };

  return (
    <div className="recent-notes">
      <div className="recent-notes__header">
        <h4>Recent Notes</h4>
        <span
          className="material-icons-outlined"
          onClick={() => {
            setShowAddNoteModal(true);
          }}
        >
          add_circle_outline
        </span>
      </div>
      <div className="note-container">
        {notes.length > 0 ? (
          notes.map((note, index) => (
            <span key={index} className="note">
              {note.note}
            </span>
          ))
        ) : (
          <span className="no-notes">No notes for this patient.</span>
        )}
      </div>
      <Modal
        className="add-trainerNotes__modal"
        show={showAddNoteModal}
        onClose={closeModal}
        useCloseButton={true}
      >
        <TextBox
          onKeyDown={onEnterPress}
          id="message-input"
          placeholder="Add a new note for this patient here..."
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          value={newNote}
          label="Notes"
        />
        <div className="trainerNotes__buttons-container">
          <Button
            onClick={closeModal}
            className="close-note__btn"
            format="transparent minimal"
          >
            Cancel
          </Button>
          <Button
            className="add-note__btn"
            format="minimal"
            type="submit"
            onClick={submitNewNote}
          >
            Add Note
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default RecentNotes;
