import React, { useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import Exercise from "../models/exercise";
import Routine from "../models/routine";
import Button from "./Button";

/**
 * RoutineCard Component
 * @param routine Routine for which to display information
 * @param className Additional classes to be applied
 * @returns Routine card that shows an image, the routine name, and a list of exercises
 */
function RoutineCard({
  routine = new Routine(),
  onSelect = () => {},
  onEdit = () => {},
  onDelete = () => {},
  selectable = false,
  className = "",
}) {

  // const [routineImgURL, setRoutineImgURL] = useState("");
  
	const [showButtons, setShowButtons] = useState(false);

	console.log(routine)

  // Iterates through the exercises and uses the first image it finds as it's display photo
  // **** Not using exercise images for now **** //
  /////////////////////////////////////////////////
  // useEffect(() => {
  //   const loadImage = async () => {
  //     let imgURL = "";
  //     for (let i = 0; i < routine.exercises.length; i++) {
  //       let newImgUrl = "";
  //       if (!routine.exercises[i].exercise.imgURL) {
  //         newImgUrl = await Exercise.getImage(
  //           routine.exercises[i].exercise.name
  //         );
  //       } else newImgUrl = routine.exercises[i].exercise.imgURL;
  //       if (newImgUrl) {
  //         imgURL = newImgUrl;
  //         break;
  //       }
  //     }
  //     setRoutineImgURL(imgURL);
  //   };
  //   loadImage();
  // }, [routine]);

  return (
    <div
      className={`routine-card ${className}`}
      onClick={() => setShowButtons(true)}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <div className="routine-data">
        <h4>{routine.name}</h4>
        <div className="routine-exercises">
          {routine.exercises?.map((exerciseSet, index) => (
            <div
              className="routine-exercise"
              key={`${routine.name}-${index}-${exerciseSet.exercise.id}`}
            >
						{ exerciseSet.exercise.displayName }
            </div>
          ))}
        </div>
      </div>
      {showButtons ? (
        <>
          <div className="routine-cover"></div>
          <div className="routine-buttons">
            <div className="top-row">
              <span className="material-icons-outlined" onClick={onEdit}>
                drive_file_rename_outlined
              </span>
              <span className="material-icons-outlined" onClick={onDelete}>
                delete
              </span>
            </div>
            {selectable ? (
              <Button type="button" format="transparent" onClick={onSelect}>
                Select
              </Button>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RoutineCard;
