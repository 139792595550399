class ListenerManager {
  constructor(listeners = []) {
    this.listeners = listeners;
  }
  addListener(listener) {
    this.listeners.push(listener);
  }
  removeListeners() {
    try {
      this.listeners.forEach((listener) => {
        console.log("Removing listener", listener);
        listener();
      });
      this.listeners = [];
    } catch (err) {
      console.error("There was a problem closing the listener", err);
    }
  }
}

export default ListenerManager;
