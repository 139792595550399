import React, { useContext, useEffect, useState } from "react";
import AppLogout from "../components/AppLogout";
import SideBar from "../components/layouts/SideBar";
import SearchBar from "../components/SearchBar";
import TextSVGContainer from "../components/svg/TextSVGContainer";
import { StateContext } from "../contexts/State";
import PatientCard from "../components/PatientCard";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Trainer from "../models/trainer";
import Firebase from "../services/firebase";
import Patient from "../models/patient";
import Loading from "../components/Loading";
import TextInput from "../components/TextInput";
import { capitalizeFirstLetter } from "../services/helpers";
import { getDoc, doc } from "firebase/firestore";

import TypeformModal from "../components/Typeform/TypeformModal";
import TypeformButtons from "../components/Typeform/TypeformButtons";
import GoogleSheets from "../services/googleSheets";
import EditPatientButton from "../components/EditPatientButtons";
import AddExerciseModal from "../components/AddExerciseModal";
import CreatePatientModal from "../components/CreatePatientModal";

import NewPatient from "../models/newPatient";
/**
 * Dashboard page
 * Shows a searchable list of patients tied to the trainer account
 * as well as some trainer data along the top of page
 */
const Dashboard = () => {
  const [filteredList, setFilteredList] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState("");
  const [patientEmail, setPatientEmail] = useState("");
  const [sortMethod, setSortMethod] = useState("");
  let [ascendingSort, setAscendingSort] = useState(true);
  let [flipper, setFlipper] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(StateContext);
  const [addResult, setAddResult] = useState("");
  const [editPatientModalOpen, setEditPatientModalOpen] = useState(false);
  const [editPatientConfirmation, setEditPatientConfirmation] = useState(false);
  const defaultNewPatientSaveData = {
    firstName: "",
    lastName: "",
    email: ""
  };
  const [newPatientSaveData, setNewPatientSaveData] = useState(defaultNewPatientSaveData);

  const saveNewPatientData = (firstName, lastName, email) => {
    newPatientSaveData.firstName = firstName;
    newPatientSaveData.lastName = lastName;
    newPatientSaveData.email = email;
    setEditPatientModalOpen(false);
    setEditPatientConfirmation(true);
    const trainerId = state.trainer.id;
    const ptCode = state.trainer.ptCode;
    const password = "password";
    const uid = NewPatient.createNewPatient(newPatientSaveData.email, password, newPatientSaveData.firstName,newPatientSaveData.lastName, trainerId, ptCode);
  };

  const closeTypeformModals = () => {
    setEditPatientModalOpen(false);
  };

  const closePatientConfirmationModals = () => {
    setEditPatientConfirmation(false);
    window.location.reload(false);
  };

  const changePatientEmail = (e) => {
    setPatientEmail(e.target.value);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      // using a timeout for now so the sort is given enough time to load in patients
      setTimeout(async () => {
        let patients = state.trainer.patients;
        const updatedPatients = [];

        for (const i in patients) {
          const patientId = patients[i].id;
          console.log("Patient ID:", patientId);

          const patientDocRef = doc(Firebase.db, "patients", patientId);
          const patientDocSnapshot = await getDoc(patientDocRef);
          console.log("Patient DocRef:", patientDocSnapshot);
          console.log("Document exists:", patientDocSnapshot.exists());


          if (patients[i].uid) {
            const updatedPatient = {
              ...patients[i],
              firstName: capitalizeFirstLetter(patients[i].firstName || ""),
              lastName: capitalizeFirstLetter(patients[i].lastName || ""),
              adherence: -1,
              streak: 0,
            };
            updatedPatients.push(updatedPatient);
          } else {
            // Patient document doesn't exist, skip adding to the list or handle it accordingly
            continue;
          }
        }

        setFilteredList(updatedPatients);
        setLoading(false);
      }, 500);
    })();
  }, [state.trainer.patients]);

  useEffect(() => {
    let sortAlgorithm;
    let direction = ascendingSort ? 1 : -1;

    switch (sortMethod) {
      default: // newest
        sortAlgorithm = (a) => -1;
        break;

      case "first-name":
        sortAlgorithm = (a, b) => {
          if (a.firstName > b.firstName) {
            return 1 * direction;
          }
          return -1 * direction;
        };
        break;

      case "last-name":
        sortAlgorithm = (a, b) => {
          if (a.lastName > b.lastName) {
            return 1 * direction;
          }
          return -1 * direction;
        };
        break;

      // case "adherence":
      //   sortAlgorithm = (a, b) => {
      //     if (a.adherence < b.adherence) {
      //       return 1 * direction;
      //     }
      //     return -1 * direction;
      //   };
      //   break;

      // case "streak":
      //   sortAlgorithm = (a, b) => {
      //     if (a.streak < b.streak) {
      //       return 1 * direction;
      //     }
      //     return -1 * direction;
      //   };
      //   break;
    }

    setFilteredList(filteredList.sort(sortAlgorithm));
    setFlipper(!flipper); // For some reason just setting filtered list isn't triggering a rerender
  }, [sortMethod, ascendingSort, filteredList]);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const closeAddModal = () => {
    setShowAddModal(false);
    setAddResult("");
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  // deletes patient reference from trainer, then updates context so UI matches changes to db.
  const addPatient = async (trainerId, email) => {
    const patient = await Patient.getFromStudyPool(email);
    if (!patient) {
      setAddResult("Patient was not found, please try again.");
      return;
    }
    setAddResult("");
    const trainer = await Trainer.addPatient(trainerId, patient.id);
    dispatch({
      type: "STORE_TRAINER_DATA",
      payload: {
        ...trainer,
      },
    });
    setShowAddModal(false);
  };

  // deletes patient reference from trainer, then updates context so UI matches changes to db.
  const deletePatient = async (trainerId, patientId) => {
    const trainer = await Trainer.deletePatient(trainerId, patientId);
    dispatch({
      type: "STORE_TRAINER_DATA",
      payload: {
        ...trainer,
      },
    });
    setShowDeleteModal(false);
  };
  return (
    <AppLogout>
      <SideBar>
        <div className="dashboard-content">
          <div className="header">
            <h2>Welcome back, {state.trainer.firstName}</h2>
            <p className="subtext">
              <span>
                <TextSVGContainer>
                  <span className="material-icons-outlined md-18">home</span>
                </TextSVGContainer>
                {` ${state.trainer.clinicName}`}
              </span>
              <span>
                <TextSVGContainer>
                  <span className="material-icons-outlined md-18">
                    pin_drop
                  </span>
                </TextSVGContainer>
                {` ${state.trainer.location}`}
              </span>
            </p>
          </div>
          <div className="patients-header-container">
            <h3>Patients ({filteredList.length})</h3>
            <EditPatientButton
              setEditPatientModalOpen={setEditPatientModalOpen}
            />
            {/* Need more customization than select component can provide */}
            <div className={`select-component`}>
              <label>
                <div className="select-container">
                  <span className="material-icons-outlined md-18">sort</span>
                  <select
                    value={sortMethod}
                    onChange={(e) => setSortMethod(e.target.value)}
                  >
                    {/* <option value="newest">Newest</option> */}
                    <option value="newest">Newest</option>
                    <option value="first-name">First Name</option>
                    <option value="last-name">Last Name</option>
                    {/* <option value="adherence">Adherence</option>
                  <option value="streak">Streak</option> */}
                  </select>
                  <span
                    className="material-icons-outlined md-18"
                    onClick={() => {
                      setAscendingSort(!ascendingSort);
                    }}
                  >
                    {ascendingSort ? "arrow_upward" : "arrow_downward"}
                  </span>
                </div>
              </label>
            </div>
            <SearchBar
              inputArray={state.trainer.patients}
              searchFields={["firstName", "lastName", "email"]}
              updateArray={(newArray) => setFilteredList([...newArray])}
            />
          </div>
          {loading ? (
            <Loading className={"center-spinner"} />
          ) : (
            <div className="user-list">
              {filteredList.map((patient) => (
                <PatientCard
                  key={patient.email}
                  patient={patient}
                  onSetShowDeleteModal={setShowDeleteModal}
                  onSetPatientToDelete={setPatientToDelete}
                >
                  {patient.email}
                </PatientCard>
              ))}
            </div>
          )}
        </div>

        <Modal
          show={editPatientModalOpen}
          onClose={closeTypeformModals}
          useCloseButton={true}
        >
        <CreatePatientModal
          show={editPatientModalOpen}
          onClose={closeTypeformModals}
          onSave={saveNewPatientData}
          newPatient={newPatientSaveData}
        />
        </Modal>
        <Modal
          show={editPatientConfirmation}
          useCloseButton={true}
          onClose={closePatientConfirmationModals}
        >
          <h2>
            Congratulations! You have succesfully created a new patient!
          </h2>
          <div className="container">
            <div className="vertical-center">
              <Button
                className="confirm-delete__patient"
                onClick={closePatientConfirmationModals}
              >Ok</Button>
            </div>
          </div>
        </Modal>

        <Modal
          show={showAddModal}
          useCloseButton={true}
          onClose={closeDeleteModal}
          className="add-patient__modal"
        >
          <h2>Add Patient</h2>

          <p>
            Add a patient using the email address they entered when enrolling in
            the study.
          </p>

          <TextInput
            label="Email Address"
            value={patientEmail ? patientEmail : ""}
            type="text"
            onChange={changePatientEmail}
          />
          <p className="error">{addResult}</p>
          <div className="delete-patient__buttons-container">
            <Button onClick={closeAddModal}>Cancel</Button>
            <Button
              onClick={() => {
                addPatient(state.trainer.id, patientEmail);
              }}
            >
              Add
            </Button>
          </div>
        </Modal>

        <Modal
          show={showDeleteModal}
          useCloseButton={true}
          onClose={closeDeleteModal}
          className="delete-patient__modal"
        >
          <h2>
            Are you sure you want to remove this patient? This action cannot be
            reversed.
          </h2>
          <div className="delete-patient__buttons-container">
            <Button onClick={closeDeleteModal}>Cancel</Button>
            <Button
              className="confirm-delete__patient"
              onClick={() => {
                deletePatient(state.trainer.id, patientToDelete);
              }}
            >
              Yes, I'm sure
            </Button>
          </div>
        </Modal>
      </SideBar>
    </AppLogout>
  );
};
export default Dashboard;
