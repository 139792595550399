import React, { useState } from "react";
import Message from "../models/message";
import Trainer from "../models/trainer";
import Patient from "../models/patient";
import MessageList from "./MessageList";
import MessageFooter from "./MessageFooter";
import ProfilePhoto from "./ProfilePhoto";
import { capitalizeFirstLetter } from "../services/helpers";
import AiMessageList from "./AiMessageList";

/**
 * RoutineCard Component
 * @param routine Routine for which to display information
 * @param className Additional classes to be applied
 * @returns Routine card that shows an image, the routine name, and a list of exercises
 */
function MessageCenter({
  trainer,
  patient,
  routines = [],
  className = "",
  chatPage = false,
  recentMessages = []
}) {

  const [pickChat, setPickChat] = useState("AI Health Coach")
  const chatOptions = ['AI Health Coach', 'Health Care Provider']

  return (
    <div className={`message-center ${className}`}>
      {chatPage && patient && patient.firstName ? (
        <div className="message-center-header chat-page__patient-header">
          <ProfilePhoto
            imgURL={patient.profilePhotoURL}
            className="chat-patient__picture"
          />
          <div>
            Conversation with{" "}
            <span className="patient-name">
              {patient.firstName
                ? capitalizeFirstLetter(patient.firstName)
                : ""}{" "}
              {patient.lastName ? capitalizeFirstLetter(patient.lastName) : ""}
            </span>
          </div>
        </div>
      ) : (
        <>
          <h3 className="message-center-header">Messaging Center</h3>
        </>
      )}
      {!chatPage && <div className="message_options_pick">
        {chatOptions.map((opt, index) => (
          <span
            key={index}
            onClick={() => setPickChat(opt)}
            className={`${pickChat === opt ? "active" : ""}`}
          >
            {opt === "AI Health Coach" ? patient.firstName : opt}
          </span>
        ))}
      </div>}

      <div className="message-center-messages">
        {(trainer.id && patient.id) ? (
          pickChat === 'AI Health Coach' && !chatPage ?
            <AiMessageList trainerId={trainer.id} patientId={patient.id} />
            :
            <MessageList trainerId={trainer.id} patientId={patient.id} chatPage={chatPage} recentMessages={recentMessages} />
        ) : (
          <></>
        )}
      </div>
      {(pickChat === "Health Care Provider" || chatPage) && <MessageFooter trainer={trainer} patient={patient} routines={routines} />}
    </div>
  );
}

export default MessageCenter;
