import React, { Component } from "react";

/**
 * Button Component
 * Standardized button component to be used throughout the app
 * @param id Id to be applied to button element
 * @param className Additional class names to be applied to parent div
 * @param type Button type to be passed onto button element
 * @param disabled Boolean value determining whether button should be considered disabled or not
 * @param onClick Async function to be called when button is clicked.  Will call the success or error callback depending on success or failure
 * @param children Content to be put inside button element
 * @param successCallback Function to be called once the async onClick function is complete
 * @param errorCallback Funciton to be called if an error is throwin in the onclick function
 * @param format Visual format selection for button. Valid formats are: simple, transparent, fancy, and minimal
 * @param value Button value to be attached to button element
 */
class Button extends Component {
  static defaultProps = {
    id: "",
    className: "",
    type: "button",
    disabled: false,
    onClick: async () => {},
    children: null,
    successCallback: () => {},
    errorCallback: () => {},
    format: "simple" /**
      Available formats:
        simple
        transparent
        fancy
        minimal */,
    value: "submit",
    style: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      hidePassword: true,
      valid: !props.hasError,
      value: "",
    };
  }

  render() {
    return (
      <button
        id={this.props.id}
        className={`button-component ${this.props.format} ${
          this.props.className
        } ${this.props.disabled ? "disabled" : ""}`}
        type={this.props.type}
        value={this.props.value}
        disabled={this.props.disabled}
        style={this.props.style}
        onClick={
          this.props.disabled
            ? () => {}
            : async () => {
                // Handle callbacks whether function that is passed in is async or not
                try {
                  const data = await this.props.onClick();
                  await this.props.successCallback(data);
                } catch (err) {
                  console.log("There was a problem with the button", err);
                  await this.props.errorCallback(err);
                }
              }
        }
      >
        <p className="button-content">{this.props.children}</p>
      </button>
    );
  }
}

export default Button;
