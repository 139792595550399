import { React, useState } from "react";
import Message from "../models/message";
import Button from "./Button";
import Modal from "./Modal";

/**
 * A reusable Modal component
 * @param className classes that get passed to the modal
 * @param show a boolean for whether or not the modal is open
 * @param onClose a function to call when the element closes
 * @param children Content to be shown inside modal
 * @param useCloseButton Whether or not there should be a close button and if modal can be closed by clicking outside of it
 * @returns
 */
function UploadFileModal({
  className = "",
  show,
  onClose = () => {},
  useCloseButton = false,
  trainerId = "",
  patientId = "",
}) {
  const [targetFile, setTargetFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loadingText, setLoadingText] = useState("");

  const uploadFile = async () => {
    setLoadingText("Uploading File...");
    await Message.uploadAndSendFile(trainerId, patientId, targetFile, fileName);
    closeModal();
  };

  const changeFile = (e) => {
    try {
      console.log(e.target.files[0]);
      setTargetFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    } catch (err) {
      console.error("There was a problem changing the file", err);
    }
  };
  const closeModal = () => {
    setTargetFile(null);
    setFileName("");
    setLoadingText("");
    onClose();
  };

  return (
    <Modal
      show={show}
      onClose={closeModal}
      useCloseButton={useCloseButton}
      className={`upload-file-modal ${className}`}
    >
      {loadingText ? (
        <div className="loading">
          <span className="loading-text">{loadingText}</span>
        </div>
      ) : (
        <>
          <h3>File Upload</h3>
          <div className="upload-file-container">
            <div className="material-icons-outlined">upload_file</div>
            <label htmlFor="file-upload" className="upload-label">
              <span className="upload-label-main">
                Click to select a file to upload
              </span>
              <br />
              <span>or drag and drop your file here</span>
              <input
                id="file-upload"
                className="upload-input"
                type={"file"}
                accept=".pdf,.mp4,.jpg,.png"
                onChange={changeFile}
              />
            </label>
          </div>
          {targetFile ? (
            <>
              <label className="get-filename">
                What would to like to call this file?
                <br />
                <input
                  type={"text"}
                  className="file-name-input"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
              </label>
              <div></div>
            </>
          ) : (
            <></>
          )}
          <div className="modal-buttons file-upload-buttons">
            <Button type="button" format="transparent" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type="minimal"
              format="minimal"
              onClick={uploadFile}
              className="upload-button"
              disabled={!targetFile || !fileName}
            >
              Upload & Send
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default UploadFileModal;
