import { Component } from "react";

export default class SelectComponent extends Component {
  static defaultProps = {
    id: "",
    className: "",
    onChange: () => {},
    inputName: "",
    label: "",
    value: "",
    children: <></>,
  };
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={`select-component ${this.props.className}`}>
        <label>
          {this.props.label}
          <div className="select-container">
            <select value={this.props.value} name={this.props.inputName} onChange={this.props.onChange}>
              {this.props.children}
            </select>
          </div>
        </label>
      </div>
    );
  }
}
