import { React, useState } from "react";
import Message from "../models/message";
import ExerciseSet from "../models/exerciseSet";
import Button from "./Button";
import Modal from "./Modal";
import TextInput from "./TextInput";
import { useContext } from "react";
import { useEffect } from "react";
import { capitalizeFirstLetter } from "../services/helpers";
import SelectComponent from "./SelectComponent";
import TextBox from "./TextBox";
import NewPatient from "../models/newPatient";

/**
 * A reusable Modal component
 * @param className classes that get passed to the modal
 * @param show a boolean for whether or not the modal is open
 * @param onClose a function to call when the element closes
 * @param onSave a function to call when the 'Save' button is clicked
 * @param children Content to be shown inside modal
 * @param useCloseButton Whether or not there should be a close button and if modal can be closed by clicking outside of it
 * @returns
 */
function CreatePatientModal({
  className = "",
  show,
  onClose = () => {},
  useCloseButton = true,
  onSave = () => {},
  newPatient
}) {
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");

  useEffect(() => {
    setNewFirstName(newPatient.firstName);
    setNewLastName(newPatient.lastName);
    setNewEmail(newPatient.email);
  }, [newPatient,
      setNewFirstName,
      setNewLastName,
      setNewEmail]);

  const changeFirstName = (e) => {
    setNewFirstName(e.target.value);
  };

  const changeLastName = (e) => {
    setNewLastName(e.target.value);
  };

  const changeEmail = (e) => {
    setNewEmail(e.target.value);
  };

  const saveData = () => {
    onSave(newFirstName, newLastName, newEmail);
  };

  const closeModal = () => {
    onClose();
  };

  try {
    return (
      <Modal
        show={show}
        onClose={closeModal}
        useCloseButton={useCloseButton}
        className={`add-exercise-modal ${className}`}
      >
      <h2>
        Create new patient
      </h2>
        <TextInput
          label="First Name"
          placeholder="First name of new patient"
          defaultValue={newFirstName}
          type="text"
          min="1"
          onChange={changeFirstName}
        />
        <TextInput
          label="Last Name"
          placeholder="Last name of new patient"
          defaultValue={newLastName}
          type="text"
          min="1"
          onChange={changeLastName}
        />
        <TextInput
          label="Email"
          placeholder="Email of new patient"
          defaultValue={newEmail}
          type="text"
          min="1"
          onChange={changeEmail}
        />
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <div style={{display: "flex", gap: "2rem", marginTop: "2rem"}}>
          <Button
            type="minimal"
            format="minimal"
            onClick={saveData}
            className="save-button"
            style={{textAlign: "center", justifyContent: "center"  }}
          >Save
          </Button>
          </div>
        </div>
      </Modal>
    );
  } catch (err) {
    console.error("There was a problem rendering the CreatePatientModal", err);
  }
}

export default CreatePatientModal;
