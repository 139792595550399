import React, { useCallback, useEffect, useState } from "react";

const DaysWorkedOut = ({
  className = "",
  timePeriod = "month",
  events = [],
}) => {
  const [dates, setDates] = useState([]);
  const [dayOfTheWeek, setDayOfTheWeek] = useState([]);
  const getDaysInMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const isDateInCurrentWeek = (date) => {
    const today = new Date();
    const todayDate = today.getDate();
    const todayDay = today.getDay();

    // get first date of week
    const firstDayOfWeek = new Date(today.setDate(todayDate - todayDay));

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

    // if date is equal or within the first and last dates of the week
    // return date >= firstDayOfWeek && date <= lastDayOfWeek;
    if (date >= firstDayOfWeek && date <= lastDayOfWeek) {
      setDayOfTheWeek((prevDates) => [...prevDates, date.getDay()]);
    } else {
      return;
    }
  };

  const onToggleTimePeriod = useCallback(() => {
    const range = getDaysInMonth();
    const currMonth = new Date().getMonth() + 1;
    // MONTHLY
    if (timePeriod === "month") {
      let days = [];
      for (let i = 0; i < range; i++) {
        days.push(i + 1);
      }
      return days.map((day, index) => (
        <div
					className={`day-item`}
          key={index}
        >
          <span>{day}</span>
        </div>
      ));

      // WEEKLY
    } else if (timePeriod === "week") {
      let temp = [...new Set(dayOfTheWeek)];
      const daysOfTheWeek = ["S", "M", "T", "W", "T", "F", "S"];
      return daysOfTheWeek.map((day, index) => (
        <div
          className={`day-item ${temp.includes(index) ? "active" : ""}`}
          key={index}
        >
          <span>{day}</span>
        </div>
      ));

      // YEARLY
    } else if (timePeriod === "year") {
      const getKey = Object.keys(dates);
      const months = [
        { month: "Jan", number: "1" },
        { month: "Feb", number: "2" },
        { month: "Mar", number: "3" },
        { month: "Apr", number: "4" },
        { month: "May", number: "5" },
        { month: "Jun", number: "6" },
        { month: "Jul", number: "7" },
        { month: "Aug", number: "8" },
        { month: "Sep", number: "9" },
        { month: "Oct", number: "10" },
        { month: "Nov", number: "11" },
        { month: "Dec", number: "12" },
      ];

      return months.map((day, index) => (
        <div
          className={`day-item ${getKey.includes(day.number) ? "active" : ""}`}
          key={index}
        >
          <span className="year-text">{day.month}</span>
        </div>
      ));
    }
  }, [timePeriod, dates, dayOfTheWeek]);

  const handleDateFormat = useCallback(() => {
    if (events.length > 0 && dates.length === 0) {
      let date = {};
      events.forEach((event) => {
        let formatted = new Date(event.createdAt.toDate());
        isDateInCurrentWeek(formatted);
        if (formatted.getFullYear() === new Date().getFullYear()) {
          date[formatted.getMonth() + 1] = date[formatted.getMonth() + 1]
            ? date[formatted.getMonth() + 1]
            : {};
          date[formatted.getMonth() + 1][formatted.getDate()] = true;
        }
      });
      setDates(date);
    }
  }, [dates, events]);

  useEffect(() => {
    handleDateFormat();
  }, [handleDateFormat]);

  return (
    <div className="days-worked__container">
      <h4>Days Worked Out</h4>
      <div className="days-worked__calendar">{onToggleTimePeriod()}</div>
    </div>
  );
};
export default DaysWorkedOut;
