export function toDisplayName(text) {
	if (!text) return;
  const words = text.split('-');

  const titleCasedWords = words.map(word => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  return titleCasedWords.join(' ');
}

export function humanize(value) {
  if (typeof value === "number") {
    return value.toFixed(2);
  }

  if (typeof value === "boolean") {
    return value ? "Fail" : "Pass";
  }

  return value;
}

export function titleCase(str) {
  var words = str.toLowerCase().split(' ');

  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  var result = words.join(' ');

  return result;
}
