import { initializeApp } from "firebase/app";
import {
  getFirestore,
  Timestamp,
  connectFirestoreEmulator,
  setDoc,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions"
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  connectAuthEmulator,
} from "firebase/auth";
import { useEffect, useState } from "react";

/**
 * FirebaseClass
 * Holds all of the functions/config required for getting data from
 * the database and the storage bucket
 */
class FirebaseClass {
  constructor() {
    const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    // Make sure you have your firebase config in your .env with this name: REACT_APP_FIREBASE_CONFIG
    this.app = initializeApp(firebaseConfig);
    this.db = getFirestore();
    this.storage = getStorage(this.app);
		this.functions = getFunctions();


    this.connectEmulators();
  }

  connectEmulators() {
    let firebaseEmulatorHost = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;
    let authEmulatorHost = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
    let storageEmulatorHost =
      process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_HOST;

    if (firebaseEmulatorHost) {
      const url = new URL(firebaseEmulatorHost);
      connectFirestoreEmulator(this.db, url.hostname, url.port);
    }

    if (storageEmulatorHost) {
      const url = new URL(storageEmulatorHost);
      connectStorageEmulator(this.storage, url.hostname, url.port);
    }

    if (authEmulatorHost) {
      const auth = getAuth();
      connectAuthEmulator(auth, authEmulatorHost);
    }
  }

  // Logs user in
  async loginUser(email, password) {
    const auth = getAuth();
    try {
      await setPersistence(auth, browserLocalPersistence);
    } catch (err) {
      console.error("Persistence error: ", err);
    }
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      document.cookie = `__session=${token}; SameSite=None; Secure; ${
        window.location.hostname !== "localhost"
          ? `Domain=.${window.location.hostname}`
          : ""
      }`;
      return userCredential;
    } catch (err) {
      console.error(`Error code ${err.code}: ${err.message}`);
      throw err;
    }
  }

  // Logs out the user
  async logoutUser() {
    const auth = getAuth();
    try {
      await signOut(auth);
    } catch (error) {
      console.error("There was an error signing out: ", error);
    }
  }

  // Sends password reset email
  async resetPassword(email) {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email).catch((error) => {
      console.error(
        `There was a problem resetting the password: ${error.code}:`,
        error
      );
      throw error;
    });
  }

  async createUser(email, password, firstName, lastName, trainerId, ptCode) {
    try {
      // Call the Cloud Function endpoint
      const user = await httpsCallable(this.functions, "createUser")({
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        trainerId: trainerId,
        ptCode: ptCode
      });
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  async newUserDoc(email, firstName, lastName, uid, trainerId, ptCode) {
    // const auth = getAuth();
    // let db = dbStore();
    // from firebase_admin import credentials
    // from firebase_admin import firestore
    // from firebase_admin import auth

    // # Use a service account.
    // const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
    // cred = credentials.Certificate(firebaseConfig)
    // app = firebase_admin.initialize_app(cred)
    // db = firestore.client()
    const db = getFirestore().client();
    console.log("made it past credentials");

    const data = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      trainerId: trainerId,
      ptCode: ptCode,
      resetPassword: true,
    };
    db.collection("users")
      .document(uid)
      .set(data)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    console.log("successfully added to collection");
    // const update_array={
    //   test_patients: ["/users/${uid}"]
    // }
    // city_ref.update({u'regions': firestore.ArrayUnion([u'greater_virginia'])})
    trainer_doc = db.collection("trainers").document(ptCode);
    trainer_doc
      .update({ test_patients: firestore.ArrayUnion(["/users/${uid}"]) })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    console.log("tried to add to trainers doc");
  }

  checkForUser() {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        resolve(!!user);
      });
    });
  }

  //   POST to https://us-central1-ezpt-93903.cloudfunctions.net/adherence
  // with {"id":"some_user_id_here"} to get the adherence of all active routines over all time

  // Alternatively, you can get a particular routine with the select keyword. {"id":"some_user_id_here","select":"some_assignedroutine_id_here"}

  // Also, you can do a time period
  // {"id":"some_user_id_here", "between": {"_0": some_date_here, "_1": some_other_date_here}}
  // You can query a particular day by making the dates the same, however date "_0" must otherwise be before date "_1". The range of "between" is inclusive.

  // These queries will return either 0...1 (floating point) or -1. Adherence is (completion records of assigned routines / total amount of records there should be).
  // In the case that it is 0/0, the function returns -1. This is because you may wish to display "--" or "100" depending on how you think about it. The function can also return an error.

  // One example of how -1 could be returned is if you query adherence for an assigned routine that has not started yet.

  // Another example is if you have a routine scheduled for mondays, but you make a "between" query that does not have a monday in range.

  async getAdherenceURL(url, body, isFirstTry = true) {
    let newURL = null;
    if (window.location.hostname === "localhost")
      newURL = "http://localhost:9999" + url;
    return fetch(newURL ? newURL : url, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status !== 200 && isFirstTry) {
          console.log("trying again", response);
          return this.getAdherenceURL(url, body, false);
        } else return response;
      })
      .catch((e) => {
        console.error("There was an error retrieving adherence data", e);
      });
  }
}

const Firebase = new FirebaseClass();

// Allows other components to run functions once auth is loaded
export const useFirebaseAuthentication = () => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unlisten = getAuth().onAuthStateChanged((authUser) => {
      authUser ? setAuthUser(authUser) : setAuthUser(null);
    });
    return () => {
      unlisten();
    };
  }, []);
  return authUser;
};

export default Firebase;
